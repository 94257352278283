import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { signInWithRedirect } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import {
  Authenticator,
  useAuthenticator,
  ThemeProvider as AmplifyThemeProvider,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { useRollbar } from '@rollbar/react'

// Amplify Overrides
import 'ui/screens/SignIn/AmplifyCSSOverrides.css'
import { amplifyThemeOverrides } from 'ui/screens/SignIn/AmplifyThemeOverrides'
import { formFields } from 'ui/screens/SignIn/AmplifyFormFieldOverrides'

// Components
import { Stack } from 'ui/baseComponents'
import { AmplifyComponentCustomizations } from 'ui/screens/SignIn/AmplifyComponentCustomizations'

// Constants
import {
  COGNITO_ATTR_DEFAULT_LOGIN_PORTAL,
  DEFAULT_LOGIN_PORTAL_STRIVE_STUDY,
  DEFAULT_LOGIN_PORTAL_STRIVE_STUDY_REQUIRED,
  STRIVE_STUDY_URL,
} from 'ui/screens/SignIn/consts'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'

// Helpers
import {
  getUserAttributes,
  handleForgotPassword,
  handleForgotPasswordSubmit,
} from 'ui/screens/SignIn/helpers'

// LaunchDarkly
import { useFlags } from 'domains/launchdarkly/hooks'

// Zustand
import useBoundStore from 'domains/zustand/store'

const awsConfig = window.Rune.Carrot.config.vendors.aws

const EMAIL_PASSWORD_SIGNIN_CONFIG = {
  Auth: {
    Cognito: {
      userPoolClientId: awsConfig.cognito.clientId,
      userPoolId: awsConfig.cognito.userPoolId,
    },
  },
}

const SSO_CONFIG = {
  Auth: {
    Cognito: awsConfig.cognitoSSO,
  },
}

const SSO_PROVIDER = 'Duo'

const configureAmplify = () => {
  const urlParams = new URLSearchParams(window.location.search)
  // If 'code' is in the URL, assume the user is returning from Duo
  if (urlParams.has('code')) {
    Amplify.configure(SSO_CONFIG)
  } else {
    Amplify.configure(EMAIL_PASSWORD_SIGNIN_CONFIG)
  }
}

configureAmplify()

const SignIn = () => {
  const user = useBoundStore((state) => state.user)
  const setUser = useBoundStore((state) => state.setUser)
  const { duoSsoEnabled } = useFlags()
  const [isDuoLogin, setIsDuoLogin] = useState(false)
  const [multipleIdentities, setMultipleIdentities] = useState(false)

  const navigate = useNavigate()

  const { authStatus, user: authUser } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ])

  const rollbar = useRollbar()

  const loginWithDuo = async () => {
    setIsDuoLogin(true)
    Amplify.configure(SSO_CONFIG)
    await signInWithRedirect({
      customState: SSO_PROVIDER, // Provide custom state so we can identify the provider in the Hub listener
      provider: {
        custom: SSO_PROVIDER,
      },
    }).catch((error) => console.error('Error during sign-in:', error))
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'customOAuthState') {
        if (payload.data === SSO_PROVIDER) {
          setIsDuoLogin(true)
        }
      }
    })
  }, [])

  /* Set the user state in Zustand once authenticated
   * and route the user to the appropriate portal
   */
  useEffect(() => {
    const configureRollbar = (user) => {
      rollbar.configure({
        payload: {
          person: user,
        },
      })
    }

    const routeAuthenticatedUser = (user) => {
      if (isDuoLogin) {
        navigate(ROUTE_METADATA.patients.path)
        return
      }

      if (
        user[COGNITO_ATTR_DEFAULT_LOGIN_PORTAL] ===
          DEFAULT_LOGIN_PORTAL_STRIVE_STUDY ||
        user[COGNITO_ATTR_DEFAULT_LOGIN_PORTAL] ===
          DEFAULT_LOGIN_PORTAL_STRIVE_STUDY_REQUIRED
      ) {
        window.location.href = STRIVE_STUDY_URL
      } else {
        navigate(ROUTE_METADATA.patients.path)
      }
    }

    if (authStatus === 'authenticated' && authUser) {
      if (!user) {
        getUserAttributes().then((attributes) => {
          /* When logging in with Duo SSO, an 'identities' attribute is returned.
           *  We parse it to get the user's email.
           *  We mainly care about this for Rollbar configuration.
           */
          let identity
          if (attributes['identities']) {
            let parsedIdentities = JSON.parse(attributes['identities'])

            /* There should only be one identity returned from Cognito.
             *  If there are more, we log an error and update to value of multipleIdentities to display an alert.
             */
            if (parsedIdentities.length > 1) {
              console.error(
                'More than one identity returned from Cognito. Expected only one.',
              )
              setMultipleIdentities(true)
            }
            identity = parsedIdentities[0]
            identity['email'] = identity['userId']
          }

          const userAttributes = identity
            ? { ...attributes, ...identity }
            : attributes

          const userConfiguration = {
            id: authUser.username,
            ...userAttributes,
          }

          setUser(userConfiguration)
          configureRollbar(userConfiguration)
          routeAuthenticatedUser(userConfiguration)
        })
      } else {
        routeAuthenticatedUser(user)
      }
    }
  }, [authStatus, JSON.stringify(authUser), user, rollbar]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack
      direction="column"
      sx={{ height: '100vh' }}
      justifyContent="center"
      alignItems="center"
    >
      <AmplifyThemeProvider theme={amplifyThemeOverrides}>
        <Authenticator
          formFields={formFields}
          hideSignUp
          components={AmplifyComponentCustomizations({
            duoSsoEnabled,
            loginWithDuo,
            multipleIdentities,
          })}
          services={{
            handleForgotPassword,
            handleForgotPasswordSubmit,
          }}
        ></Authenticator>
      </AmplifyThemeProvider>
    </Stack>
  )
}

export default SignIn
