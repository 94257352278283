const COLUMN_WIDTHS = {
  gapColumn: 200,
  patientNameClinicianPortal: 200,
  patientEmail: 200,
  icon: 20,
}

const DATA_TABLE_COLUMN_WIDTHS = {
  patientName: 200,
  redFlagColumn: 70,
}

export const SEARCH_FIELDS = {
  codeName: 'codeName',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
}

export const URGENCY_COLORS = {
  alertClinician: 'rgb(252, 245, 246)',
  needsEducation: 'rgb(254, 252, 242)',
}

export const STYLES = {
  emailCell: {
    width: COLUMN_WIDTHS.patientEmail,
  },
  gapColumn: {
    width: COLUMN_WIDTHS.gapColumn,
  },
  iconButton: {
    padding: '3px',
    width: '30px',
    height: '30px',
  },
  iconCell: {
    textAlign: 'center',
    width: COLUMN_WIDTHS.icon,
    padding: '0',
  },
  nameCellClinicianPortal: {
    width: COLUMN_WIDTHS.patientNameClinicianPortal,
  },
  patientName: {
    fontSize: '1.1rem',
    wordBreak: 'break-all',
  },
  settingsIcon: {
    height: '1.5rem',
    padding: 0,
  },
  patientNameColumn: {
    width: DATA_TABLE_COLUMN_WIDTHS.patientName,
  },
  redFlagColumn: {
    width: DATA_TABLE_COLUMN_WIDTHS.redFlagColumn,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  loading: {
    '& [class^="MUIDataTable-responsiveBase"]': {
      display: 'none',
    },
  },
  loaded: {
    '& [class^="MUIDataTable-responsiveBase"]': {
      display: 'block',
    },
    '& [class^="MuiTableCell-root"]': {
      verticalAlign: 'middle',
    },
    '& [class^="MuiButtonBase-root"]': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& [class^="MUIDataTableHeadCell-sortAction"]': {
      alignItems: 'center',
    },
    '& td.darkened-background': {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        background: 'rgba(0, 0, 0, 0.02)',
      },
    },
    '& .urgency-alert': {
      backgroundColor: URGENCY_COLORS.alertClinician,
    },
    '& .urgency-educate': {
      backgroundColor: URGENCY_COLORS.needsEducation,
    },
  },
  expandedRow: {
    backgroundColor: '#F9FBFF',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
}

export const TABLE_HEADERS = {
  email: 'Email',
  falling: 'Falling',
  hallucination: 'Hallucination',
  lightheadedness: 'Lightheadedness',
  nextVisit: 'Next Visit',
  offPeriod: 'Off Period',
  patient: 'Patient',
  report: 'Report',
  settings: 'Settings',
  streams: 'Streams',
  swallowingIssues: 'Swallowing Issues',
  urgency: 'Urgency',
}

export const TABLE_STATUS_TEXT = {
  empty: 'No patients found',
  error:
    'There was a problem loading the patient list. Try refreshing the page, or contact support@runelabs.io if the issue persists.',
  search:
    'Search for patients. Enter at least 3 characters for each field you want to search by.',
}

export const MORE_RESULTS_AVAILABLE_TEXT =
  'More results are available. Refine your search to view them.'

// NEW DATA TABLE CONSTS

export const FILTER_PLACEHOLDER_TEXT =
  'Filter by any value (eg. name, ID, urgency, etc.)'

export const RED_FLAG_CLASSIFICATIONS = {
  falling: 'falling',
  hallucinations: 'hallucinations',
  lightheadedness: 'lightheadedness',
  swallowingIssues: 'swallowing-issues',
  offPeriod: 'off-period',
}

export const PATIENT_TABLE_ROWS_PER_PAGE = {
  default: 50,
  min: 25,
  max: 200,
}

export const PATIENT_TABLE_NO_RESULTS = 'No patients found'

export const PATIENT_TABLE_URGENCY = {
  alert: 'alert',
  educate: 'educate',
}

export const noPaddingCellStyles = {
  setCellProps: () => ({
    style: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  }),
  setCellHeaderProps: () => ({
    style: { paddingRight: 0, paddingLeft: 0 },
  }),
}

export const hiddenColumnStyles = {
  setCellProps: () => ({
    style: {
      display: 'none',
    },
  }),
  setCellHeaderProps: () => ({
    style: {
      display: 'none',
    },
  }),
}
