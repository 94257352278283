import { NEXT_PAGE_TOKEN } from './consts'
import { getAuthHeaders } from 'ui/screens/SignIn/helpers'
import { callApi } from 'domains/common/callApi'

/**
 * Call the Tangerine API, with authentication handling
 * @param {object} axiosOptions - the options to pass to axios
 * @param {string} method - the method to use for the call, e.g. GET, POST, PATCH, DELETE
 * @param {object} params - the parameters to pass to the call
 * @param {string} url - the url to make the call to
 *
 * @returns {Promise<*>} the response from the API call
 */

export const callTangerineApi = ({ url, params, axiosOptions, method }) =>
  callApi({
    axiosOptions,
    getAuthHeaders,
    method,
    nextPageToken: NEXT_PAGE_TOKEN,
    params,
    url,
  })
