export const TERMS_AND_PRIVACY_NOTICE_AGREEMENT = {
  existingUser: 'By using these services, I agree to the Rune Labs',
  newUser: 'By confirming my account, I agree to the Rune Labs',
}

export const COGNITO_ATTR_DEFAULT_LOGIN_PORTAL = 'custom:default_login_portal'
export const DEFAULT_LOGIN_PORTAL_STRIVE_STUDY = 'STRIVE_STUDY'
export const DEFAULT_LOGIN_PORTAL_STRIVE_STUDY_REQUIRED =
  'STRIVE_STUDY_REQUIRED'
export const STRIVE_STUDY_URL = `https://${window?.Rune?.Carrot?.config?.striveStudy?.host}`

export const COGNITO_ERRORS = {
  codeMismatchException: 'CodeMismatchException',
  invalidParameterException: 'InvalidParameterException',
  invalidPasswordException: 'InvalidPasswordException',
  userNotFoundException: 'UserNotFoundException',
}

export const CUSTOM_ERROR_MESSAGES = {
  email: 'This email does not exist',
  incorrectPassword: 'Incorrect password',
  invalidPassword: 'Invalid password',
  resetPassword:
    'We are unable to reset your password at this time. Contact your account administrator and request that they send you another invite.',
  verificationCode: 'Invalid verification code',
}
