import { Outlet, useParams } from 'react-router-dom'
import { withPermission } from 'domains/carrotGraph/user'
import { useLogger } from 'ui/hooks'
import { Search } from '@material-ui/icons'
import ErrorMessage from 'ui/components/ErrorMessage'
import Loading from 'ui/components/Loading'
import { PatientProvider } from 'ui/contexts/PatientContext'
import EditPatientSidebar from 'ui/screens/EditPatient/EditPatientSidebar'
import { usePatientEditableFields } from 'ui/hooks/usePatientEditableFields'
import { Stack } from 'ui/baseComponents'
import TabActionableHeader from 'ui/templates/TabActionableHeader'

export const EditPatient = () => {
  const { patientId } = useParams()
  const { loading, error, patient } = usePatientEditableFields(patientId)
  const logger = useLogger()

  if (loading) {
    return (
      <Stack justifyContent="center" height="50vh">
        <Loading />
      </Stack>
    )
  }

  if (error || !patient) {
    logger.error(error)
    return (
      <ErrorMessage
        icon={Search}
        message="Oops! Looks like this patient doesn't exist, or you don't have access to their data."
      />
    )
  }

  return (
    <PatientProvider value={patient}>
      <TabActionableHeader />
      <Stack direction="row">
        <EditPatientSidebar />
        <Outlet />
      </Stack>
    </PatientProvider>
  )
}

export default withPermission('admin')(EditPatient)
