import { gql } from '@apollo/client'

export const GET_PATIENT_EDITABLE_FIELDS = gql`
  query getPatientEditableFields($patientId: ID!) {
    patient(id: $patientId) {
      id
      codeName
      identifiableInfo {
        realName
        firstName
        lastName
        demographicData {
          clinicalVisit
          dateOfBirth
          ethnicity
          facilityName
          gender
          geneticMarkers {
            id
            name
          }
          height
          otherConditions {
            id
            name
          }
          pdDiagnosis
          pdDiagnosisDate
          providerAddress {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          providerInfo {
            firstName
            lastName
          }
          providerNpi
          race
          state
          weight
        }
      }
      deviceList {
        pageInfo {
          endCursor
        }
        devices {
          id
          deviceShortId
          deviceType {
            id
            displayName
          }
          alias
          kind
          disabled
          channels
        }
      }
      clientList {
        clients {
          id
          displayName
          disabled
          clientKeyList {
            clientKeys {
              id
              disabled
              disabledAt
              createdAt
              clientKeyId
            }
          }
        }
      }
    }
  }
`
