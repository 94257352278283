import { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import { useFlags } from 'domains/launchdarkly/hooks'
import { PatientContext, UserPHIContext } from 'ui/contexts'
import { SummaryModeContext } from 'ui/contexts/SummaryModeContext'
import { colors } from 'theme/colors'
import { useIsAdmin } from 'ui/hooks'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'

const STYLES = {
  tabs: {
    paddingLeft: '3.5rem',
    backgroundColor: colors.COOL[100],
    '@media print': {
      display: 'none !important',
    },
  },
  tabIndicatorProps: {
    backgroundColor: colors.PRIMARY[300],
    height: '.5rem',
    borderRadius: '6px 6px 0 0',
  },
  tab: {
    '&.MuiTab-root': {
      margin: '1rem 0',
      color: colors.PRIMARY[900],
      textTransform: 'none',
      fontSize: '1.125rem',
      fontWeight: 400,
      letterSpacing: 'normal',
      minWidth: 'auto',
    },
    '&:hover': {
      color: colors.PRIMARY[1000],
      fontWeight: 600,
    },
    '&.Mui-selected': {
      color: colors.PRIMARY[1000],
      fontWeight: 600,
    },
  },
}

const ClinicianPatientTabs = ({ getTabs }) => {
  const {
    monthlyReportsTabVisibile: monthlyReportsTabVisible,
    striveGptVisible,
    clinicianReportVisible,
  } = useFlags()
  const { id: patientId } = useContext(PatientContext)
  const { comparisonModeEnabled } = useContext(SummaryModeContext)
  const { pathname } = useLocation()
  const phiVisibility = useContext(UserPHIContext)
  const { admin } = useIsAdmin()

  const TABS = getTabs({
    admin,
    clinicianReportVisible,
    comparisonModeEnabled,
    monthlyReportsTabVisible,
    phiVisibility,
    striveGptVisible,
  })
  const [firstTab] = TABS || []

  const determineSelectedTab = () => {
    const matchingTab = TABS.find((tab) => {
      if (
        pathname.startsWith(
          `/patients/${patientId}/${ROUTE_METADATA.editPatient.path}`,
        )
      ) {
        return tab.to === `${ROUTE_METADATA.editPatient.path}`
      }
      return `/patients/${patientId}/${tab.to}` === pathname
    })

    return matchingTab ? matchingTab.to : firstTab.to
  }

  return (
    <Tabs
      aria-label="Patient Data Tabs"
      value={determineSelectedTab()}
      variant="scrollable"
      sx={STYLES.tabs}
      TabIndicatorProps={{
        style: STYLES.tabIndicatorProps,
      }}
    >
      {TABS.map(({ component, ...tab }) => (
        <Tab
          key={tab.id}
          disableRipple
          component={Link}
          {...tab}
          value={tab.to}
          data-testid={`tab-${tab.id}`}
          data-cy={`tab-${tab.label.toLowerCase().split(' ').join('-')}`}
          sx={{
            ...STYLES.tab,
            '&::after': {
              content: `"${tab.label}"`,
              height: 0,
              visibility: 'hidden',
              overflow: 'hidden',
              userSelect: 'none',
              pointerEvents: 'none',
              fontWeight: 600,
            },
          }}
        />
      ))}
    </Tabs>
  )
}

export default ClinicianPatientTabs
