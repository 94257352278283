import { Outlet, useParams } from 'react-router-dom'
import { Search } from '@material-ui/icons'
import DefaultLayout from 'ui/components/DefaultLayout'
import ErrorMessage from 'ui/components/ErrorMessage'
import LoadingPage from 'ui/components/LoadingPage/LoadingPage'
import { PatientProvider } from 'ui/contexts/PatientContext'
import { useLogger, usePatient } from 'ui/hooks'

const Patient = () => {
  const { patientId } = useParams()
  // Load the patient at the route level, then expose it to children via PatientProvider
  const { loading, error, patient } = usePatient({ patientId })
  const logger = useLogger()

  if (error) {
    logger.error(error)
    return (
      <DefaultLayout>
        <ErrorMessage
          icon={Search}
          message="Oops! Looks like this patient doesn't exist, or you don't have access to their data."
        />
      </DefaultLayout>
    )
  }
  if (loading) {
    return <LoadingPage />
  }

  return (
    <PatientProvider value={patient}>
      <DefaultLayout>
        <Outlet />
      </DefaultLayout>
    </PatientProvider>
  )
}

export default Patient
