/**
 *
 * @param {object} patient - the patient object
 * @returns {object} provider - an object with the provider's information
 */
export const constructProviderFromPatient = (patient) => {
  return {
    first_name:
      patient?.identifiableInfo?.demographicData?.providerInfo?.firstName,
    last_name:
      patient?.identifiableInfo?.demographicData?.providerInfo?.lastName,
    address: {
      address_line_1:
        patient?.identifiableInfo?.demographicData?.providerAddress
          ?.addressLine1,
      address_line_2:
        patient?.identifiableInfo?.demographicData?.providerAddress
          ?.addressLine2,
      city: patient?.identifiableInfo?.demographicData?.providerAddress?.city,
      state: patient?.identifiableInfo?.demographicData?.providerAddress?.state,
      zip_code:
        patient?.identifiableInfo?.demographicData?.providerAddress?.zipCode,
    },
    npi: patient?.identifiableInfo?.demographicData?.providerNpi,
  }
}
