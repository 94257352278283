import { colors } from 'theme/colors'

const DISABLED_FONT_COLOR = colors.COOL[400]

export const SHARED_STYLES = {
  disabledFont: { color: DISABLED_FONT_COLOR },
  disabledInputBox: {
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.COOL[400]}`,
    borderRadius: '4px',
  },
  disabledInput: {
    '& .MuiInputLabel-root': {
      color: colors.COOL[400],
    },
    '& .MuiInputBase-root': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  readOnlyInput: {
    '& .MuiFormLabel-root': {
      color: colors.GREY[800],
    },
    '& .MuiInputBase-root': {
      backgroundColor: colors.GREY[100],
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      backgroundColor: colors.GREY[100],
      WebkitTextFillColor: colors.COOL[900],
    },
  },
}
