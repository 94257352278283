import * as MUI from '@mui/material'
import { useFlags } from 'domains/launchdarkly/hooks'
import { useContext, useState } from 'react'
import { Alert, Link, Text } from 'ui/baseComponents'
import { PatientContext } from 'ui/contexts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { EXPERIMENTAL_WIDGET_TITLES } from 'ui/baseComponents/ExperimentalIFrame/consts'

const ExperimentalIFrame = ({
  widget = '',
  startDate,
  endDate,
  width = '100%',
  height = '600px',
  expandedDefault = true,
}) => {
  const STYLES = {
    accordion: {
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '@media print': {
        display: 'none',
      },
    },
  }

  const { experimentalWebComponentsVisible } = useFlags()
  const { id: patientId } = useContext(PatientContext)

  const [showVPNAlert, setShowVPNAlert] = useState(true)
  const [expanded, setExpanded] = useState(expandedDefault)

  if (!experimentalWebComponentsVisible || !widget) {
    return null
  }

  const src = `https://datascience-${widget}.runelabs.io`

  return (
    <MUI.Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={STYLES.accordion}
    >
      <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Text variant="body16B">
          {EXPERIMENTAL_WIDGET_TITLES[widget] || widget}
        </Text>
      </MUI.AccordionSummary>
      <MUI.AccordionDetails>
        <>
          {showVPNAlert ? (
            <Alert severity="warning">Please check your VPN.</Alert>
          ) : (
            <Text
              variant="body16"
              component="p"
              style={{ marginBottom: '1rem' }}
            >
              If you are seeing a `refused to connect` error, please{' '}
              <Link href={src} external underline>
                sign in here
              </Link>{' '}
              and then refresh this page.
            </Text>
          )}

          <iframe
            {...{
              width,
              height: showVPNAlert ? '0' : height,
              frameBorder: '0',
              src: `${src}/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}`,
              allow: 'clipboard-read; clipboard-write',
              title: widget,
              'data-cy': 'experimental-iframe',
              onLoad: () => setShowVPNAlert(false),
            }}
          />
        </>
      </MUI.AccordionDetails>
    </MUI.Accordion>
  )
}

export default ExperimentalIFrame
