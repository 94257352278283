import * as MUI from '@mui/material'
import StrivePDPoweredByRuneLogo from 'assets/img/strivepd-poweredby-rune-logo.svg'

const StrivePDLogoDisplay = ({ sx }) => (
  <MUI.Box
    sx={{
      textAlign: 'center',
      ...sx,
    }}
  >
    <img
      alt="StrivePD powered by Rune Labs logo"
      src={StrivePDPoweredByRuneLogo}
    />
  </MUI.Box>
)

export default StrivePDLogoDisplay
