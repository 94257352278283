import React from 'react'
import SummaryViewsSummaryModeHeader from '../../Tabs/TabContentHeaders/Layouts/Tabs/SummaryViewsSummaryModeHeader'
import SummaryModeCollapsibleViews from './SummaryModeCollapsibleViews'

const SummaryMode = () => (
  <>
    <SummaryViewsSummaryModeHeader />
    <SummaryModeCollapsibleViews />
  </>
)

export default SummaryMode
