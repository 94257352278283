import { signOut } from 'aws-amplify/auth'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'

const authenticationSlice = (set) => ({
  user: null,
  setUser: (user) => {
    set(() => ({ user }))
  },
  signOut: async () => {
    try {
      await signOut()
      set({ user: null })
      sessionStorage.clear()
      window.location.href = ROUTE_METADATA.signin.path
    } catch (error) {
      console.error('Error signing out', error)
    }
  },
})

export default authenticationSlice
