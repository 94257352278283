import axios from 'axios'

/**
 * A function to make API calls
 * @param {object} axiosOptions - the options to pass to axios
 * @param {function} getAuthHeaders - a function to get the auth headers
 * @param {string} method - the method to use for the call, e.g. GET, POST, PATCH, DELETE
 * @param {object} nextPageToken - the next page token to use if there is one
 * @param {object} params - the parameters to pass to the call
 * @param {string} url - the url to make the call to
 *
 * @returns {Promise<*>} the response from the API call
 */
export const callApi = async ({
  axiosOptions,
  getAuthHeaders,
  method,
  nextPageToken,
  params,
  url,
}) => {
  const headers = getAuthHeaders ? await getAuthHeaders() : {}

  if (method === 'GET') {
    try {
      const response = await axios.get(url, {
        params,
        headers,
      })

      const nextPageTokenResponse =
        response?.headers?.[nextPageToken?.responseHeader]

      return {
        ...(response?.data || response),
        ...(nextPageTokenResponse && {
          [nextPageToken?.key]: nextPageTokenResponse,
        }),
      }
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  } else {
    try {
      delete params.timezone_name
      headers['Content-Type'] = 'application/json'
      let response = {}
      if (method === 'POST') {
        response = await axios.post(url, params, {
          headers,
          ...axiosOptions,
        })
      } else if (method === 'PATCH') {
        response = await axios.patch(url, params, { headers })
      } else if (method === 'DELETE') {
        response = await axios.delete(url, { headers })
      }
      return response ? response.data : response
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  }
}
