import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_PATIENT_EDITABLE_FIELDS_DROPDOWN_OPTIONS } from 'domains/carrotGraph/queries'
import {
  FORM_FIELDS_METADATA,
  US_STATES_AND_ABBREVIATIONS,
} from 'ui/screens/EditPatient/PatientDetails/consts'

/**
 * Retrieve predetermined options available for patient fields that can be edited.
 * @return {{loading, error, options}} loading, error, options
 */
const usePatientEditableFieldsDropdownOptions = () => {
  const [dropdownOptions, setDropdownOptions] = useState({})

  const { loading, error, data } = useQuery(
    GET_PATIENT_EDITABLE_FIELDS_DROPDOWN_OPTIONS,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  useEffect(() => {
    if (!data) return
    const transformEnumValues = (enumValues) =>
      enumValues.map(({ name }) => {
        const displayName = name
          .toLowerCase()
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
        return { key: name, name: displayName, value: name }
      })

    const transformedData = Object.keys(data).reduce((acc, key) => {
      if (
        key === FORM_FIELDS_METADATA.geneticMarkers.key ||
        key === FORM_FIELDS_METADATA.otherConditions.key
      ) {
        acc[key] = [...data[key][key]].sort((a, b) =>
          a.name.localeCompare(b.name),
        )
      } else {
        acc[key] = transformEnumValues(data[key].enumValues).sort((a, b) =>
          a.name.localeCompare(b.name),
        )
      }

      return acc
    }, {})

    const stateOptions = Object.keys(US_STATES_AND_ABBREVIATIONS).map(
      (state) => ({
        key: state,
        name: state,
        value: US_STATES_AND_ABBREVIATIONS[state],
      }),
    )

    transformedData[FORM_FIELDS_METADATA.stateForSearch.key] = stateOptions

    setDropdownOptions(transformedData)
  }, [data])

  return {
    loading,
    error,
    options: dropdownOptions,
  }
}

export default usePatientEditableFieldsDropdownOptions
