import { Fragment, useCallback, useContext, useState } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import { ClientKeys } from '../CreateClient/ClientKeys'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { useMutation } from '@apollo/client'
import { Error, NotificationBar } from 'ui/components/Notifications'
import { CREATE_CLIENT_KEY } from '../mutations'
import { useRefetchPatientEditableFields } from 'ui/hooks/usePatientEditableFields'
import { PatientContext } from 'ui/contexts'

export const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 500,
  },
}))

const CreateClientKey = ({ client }) => {
  const { id: patientId } = useContext(PatientContext)
  const classes = useStyles()
  const [open, setOpen] = useState()
  const [error, setError] = useState()
  const [createClientKey, { data }] = useMutation(CREATE_CLIENT_KEY, {
    onError: (error) => {
      console.error(error)
      setError('Unable to create Client Key')
    },
    refetchQueries: [useRefetchPatientEditableFields(patientId)],
  })
  const {
    createClientKey: { clientKey: { clientKeyId } = {}, clientKeySecret } = {},
  } = data || {}
  const handleCreateKey = async (e) => {
    e.preventDefault()
    await createClientKey({
      variables: { input: { clientId: client.id } },
    })
    setOpen(true)
  }
  const handleCloseError = useCallback(() => setError(false), [])
  const handleClose = useCallback(() => setOpen(false), [])
  return (
    <Fragment>
      <Button
        variant="outlined"
        data-cy="create-access-key"
        onClick={handleCreateKey}
      >
        New Key <AddIcon />
      </Button>
      {open && (
        <Dialog open={!!clientKeyId}>
          <DialogContent className={classes.dialogContent}>
            <ClientKeys secret={clientKeySecret} clientKeyId={clientKeyId} />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {error && (
        <NotificationBar open={!!error} onClose={handleCloseError}>
          <Error message={error} onClose={handleCloseError} />
        </NotificationBar>
      )}
    </Fragment>
  )
}

export default CreateClientKey
