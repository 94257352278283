export const EXPERIMENTAL_WIDGETS = {
  fallRisk: 'fallriskquantification',
  MM4PDFluctuation: 'mm4pdfluctuation',
  topPatientIssues: 'top-patient-issues',
  symptomBurdenRisk: 'symptomburdenrisk',
}

export const EXPERIMENTAL_WIDGET_TITLES = {
  [EXPERIMENTAL_WIDGETS.fallRisk]: 'Fall Risk Quantification',
  [EXPERIMENTAL_WIDGETS.MM4PDFluctuation]: 'Medication Response',
  [EXPERIMENTAL_WIDGETS.topPatientIssues]: 'Top Patient Issues',
  [EXPERIMENTAL_WIDGETS.symptomBurdenRisk]: 'Symptom Burden Risk',
}
