import { BarChart, Stack } from 'ui/baseComponents'
import { formatDailyEventsBarChartData } from 'ui/clinicianScreens/Patient/Daily/helpers'
import { colors } from 'theme/colors'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle/DailyChartTitle'
import {
  SECTION_TITLES,
  smallChartHeight,
} from 'ui/clinicianScreens/Patient/Daily/consts'
import moment from 'moment'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { memo, useContext, useEffect } from 'react'
import useBoundStore from 'domains/zustand/store'

const DailyMedication = ({
  date,
  data = [],
  activeFilters = [],
  setHasDataForDay = () => {},
}) => {
  const dailyViewCombinedMedications = useBoundStore(
    (state) => state.dailyViewCombinedMedications,
  )
  const { selectedTimezone } = useContext(DateRangeContext)

  const legendItem = {
    name: SECTION_TITLES.medication,
    color: colors.PURPLE[500],
  }

  const filteredData = formatDailyEventsBarChartData(
    {
      date,
      data,
      activeFilters,
      label: SECTION_TITLES.medication,
      timezoneName: selectedTimezone,
    },
    dailyViewCombinedMedications,
  )

  useEffect(() => {
    if (filteredData.length > 0) {
      setHasDataForDay(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData.length])

  return filteredData.length > 0 ? (
    <Stack data-cy="daily-view-section-medication">
      {filteredData.map((item) => (
        <Stack
          spacing={0}
          key={`daily-medication-${moment(date).format('MM DD')}-${
            item[0].title
          }`}
        >
          <DailyChartTitle
            key={item.timestamp}
            variant="body12"
            component="h3"
            sx={{ height: 0 }}
          >
            {item[0].title}
          </DailyChartTitle>

          <Stack sx={{ paddingLeft: '60px' }}>
            <BarChart
              {...{
                title: SECTION_TITLES.medication,
                height: smallChartHeight,
                barSize: null,
                barCategoryGap: 0,
                barGap: 0,
                barRadius: [50, 50, 50, 50],
                data: item,
                legendItem,
                yAxisMax: 1,
                legend: false,
                isAnimationActive: false,
                hideYAxis: true,
                hideXAxis: true,
                hideXAxisTickLine: true,
                hideVerticalGridLines: true,
                hideTopXAxisGridLines: true,
                chartId: `daily-chart-medication`,
                showOverflowingBars: true,
              }}
            />
          </Stack>
        </Stack>
      ))}
    </Stack>
  ) : null
}

export default memo(DailyMedication)
