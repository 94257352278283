import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import ProtectedRoute from '../ProtectedRoute'
import { getAutomatedUserJwt } from 'utilities/automatedUser'
import useBoundStore from 'domains/zustand/store'

const ProtectedByLogin = () => {
  const user = useBoundStore((state) => state.user)

  const isAllowed = Boolean(!!getAutomatedUserJwt() || !!user)

  if (!isAllowed) {
    const { pathname, search } = window.location
    const referrerPath = `${pathname}${search}`
    sessionStorage.setItem('referrer', referrerPath)
  }

  return (
    <ProtectedRoute
      isAllowed={isAllowed}
      redirectPath={ROUTE_METADATA.signin.path}
    />
  )
}

ProtectedByLogin.propTypes = {}

ProtectedByLogin.defaultProps = {}

export default ProtectedByLogin
