import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import {
  FormControl as MUIFormControl,
  InputLabel as MUIInputLabel,
  MenuItem as MUIMenuItem,
  Select as MUISelect,
} from '@mui/material'
import { SHARED_STYLES } from './consts'
import { toKebabCaseLowerCase } from 'utilities/string'

const STYLES = {
  disabled: {
    '& .MuiInputBase-input.Mui-disabled': SHARED_STYLES.disabledInputBox,
    ...SHARED_STYLES.disabledInput,
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      display: 'block',
      paddingLeft: '16px',
    },
  },
  readOnly: SHARED_STYLES.readOnlyInput,
}

const Select = ({
  disabled,
  label,
  name,
  onChange,
  options,
  readOnly,
  sxSelect,
  value,
}) => {
  // Convert label to lowercase and replace spaces with hyphens
  const reformattedLabel = toKebabCaseLowerCase(label)
  const labelId = `${reformattedLabel}-label`
  const selectId = `${reformattedLabel}-select`

  const handleChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value,
      },
    })
  }

  return (
    <MUIFormControl
      fullWidth
      {...{
        disabled,
        sx: {
          ...(readOnly && STYLES.readOnly),
          ...(disabled && !readOnly && STYLES.disabled),
        },
      }}
    >
      <MUIInputLabel id={labelId}>{label}</MUIInputLabel>
      <MUISelect
        {...{
          id: selectId,
          label,
          labelId,
          onChange: handleChange,
          sx: sxSelect,
          value,
        }}
      >
        {options.map((option) => (
          <MUIMenuItem
            key={option?.key || option}
            value={option?.value || option}
            sx={STYLES.menuItem}
          >
            {option?.name || option}
          </MUIMenuItem>
        ))}
      </MUISelect>
    </MUIFormControl>
  )
}

export default Select

Select.propTypes = {
  disabled: bool,
  label: string.isRequired,
  name: string,
  onChange: func,
  options: arrayOf(
    oneOfType([
      shape({
        key: string,
        name: string.isRequired,
        value: oneOfType([number, string]).isRequired,
      }),
      string,
    ]),
  ),
  readOnly: bool,
  sxSelect: object,
  value: oneOfType([number, string]),
}
