import { gql } from '@apollo/client'

export const GET_PATIENT_LIST = gql`
  query getPatientList(
    $cursor: Cursor
    $limit: Int
    $startTime: Float!
    $endTime: Float!
  ) {
    org {
      id
      patientAccessList(cursor: $cursor, limit: $limit) {
        pageInfo {
          endCursor
        }
        patientAccess {
          patient {
            id
            codeName
            created_at: createdAt
            identifiableInfo {
              realName
              firstName
              lastName
              email
              demographicData {
                dateOfBirth
                clinicalVisit
                nextVisitMonthYear
              }
            }
            redFlags(endTime: $endTime, startTime: $startTime) {
              totalSummary {
                counts {
                  classification {
                    id
                    namespace
                    category
                    enum
                  }
                  displayName
                  count
                }
              }
              needsEducation
              alertClinician
            }
          }
        }
      }
    }
  }
`
