import { useContext, useEffect, useState } from 'react'
import { PatientContext, UserPHIContext } from 'ui/contexts'
import { Stack, Text } from 'ui/baseComponents'
import ActionButtons from 'ui/screens/EditPatient/PatientDetails/ActionButtons'
import FormField from './FormField'
import PatientDetailsWithoutPHI from './PatientDetailsWithoutPHI'
import ProviderSearchField from './ProviderSearchField'
import {
  FORM_FIELD_WIDTH,
  FORM_FIELDS,
  FORM_FIELDS_METADATA,
  PROVIDER_SEARCH_FIELDS,
  TYPOGRAPHY,
} from 'ui/screens/EditPatient/PatientDetails/consts'
import { DATE_FORMAT } from 'ui/consts'
import { constructProviderFromPatient } from './helpers'
import { useFlags } from 'domains/launchdarkly/hooks'
import {
  usePatientEditableFieldsDropdownOptions,
  useProviderSearch,
} from 'ui/hooks'
import {
  getNestedObjectValue,
  updateNestedObjectValue,
} from 'utilities/objects'

const PatientDetails = () => {
  const { editPatientFormEnabled } = useFlags()
  const patient = useContext(PatientContext)
  const phiVisibility = useContext(UserPHIContext)

  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({}) // Only keeps track of the fields that change
  const [isEditing, setIsEditing] = useState(false)

  const { options, loading: loadingOptions } =
    usePatientEditableFieldsDropdownOptions()

  const handleProviderSelect = (provider) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      identifiableInfo: {
        ...prevFormData?.identifiableInfo,
        demographicData: {
          ...prevFormData?.identifiableInfo?.demographicData,
          providerAddress: {
            addressLine1: provider?.address?.address_line_1 || '',
            addressLine2: provider?.address?.address_line_2 || '',
            city: provider?.address?.city || '',
            state: provider?.address?.state || '',
            zipCode: provider?.address?.zip_code || '',
          },
          providerInfo: {
            firstName: provider?.first_name || '',
            lastName: provider?.last_name || '',
          },
          providerNpi: provider?.npi || '',
        },
      },
    }))
  }

  const {
    providerLocation,
    providerOptions,
    searchProviderInputFields,
    selectedProvider,
    updateProviderLocation,
    updateSearchProviderInputFields,
    updateSelectedProvider,
  } = useProviderSearch({
    onProviderSelect: handleProviderSelect,
    providerState:
      getNestedObjectValue({
        object: patient,
        path: FORM_FIELDS_METADATA.stateForSearch.name,
      }) || '',
    searchFields: PROVIDER_SEARCH_FIELDS,
  })

  useEffect(() => {
    if (
      patient?.identifiableInfo?.demographicData?.providerInfo?.firstName ||
      patient?.identifiableInfo?.demographicData?.providerInfo?.lastName
    ) {
      const initialProvider = constructProviderFromPatient(patient)
      updateSelectedProvider({
        target: {
          name: 'initialProvider',
          value: initialProvider,
        },
      })
    }
  }, [patient]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = ({ event }) => {
    const { name, value } = event.target

    if (name === FORM_FIELDS_METADATA.stateForSearch.name) {
      updateProviderLocation(value)
    }

    setFormData((prev) =>
      updateNestedObjectValue({ object: prev, path: name, value }),
    )
  }

  const handleDateChange = (event) => {
    const { name, value } = event.target
    const newDate = value ? value.format(DATE_FORMAT) : null
    setFormData((prev) =>
      updateNestedObjectValue({ object: prev, path: name, value: newDate }),
    )
  }

  const handleMultiSelectChange = (event) => {
    const { name, value } = event.target

    let currentOptions = []
    if (name === FORM_FIELDS_METADATA.geneticMarkers.name) {
      currentOptions = options.geneticMarkers
    } else if (name === FORM_FIELDS_METADATA.otherConditions.name) {
      currentOptions = options.diagnoses
    }

    const newValue = value
      .map((optionId) =>
        currentOptions.find((option) => option.id === optionId),
      )
      .sort((a, b) => a.name.localeCompare(b.name))

    setFormData((prev) =>
      updateNestedObjectValue({ object: prev, path: name, value: newValue }),
    )
  }

  const validateFields = () => {
    const newErrors = {}
    Object.keys(FORM_FIELDS_METADATA).forEach((key) => {
      const { name } = FORM_FIELDS_METADATA[key]
      const value = getNestedObjectValue({ object: formData, path: name })

      const error = FORM_FIELDS_METADATA[key]?.validate
        ? FORM_FIELDS_METADATA[key].validate(value)
        : null
      if (error) {
        newErrors[name] = error
      }
    })
    setErrors(newErrors)
  }

  const onClickEdit = () => setIsEditing(true)

  const onClickSave = () => {
    // TODO: Handle errors and save data. Be sure to include check for duplicate code names.
    console.log({ formData })
    validateFields()
    setIsEditing(false)
  }

  const onClickCancel = () => {
    setFormData({ ...patient })
    setIsEditing(false)
  }

  const renderFields = (fields) => {
    const rows = []
    let currentRow = []
    let currentRowWidth = 0

    fields.forEach((field, index) => {
      const fieldWidth =
        field.layout === FORM_FIELD_WIDTH.full.name
          ? FORM_FIELD_WIDTH.full.numericValue
          : FORM_FIELD_WIDTH.half.numericValue

      if (currentRowWidth + fieldWidth > 1) {
        rows.push(currentRow)
        currentRow = []
        currentRowWidth = 0
      }

      currentRow.push(field)
      currentRowWidth += fieldWidth

      if (
        field.layout === FORM_FIELD_WIDTH.full.name ||
        field.isOnlyFieldInRow ||
        index === fields.length - 1
      ) {
        rows.push(currentRow)
        currentRow = []
        currentRowWidth = 0
      }
    })

    return rows.map((row, rowIndex) => (
      <Stack key={rowIndex} direction="row" spacing={2}>
        {row.map((field) => (
          <FormField
            {...{
              errors,
              field,
              formData,
              handleChange,
              handleDateChange,
              handleMultiSelectChange,
              isEditing,
              key: field.name,
              loadingOptions,
              options,
              patient,
            }}
          />
        ))}
      </Stack>
    ))
  }

  if (!editPatientFormEnabled || !phiVisibility) {
    return <PatientDetailsWithoutPHI />
  }

  return (
    <Stack spacing={2} sx={{ width: '100%', padding: 2 }}>
      <ActionButtons
        {...{
          isEditing,
          onClickEdit,
          onClickSave,
          onClickCancel,
        }}
      />
      <Stack direction="row" gap={8}>
        <Stack direction="column" spacing={2} width="50%">
          <Text variant={TYPOGRAPHY.sectionHeading}>Personal Information</Text>
          {renderFields(FORM_FIELDS.personalInfo)}
          <Text variant={TYPOGRAPHY.sectionSubHeading}>Health Information</Text>
          {renderFields(FORM_FIELDS.healthInfo)}
          <Text variant={TYPOGRAPHY.sectionSubHeading}>
            Demographic Information
          </Text>
          {renderFields(FORM_FIELDS.demographicInfo)}
          <Text variant={TYPOGRAPHY.sectionSubHeading}>Code Name</Text>
          {renderFields(FORM_FIELDS.codeName)}
        </Stack>
        <Stack direction="column" spacing={2} width="50%">
          <Text variant={TYPOGRAPHY.sectionHeading}>Clinical Information</Text>
          {renderFields(FORM_FIELDS.clinicalInfoFields)}
          <ProviderSearchField
            {...{
              disabled: !isEditing,
              errors,
              formData,
              handleChange,
              inputValues: searchProviderInputFields,
              isEditing,
              loadingOptions,
              options,
              patient,
              providerLocation,
              providerOptions,
              updateSearchProviderInputFields,
              updateSelectedProvider,
              value: selectedProvider,
            }}
          />
          {renderFields(FORM_FIELDS.facilityInfo)}
        </Stack>
      </Stack>
      <ActionButtons
        {...{
          isEditing,
          onClickEdit,
          onClickSave,
          onClickCancel,
        }}
      />
    </Stack>
  )
}

export default PatientDetails
