import {
  array,
  arrayOf,
  node,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import * as MUI from '@mui/material'
import { Text } from '../Text'
import { colors } from 'theme/colors'

const Table = ({ className, headings, rows, sx, tableCellProps, ...props }) => (
  <MUI.Table data-cy="table" {...{ className, sx, ...props }}>
    {headings?.length > 0 && (
      <MUI.TableHead>
        <MUI.TableRow>
          {headings?.map((heading) => (
            <MUI.TableCell
              key={heading.key}
              sx={{
                ...heading.sx,
                paddingLeft: 0,
                paddingTop: '.25rem',
                paddingBottom: 0,
                border: 'none',
              }}
              colSpan={heading.colSpan}
              data-cy="table-header-cell"
            >
              <Text variant="caps12B">{heading.value}</Text>
            </MUI.TableCell>
          ))}
        </MUI.TableRow>
      </MUI.TableHead>
    )}
    <MUI.TableBody>
      {rows.map((row) => (
        <MUI.TableRow
          key={`row-${rows?.indexOf(row)}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {row.map((cell) => (
            <MUI.TableCell
              key={cell.key}
              sx={{
                paddingLeft: 0,
                verticalAlign: 'top',
                borderColor: colors.GREY[200],
                ...cell.sx,
              }}
              colSpan={cell.colSpan}
              data-cy="table-row-cell"
              {...tableCellProps}
            >
              {cell.value}
            </MUI.TableCell>
          ))}
        </MUI.TableRow>
      ))}
    </MUI.TableBody>
  </MUI.Table>
)

Table.propTypes = {
  className: string,
  headings: arrayOf(
    shape({
      key: string.isRequired,
      value: oneOfType([string.isRequired, node.isRequired]),
      sx: object,
    }),
  ),
  rows: arrayOf(array).isRequired,
  sx: object,
}

export default Table
