/**
 * Given a string, convert it to kebab-case and lowercase
 * @param {string} string String to be converted to kebab-case
 * @returns {string} Kebab-cased string in lowercase
 */
export const toKebabCaseLowerCase = (string = '') =>
  string.replace(/\s+/g, '-').toLowerCase()

/**
 * Match the `text-transform: capitalize` functionality
 * @param {string} string String to be transformed to title case
 * @returns {string} Title-cased string
 */
export const toTitleCase = (string = '') => {
  const FIRST_LETTER_INDEX = 0
  const REST_OF_WORD_INDEX = 1

  return string
    .split(' ')
    .map(
      (word) =>
        word.charAt(FIRST_LETTER_INDEX).toUpperCase() +
        word.slice(REST_OF_WORD_INDEX),
    )
    .join(' ')
}

/**
 * patientName is a function that takes a patient object and returns a patient name
 * in a variety of formats.
 *
 * For basically all of the below, the logic is to use firstname/lastname fields, then
 * realname, and finally codename.
 *
 * @typedef {object} PatientName
 * @property {function} withLastFirst - Returns the patient name in the format of "Last, First"
 * @property {function} firstName - Returns the patient first name
 * @property {function} fullName - Returns the patient full name
 *
 * @param {object} patientObject - The patient object returned by GQL
 * @returns {PatientName} - A set of patientName formatting functions
 *
 */
export const patientName = (patientObject) => {
  const idInfo = patientObject?.identifiableInfo || {}
  const realName = (idInfo?.realName || '').trim()
  const first = (idInfo?.firstName || '').trim()
  const last = (idInfo?.lastName || '').trim()
  const codeName = (patientObject?.codeName || '').trim()

  /**
   * Returns whether the patient has a first and last name
   * @returns {boolean} - Whether the patient has a first and last name
   */
  const hasFirstAndLast = () => !!(first.length && last.length)

  /**
   * Returns whether the patient has a real name
   * @returns {boolean} - Whether the patient has a real name
   */
  const hasRealName = () => !!realName.length

  /**
   * Returns the patient first name
   * @returns {string} - The patient first name
   */
  const firstName = () => {
    if (first.length) {
      return first
    }
    if (hasRealName()) {
      return realName.split(' ')[0].trim()
    }
    return codeName
  }

  /**
   * Returns the patient full name
   * @returns {string} - The patient full name
   */
  const fullName = () => {
    if (hasFirstAndLast()) {
      return `${first} ${last}`
    }
    if (hasRealName()) {
      return realName
    }
    return codeName
  }

  const noopTruncate = (string) => string
  const truncateName = (string, maxLength = 15) => {
    if (string.length > maxLength) {
      return `${string.substring(0, maxLength)}…`
    }
    return string
  }

  /**
   * Returns the patient name in the format of "Last, First"
   *
   * @param {boolean} truncate - Whether to truncate the name
   * @returns {string} - The patient name in the format of "Last, First"
   */
  const withLastFirst = (truncate = false) => {
    const truncator = truncate ? truncateName : noopTruncate
    if (hasFirstAndLast()) {
      return `${truncator(last)}, ${truncator(first)}`
    }

    if (hasRealName()) {
      const realName = idInfo?.realName || ''
      const splitName = realName.split(' ').filter((n) => n)
      const maybeLastName = splitName.pop()
      if (splitName.length > 0) {
        return `${truncator(maybeLastName)}, ${truncator(splitName.join(' '))}`
      }
      return truncator(maybeLastName)
    }
    return truncator(codeName)
  }

  return {
    withLastFirst,
    firstName,
    fullName,
  }
}
