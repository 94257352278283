import { useCallback, useContext, useState } from 'react'
import { push } from 'redux-first-history'
import { UPDATE_ORG } from './mutations'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import UserContext from 'ui/contexts/UserContext'
import { useRefetchMe } from 'ui/screens/Root'
import OrgSwitchWarningMessage from './OrgSwitchWarningMessage'
import { MILLISECONDS_IN_SECONDS } from 'utilities/time'
import OrgList from './OrgList'
import { useRefetchPatients } from 'ui/screens/Patients/queries'
import { getPatientListStartEndTimes } from 'ui/screens/Patients/PatientList/PatientTable/helpers'

const TIMEOUT_IN_SECONDS = 10

const OrgListWrapper = () => {
  const { membershipList, defaultMembership } = useContext(UserContext)
  const [error, setError] = useState()
  const [timeoutId, setTimeoutId] = useState(false)
  const { startTime, endTime } = getPatientListStartEndTimes()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const [updateOrgMutation] = useMutation(UPDATE_ORG, {
    onError: (error) => {
      console.error(error)
      clearTimeout(timeoutId)
      setError('Unable to update Org')
      setTimeoutId(
        setTimeout(
          () => setError(false),
          TIMEOUT_IN_SECONDS * MILLISECONDS_IN_SECONDS,
        ),
      )
    },
    refetchQueries: [
      useRefetchMe(),
      useRefetchPatients({ startTime, endTime }),
    ],
    awaitRefetchQueries: true,
  })

  /**
   * Runs the updateOrgMutation and manages success (pushing user to the
   * /patients view and updating their patient list) and
   * error (showing error notification) states.
   *
   * @param {string} newOrgID the ID of the org the user will attempt to switch to
   * @returns {void}
   */
  const updateOrg = useCallback(
    async (newOrgID) => {
      try {
        await updateOrgMutation({
          variables: {
            input: {
              orgId: newOrgID,
            },
          },
        })
        dispatch(push('/patients'))
      } catch (error) {
        console.error(error)
        setError(error)
      }
    },
    [updateOrgMutation, dispatch],
  )

  /**
   * Shows a confirmation warning if the user has not asked to hide it.
   *
   * @param {string} newOrgID the ID of the org the user will attempt to switch to
   * @param {string} name the name of the org the user will attempt to switch to
   * @returns {void}
   */
  const confirmUpdateOrg = useCallback(
    async (newOrgID, name) => {
      const hideWarning = localStorage.getItem('RuneDoNotShowOrgWarning')
      const viewString = `You are about to switch to accessing ${name} patient data. This will also change what you can access with the SDK and may result in broken queries until you switch back or update your code.`
      if (!hideWarning) {
        await confirm({
          confirmationButtonProps: { variant: 'contained' },
          title: ' ',
          description: (
            <>
              {viewString}
              <OrgSwitchWarningMessage />
            </>
          ),
        })
      }
      updateOrg(newOrgID)
    },
    [confirm, updateOrg],
  )

  const memberships = membershipList?.memberships || [defaultMembership]

  return (
    <OrgList
      defaultMembership={defaultMembership}
      memberships={memberships}
      onClickCallback={confirmUpdateOrg}
      error={error}
    />
  )
}

export default OrgListWrapper
