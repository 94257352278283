import { useQuery } from '@apollo/client'
import { GET_PATIENT_EDITABLE_FIELDS } from 'domains/carrotGraph/queries'

/**
 * Retrieve patient fields that can be edited.
 * @param {string} patientId patient ID
 * @return {{loading, error, patient}} loading, error, patient
 */
export const usePatientEditableFields = (patientId) => {
  const { loading, error, data } = useQuery(GET_PATIENT_EDITABLE_FIELDS, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
  })
  const patient = data?.patient
  return {
    loading,
    error,
    patient,
  }
}
export const useRefetchPatientEditableFields = (patientId) => ({
  query: GET_PATIENT_EDITABLE_FIELDS,
  variables: { patientId },
})
