import { memo, useEffect, useState } from 'react'
import { colors } from 'theme/colors'
import { Stack, Text } from 'ui/baseComponents'
import ReportSection from 'ui/clinicianScreens/Patient/Report/components/ReportSection'
import ReportTableActivityCategories from 'ui/clinicianScreens/Patient/Report/sections/Activity/ReportTableActivityCategories'
import ReportChartActivityCategories from 'ui/clinicianScreens/Patient/Report/sections/Activity/ReportChartActivityCategories'
import ReportMetric from 'ui/clinicianScreens/Patient/Report/components/ReportMetric'
import ReportTableActivities from 'ui/clinicianScreens/Patient/Report/sections/Activity/ReportTableActivities'
import ReportTitle from 'ui/clinicianScreens/Patient/Report/components/ReportTitle'
import EditableLLMContent from 'ui/clinicianScreens/Patient/Report/components/EditableLLMContent'
import useActivityOverview from 'ui/hooks/useActivityOverview'
import {
  getActivityTableHelper,
  getActivityDurationsByCategory,
  getWeeklyActivityAverages,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/helpers'
import {
  DATA_ATTRIBUTES,
  NO_DATA_EMPTY_STATE,
  SECTION_TITLES,
} from 'ui/clinicianScreens/Patient/Report/consts'
import {
  ACTIVITY_COLORS,
  HELPER_ACTIVITY_TIME_IN_CATEGORY,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/consts'
import activityIconDuration from 'assets/img/activity-duration.svg'
import activityIconHR from 'assets/img/activity-hr.svg'

const ReportSectionActivityHR = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  llmKey,
  llmSummary,
  onUpdate = () => {},
  addToUnsavedChanges = () => {},
  removeFromUnsavedChanges = () => {},
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
}) => {
  const STYLES = {
    weeklyAvgValues: {
      paddingLeft: '34px',
    },
    highIntensityDot: {
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '50%',
        backgroundColor: ACTIVITY_COLORS.highIntensityHR,
        marginRight: '0.5rem',
        verticalAlign: 'middle',
      },
    },
    lowIntensityDot: {
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '50%',
        backgroundColor: ACTIVITY_COLORS.lowIntensityHR,
        marginRight: '0.5rem',
        verticalAlign: 'middle',
      },
    },
    tableContent: {
      padding: '0.5rem 1.5rem',
      margin: 0,
    },
    table: {
      '& .activity-name:before': {
        content: '""',
        display: 'inline-block',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        backgroundColor: colors.COOL[500],
        marginRight: '0.5rem',
        verticalAlign: 'middle',
      },
    },
  }

  const title = SECTION_TITLES.ACTIVITY
  const { activity: activityDataAttributes } = DATA_ATTRIBUTES
  const { noActivityDataAttribute } = activityDataAttributes
  const [attributesForPlaywrightScript, setAttributesForPlaywrightScript] =
    useState([noActivityDataAttribute.playwrightScriptSelector])

  const { data: recentData, loading: loadingRecentActivity } =
    useActivityOverview({ startDate, endDate })
  const { data: prevData, loading: loadingPrevActivity } = useActivityOverview({
    startDate: prevStartDate,
    endDate: prevEndDate,
  })

  const loading = loadingRecentActivity || loadingPrevActivity

  useEffect(() => {
    if (loading) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const hasData = recentData?.activity_overview?.length > 0

  useEffect(() => {
    if (recentData) {
      hasData ? addToHasDataSet(title) : removeFromHasDataSet(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData])

  const {
    weeklyDurationAvg,
    weeklyDurationDiff,
    weeklyHighIntensityAvg,
    weeklyHighIntensityDiff,
  } = getWeeklyActivityAverages(recentData, prevData)

  const recentCategoryDistribution = recentData?.category_distribution || []
  const prevCategoryDistribution = prevData?.category_distribution || []

  const activityDurationsByCategory = getActivityDurationsByCategory(
    recentCategoryDistribution,
    prevCategoryDistribution,
  )

  const hasRecentCategoryData =
    recentCategoryDistribution.reduce(
      (acc, category) => acc + category.count,
      0,
    ) > 0

  const updateAttributesForPlaywrightScript = (newAttributes) => {
    // If we're getting new attributes, it means we've got data, so the script no longer needs to wait for the no data attribute
    const filteredAttributes = attributesForPlaywrightScript.filter(
      (attribute) =>
        attribute !== noActivityDataAttribute.playwrightScriptSelector,
    )

    setAttributesForPlaywrightScript(() => [
      ...filteredAttributes,
      ...newAttributes,
    ])
  }

  const WeeklyAverageTitle = ({
    title,
    color,
    icon,
    value,
    diff,
    ...props
  }) => (
    <Stack spacing={0} {...props}>
      <Stack direction="row" alignItems="center">
        <img src={icon} alt={`${title} icon`} width="26" />
        <Text variant="body16B" component="h3" color={color}>
          {title} <Text variant="body16">Avg/week</Text>
        </Text>
      </Stack>
      <ReportMetric
        {...{
          value: `${value}m`,
          trend: diff,
          variant: null,
          trendLabel: 'm',
          iconVariant: diff > 0 ? 'better' : 'worse',
          sx: STYLES.weeklyAvgValues,
        }}
      />
    </Stack>
  )

  return (
    <ReportSection
      {...{
        title,
        subtitle: 'Data recorded by Apple Health and activity logs',
        hidden:
          hiddenSections.has(title) ||
          (isClinicianReport && !hasDataSet.has(title)),
        ...{
          'data-expected-activity-attributes': attributesForPlaywrightScript,
        },
      }}
    >
      {llmSummary && (
        <EditableLLMContent
          {...{
            llmKey,
            llmSummary,
            onUpdate,
            addToUnsavedChanges,
            removeFromUnsavedChanges,
            ...(!hasData && {
              [`data-${noActivityDataAttribute.carrotWebSuffix}`]: true,
            }),
          }}
        >
          {llmSummary}
        </EditableLLMContent>
      )}

      {hasData && (
        <>
          <ReportTitle
            justifyContent="center"
            spacing={8}
            data-cy="activity-averages"
          >
            <WeeklyAverageTitle
              {...{
                title: 'Weekly activity',
                color: ACTIVITY_COLORS.time,
                icon: activityIconDuration,
                value: weeklyDurationAvg,
                diff: weeklyDurationDiff,
                'data-cy': 'activity-average-duration',
              }}
            />
            <WeeklyAverageTitle
              {...{
                title: 'HR zone 3+ min',
                color: ACTIVITY_COLORS.highIntensityHR,
                icon: activityIconHR,
                value: weeklyHighIntensityAvg,
                diff: weeklyHighIntensityDiff,
                'data-cy': 'activity-average-high-intensity',
              }}
            />
          </ReportTitle>

          {hasRecentCategoryData ? (
            <>
              {HELPER_ACTIVITY_TIME_IN_CATEGORY}
              <Stack direction="row" justifyContent="space-between" spacing={4}>
                <Stack sx={{ flex: 1 }}>
                  <ReportChartActivityCategories
                    {...{
                      activityDurationsByCategory,
                      updateAttributesForPlaywrightScript,
                    }}
                  />
                </Stack>
                <Stack sx={{ flex: 1 }}>
                  <ReportTableActivityCategories
                    {...{ activityDurationsByCategory }}
                  />
                </Stack>
              </Stack>
            </>
          ) : (
            <ReportChartActivityCategories
              {...{
                updateAttributesForPlaywrightScript,
              }}
            />
          )}

          <ReportTitle>
            <Text variant="body16B" component="p" color={ACTIVITY_COLORS.time}>
              Activity and HR zone 3+ time
            </Text>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Text
                variant="body14"
                component="p"
                sx={{ ...STYLES.highIntensityDot }}
              >
                In zone 3 or higher
              </Text>
              <Text
                variant="body14"
                component="p"
                sx={{ ...STYLES.lowIntensityDot }}
              >
                Below zone 3
              </Text>
            </Stack>
          </ReportTitle>

          <ReportTableActivities {...{ recentData, prevData }} />

          <div data-cy="activity-table-helper">
            {getActivityTableHelper(recentData, activityDurationsByCategory)}
          </div>
        </>
      )}

      {!hasData && !llmSummary && (
        // data-no-activity-data used by script for automated reports
        <Text variant="body16" component="p" data-no-activity-data>
          {NO_DATA_EMPTY_STATE}
        </Text>
      )}
    </ReportSection>
  )
}

export default memo(ReportSectionActivityHR)
