import { useSearchParams } from 'react-router-dom'
import { Link, Stack, Text } from 'ui/baseComponents'
import StrivePDGuardianLogo from 'assets/img/strivepd-guardian-logo.svg'
import StrivePDGuardianQRCode from 'assets/img/strivepd-guardian-qr-code.png'
import { colors } from 'theme/colors'

const STYLES = {
  adContainer: {
    backgroundColor: colors.COOL[50],
    border: `2px solid ${colors.VIOLET[600]}`,
    borderRadius: '24px',
    padding: '1.5rem',
    '@media screen': {
      display: 'none',
    },
  },
}

const GuardianAd = () => {
  const [searchParams] = useSearchParams()
  const adCampaign = searchParams.get('ad_campaign')

  if (adCampaign !== 'guardian') {
    return null
  }

  return (
    <Stack
      direction="row"
      spacing={4}
      alignItems="start"
      sx={STYLES.adContainer}
    >
      <img
        alt="StrivePD Guardian Logo"
        src={StrivePDGuardianLogo}
        width={120}
      />
      <Stack>
        <Text variant="head24B" color={colors.PURPLE[600]}>
          Upgrade now to optimize your care
        </Text>
        <Stack spacing={0} sx={{ marginTop: '1rem' }}>
          <Text variant="body14">
            Want an expert to review your monthly StrivePD reports with you?{' '}
          </Text>
          <Text variant="body14">
            Upgrade to <Text variant="body14B">StrivePD Guardian</Text> and
            receive personalized coaching,{' '}
          </Text>
          <Text variant="body14">
            proactive alerts, and tailored insights to optimize your care.{' '}
          </Text>
          <Text variant="body14">
            Feel more confident and prepared for your next doctor’s visit.
          </Text>
        </Stack>
        <Stack direction="row" sx={{ marginTop: '1rem' }}>
          <Text variant="body14">
            Learn more:{' '}
            <Link
              href="https://www.strive.group/guardian"
              external
              underline
              light
            >
              www.strive.group/guardian
            </Link>
          </Text>
        </Stack>
      </Stack>
      <img
        alt="StrivePD Guardian QR Code"
        src={StrivePDGuardianQRCode}
        width={120}
      />
    </Stack>
  )
}

export default GuardianAd
