import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createReduxHistoryContext } from 'redux-first-history'
import reducers from './reducers'

/*  Use the compose function provided by Redux Devtools if Redux Devtools is installed. (https://github.com/reduxjs/redux-devtools/tree/main/extension)
    Otherwise, use Redux's compose function
*/
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

/**
 * Create a Redux store, with initial state and dependencies,
 * which will be injected via thunk.withExtraArgument and
 * available in all action-creators
 * @param initialState
 * @param dependencies
 * @param historyObject, history object created by using createBrowserHistory or createMemoryHistory (functions made available by the history API - https://github.com/remix-run/history/blob/dev/docs/api-reference.md#createbrowserhistory)
 * @return {Store<any, Action> & {dispatch: any}}
 */
export const initStore = ({ initialState, dependencies, historyObject }) => {
  //  CONNECT REDUX-FIRST-HISTORY
  /*  This implementation of redux-first-history is a bit different than the implementation found in the documentation. 
      The documentation assumes that we are always using createBrowserHistory from the history API. However, we also use createMemoryHistory. 
      This modified implementation was inspired by: https://github.com/salvoravida/redux-first-history/issues/1. 
  */
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history: historyObject,
    })

  //  ADD MIDDLEWARE
  const thunkMiddleware = thunk.withExtraArgument(dependencies)
  let middlewares = [thunkMiddleware, routerMiddleware]

  if (process.env.NODE_ENV === 'development') {
    // Logger must be the last in the chain
    const logger = createLogger({ collapsed: true })
    middlewares.push(logger)
  }

  // CREATE STORE
  const store = createStore(
    reducers(routerReducer),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )

  // CREATE HISTORY
  const history = createReduxHistory(store)

  return { history, store }
}

export default initStore
