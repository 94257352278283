import { useTangerineApi } from 'domains/tangerine/hooks'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'

const useDailyCheckInsSymptoms = ({ options }) => {
  const patientContext = useContext(PatientContext) || {}
  const { config } = window.Rune.Carrot

  const params = {
    start_at: options?.start,
    end_at: options?.end,
    patient_id: patientContext.id,
  }

  const {
    data = [],
    errors,
    loading,
  } = useTangerineApi({
    url: `https://${config.strivepd.host}/api/v1/daily_checkins`,
    params,
    method: 'GET',
    axiosOptions: { responseType: 'arraybuffer' },
  })

  return {
    data,
    loading,
    hasError: !!(errors && errors.length),
  }
}

export default useDailyCheckInsSymptoms
