import { gql } from '@apollo/client'

export const PATIENT_SEARCH = gql`
  query patientSearch(
    $codeName: String
    $email: String
    $firstName: String
    $lastName: String
    $startTime: Float!
    $endTime: Float!
  ) {
    patientSearch(
      codeName: $codeName
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      patients {
        id
        codeName
        identifiableInfo {
          email
          firstName
          lastName
          realName
          demographicData {
            dateOfBirth
            clinicalVisit
            nextVisitMonthYear
          }
        }
        redFlags(endTime: $endTime, startTime: $startTime) {
          totalSummary {
            counts {
              classification {
                id
                namespace
                category
                enum
              }
              displayName
              count
            }
          }
          needsEducation
          alertClinician
        }
      }
      moreResults
    }
  }
`
