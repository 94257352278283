import { TextField as MUITextField } from '@mui/material'
import { bool, func, number, object, oneOfType, string } from 'prop-types'
import { SHARED_STYLES } from './consts'

const STYLES = {
  disabledInput: {
    '& .MuiInputBase-input.Mui-disabled': SHARED_STYLES.disabledInputBox,
    ...SHARED_STYLES.disabledInput,
  },
  readOnlyInput: SHARED_STYLES.readOnlyInput,
}

const TextField = ({
  disabled,
  error,
  helperText,
  inputProps,
  label,
  name,
  onChange,
  sx,
  type = 'text',
  value = '',
}) => {
  const readOnly = inputProps?.readOnly

  return (
    <MUITextField
      {...{
        disabled,
        error,
        fullWidth: true,
        helperText,
        InputProps: inputProps,
        label,
        onChange,
        name,
        value,
        sx: {
          ...sx,
          ...(readOnly && STYLES.readOnlyInput),
          ...(disabled && !readOnly && STYLES.disabledInput),
        },
        type,
      }}
    />
  )
}

TextField.propTypes = {
  disabled: bool,
  error: bool,
  helperText: string,
  inputProps: object,
  label: string.isRequired,
  name: string,
  onChange: func,
  sx: object,
  type: string,
  value: oneOfType([number, string]),
}

export default TextField
