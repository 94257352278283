import { Text } from 'ui/baseComponents/Text'
import { colors } from 'theme/colors'

export const REPORT_VARIANTS = {
  din: 'din',
  guardian: 'guardian',
}

export const REPORT_PERIOD_IN_WEEKS = 4
export const REPORT_PERIOD_IN_DAYS = REPORT_PERIOD_IN_WEEKS * 7

export const AVG_PER = {
  day: 'Avg/Day',
  week: 'Avg/Week',
}

export const PERIODS = {
  previous: 'Previous Period',
  recent: 'Recent Period',
}

// eslint-disable-next-line prefer-destructuring
export const NEW_LABEL_COLOR = colors.ORANGE[500]

export const SECTION_TITLES = {
  RED_FLAGS: 'Red flags reported',
  OTHER_ISSUES: 'Other reported issues',
  HIGHLIGHTS: 'Highlights',
  SYMPTOM_LOG: 'Symptom Log',
  TREMOR: 'Tremor',
  DYSKINESIA: 'Dyskinesia',
  ACTIVITY: 'Activity',
  MOBILITY: 'Mobility',
  MEDICATION: 'Medication',
  SUPPORTING_MATERIALS: 'Supporting Materials',
  SUPPORTING_MATERIALS_RED_FLAGS_AND_OTHER_REPORTED_ISSUES:
    'Supporting material regarding Red Flags and Other Reported Issues',
  ABOUT_DIN: 'About Dyskinesia Insights Network',
  DYSKINESIA_SYMPTOMS: 'Dyskinesia Symptoms',
  DIN: 'DIN',
}

export const CHART_TITLES = {
  ACTIVITY_LOG: 'Activity Log',
  TREMOR: 'Tremor',
  DYSKINESIA: 'Dyskinesia',
  SYMPTOM: 'Most logged symptom',
  MEDICATION: 'Medication',
}

export const LLM_KEYS = {
  ACTIVITY: 'activity',
  SYMPTOM: 'symptom',
}

export const SAVE_BUTTON_STYLES = {
  padding: 0,
  '@media print': {
    display: 'none',
  },
}

export const CHANGE_THRESHOLD_DEFAULT = 10

export const NO_CHANGE = 'No change'

export const NO_PREV_DATA = 'No data is available for previous period'

export const NO_DATA_EMPTY_STATE = 'No data is available for recent period'

export const NO_ACTIVITY_TIME =
  'No activity time is available for recent period'

export const WATCH_VIDEO = 'watch our video'

export const VIDEO_LINKS = {
  bestPractices:
    'https://www.loom.com/share/8f1f69dd0eac42568bb42b53b0531004?sid=14d1df92-0984-4dd3-a478-a504ef15e99b',
  howToSetUpAndPairYourAppleWatch:
    'https://www.loom.com/share/012728165b6144438a454508aea88846?sid=e01c4bde-7be2-47d9-9aa3-ea22f7862666',
  importanceOfLoggingSymptoms:
    'https://www.loom.com/share/81260428973a432580ebe979fd21f793?sid=3efb3bc1-f19a-4f6c-aae8-293dee4b4902',
  loggingItems:
    'https://www.loom.com/share/a6a259a030f2489fa40e4527859b421e?sid=1274fd43-fcfd-484d-b92c-bb736a2cb82b',
  medication:
    'https://www.loom.com/share/6bad503191254af1b7e87374c6eb22bf?sid=62422393-874a-4c9d-ac17-6ce78e044e92',
  medicationScheduleTutorial:
    'https://www.loom.com/share/666b57bd63a1461c8ef3e0ce49d3ca06?sid=08d6d57f-5bf0-42a3-8664-3cea9ccf8a67',
  tremorAndDyskinesia:
    'https://www.loom.com/share/2dd3ff15359742c1bfc58bac318dc0bd?sid=101e139b-de85-444c-a4d1-6cd25e605d0d',
  whatIsMobility:
    'https://www.loom.com/share/fc5e7eba349b4ee4a010df41c960121d?sid=22e75667-0e7e-4867-948c-e9f1670a4e41',
  importanceOfActivity:
    'https://www.loom.com/share/43edb9abeecc4f189d4c2a47beaf220a?sid=425b39fc-592f-4efd-9617-9096ddadab58',
  howToLogActivity:
    'https://www.loom.com/share/d0d34361b20745fda7910dd49df0f1f9?sid=30ded699-7bb0-4300-8632-362d3bafa256',
  hrZones:
    'https://www.loom.com/share/7e47eb7da2fe448eaa906bd580e64be5?sid=be2f805a-8c52-4757-81e0-35fc0936ed6c',
  aerobic:
    'https://www.loom.com/share/39710b2b047c4b67b8031c1d2e4407d5?sid=733b2ecf-793f-4b03-b774-cd422f146425',
  strength:
    'https://www.loom.com/share/80935206d8da496abe79bb88c8b67fc1?sid=2da06ac6-0777-4e81-ac6f-f4a2429549ab',
  flexibility:
    'https://www.loom.com/share/9b26729b3d2f4c9a972174638e15f7d3?sid=4807f2b9-7b01-4c48-a0d1-903e50ce6292',
  balance:
    'https://www.loom.com/share/c9d1292e6557463cad2dc604ff710a1d?sid=7fe1f96d-e290-4601-8bcf-0f5861f79d62',
  rpe: 'https://www.loom.com/share/11244d395324465c88e47eaf0b2c0bdb?sid=c0d6f931-dacf-4ffb-9001-4eee4bea4159',
}

// FOOTER
export const LLM_DISCLAIMER = (
  <Text variant="body14" component="p">
    *The summaries are generated by an artificial intelligence language model,
    which draws insights from your engagement with the Apple Watch and the
    StrivePD app, encompassing notes and logs.
  </Text>
)

export const FOOTER_DISCLAIMER = (
  <div>
    <Text variant="body14" component="p">
      Confidential: This report is for informational purposes only and should
      only be shared with the user’s immediate care team. Please consult your
      healthcare provider for any concerns or questions related to your medical
      condition and treatment plan.
    </Text>
    <Text variant="body14B" component="p">
      If you are having a medical emergency, seek immediate medical care.
    </Text>
  </div>
)

export const DATA_ATTRIBUTES = {
  activity: {
    noActivityDataAttribute: {
      carrotWebSuffix: 'no-activity-data',
      playwrightScriptSelector: '[data-no-activity-data]',
    },
    goalChartEmptyAttribute: {
      playwrightScriptSelector: '[data-goal-chart-empty]',
    },
    goalChartRecentAttribute: {
      playwrightScriptSelector: '[data-chart-id="activity-goal-chart-recent"]',
    },
    goalChartPreviousAttribute: {
      playwrightScriptSelector:
        '[data-chart-id="activity-goal-chart-previous"]',
    },
    categoryChartEmptyAttribute: {
      playwrightScriptSelector: '[data-category-chart-empty]',
    },
    categoryChartAttribute: {
      playwrightScriptSelector: '[data-chart-id="activity-category-chart"]',
    },
  },
  dyskinesia: {
    noDyskinesiaDataAttribute: {
      carrotWebSuffix: 'no-dyskinesia-data',
      playwrightScriptSelector: '[data-no-dyskinesia-data]',
    },
    dyskinesiaChartRecentAttribute: {
      playwrightScriptSelector:
        '[data-chart-id="dyskinesia-chart-recent"] .recharts-legend-item-text >> text="Recent Period"',
    },
    dyskinesiaChartPreviousAttribute: {
      playwrightScriptSelector:
        '[data-chart-id="dyskinesia-chart-previous"] .recharts-legend-item-text >> text="Previous Period"',
    },
  },
  medication: {
    loggedMedicationsChartAttribute: {
      carrotWebSuffix: 'medication-chart',
      playwrightScriptSelector:
        '[data-chart-id="medication-chart"] .recharts-legend-item-text >> text="Logged medication"',
    },
    noLoggedMedicationsChartAttribute: {
      carrotWebSuffix: 'no-logged-medications-chart',
      playwrightScriptSelector: '[data-no-logged-medications-chart]',
    },
    noMedicationDataAttribute: {
      carrotWebSuffix: 'no-medication-data',
      playwrightScriptSelector: '[data-no-medication-data]',
    },
  },
  tremor: {
    noTremorDataAttribute: {
      carrotWebSuffix: 'no-tremor-data',
      playwrightScriptSelector: '[data-no-tremor-data]',
    },
    tremorChartRecentAttribute: {
      playwrightScriptSelector:
        '[data-chart-id="tremor-chart-recent"] .recharts-legend-item-text >> text="Recent Period"',
    },
    tremorChartPreviousAttribute: {
      playwrightScriptSelector:
        '[data-chart-id="tremor-chart-previous"] .recharts-legend-item-text >> text="Previous Period"',
    },
  },
}
