import {
  confirmResetPassword,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
} from 'aws-amplify/auth'
import { COGNITO_ERRORS, CUSTOM_ERROR_MESSAGES } from 'ui/screens/SignIn/consts'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import { getAutomatedUserJwt } from 'utilities/automatedUser'

/**
 * Retrieves the authentication headers for API access.
 *
 * This function first checks if there is an automated user JWT available.
 * If so, it returns the headers containing the automated user JWT.
 * If not, it attempts to fetch the current authenticated user's session
 * and extract the access token from it. If successful, it returns the headers
 * containing the user's access token.
 *
 * If an error occurs while fetching the user's session, it logs the error,
 * redirects the user to the sign-in page, and returns a rejected promise.
 *
 * @returns {Promise<Object>} A promise that resolves to an object containing the authentication headers.
 * @throws Will throw an error if the user is not authenticated or the session is expired.
 */
export const getAuthHeaders = async () => {
  const automatedUserJwt = getAutomatedUserJwt()

  if (automatedUserJwt) {
    return {
      'X-Rune-Internal-Service-Access-Token': automatedUserJwt,
    }
  }

  try {
    /**
     * The fetchAuthSession API automatically refreshes the user's session
     * when the authentication tokens have expired and a valid refreshToken is present.
     */
    const session = await fetchAuthSession()
    const token = session?.tokens?.accessToken || null

    return {
      'X-Rune-User-Access-Token': token,
    }
  } catch (error) {
    console.error('Error retrieving JWT token:', error)
    // Redirect to sign-in if user is not authenticated or session is expired
    window.location = ROUTE_METADATA.signin.path
    return Promise.reject('Authentication required for API access')
  }
}

/**
 * Fetches the user's attributes from Cognito.
 * @returns {Promise<Object>} A promise that resolves to an object containing the user's attributes.
 */
export const getUserAttributes = async () => {
  try {
    const userAttributes = await fetchUserAttributes()
    return userAttributes
  } catch (error) {
    console.error('Error fetching user attributes:', error)
  }
}

/**
 * Handles the forgot password flow and customizes errors based on error returned by Cognito.
 * @param {Object} input - The input object.
 * @param {string} input.username - The username of the user.
 * @returns {Promise} A promise that resolves when the password reset email is sent.
 * @throws Will throw an error if the email address is not found or an error occurs while sending the password reset email.
 */
export const handleForgotPassword = async (input) => {
  const { username } = input
  try {
    return await resetPassword({ username })
  } catch (error) {
    const showInvalidEmailError = (error) =>
      error?.name === COGNITO_ERRORS.userNotFoundException

    let errorMessage = ''
    if (showInvalidEmailError(error)) {
      errorMessage = CUSTOM_ERROR_MESSAGES.email
    } else if (error) {
      errorMessage = CUSTOM_ERROR_MESSAGES.resetPassword
    }

    throw new Error(errorMessage)
  }
}

/**
 * Handles the forgot password submit flow and customizes errors based on error returned by Cognito.
 * @param {Object} input - The input object.
 * @param {string} input.username - The username of the user.
 * @param {string} input.confirmationCode - The confirmation code sent to the user's email.
 * @param {string} input.newPassword - The new password to set.
 * @returns {Promise} A promise that resolves when the password is successfully reset.
 * @throws Will throw an error if the confirmation code is invalid or the new password is invalid.
 */
export const handleForgotPasswordSubmit = async (input) => {
  const { username, confirmationCode, newPassword } = input

  const checkErrorType = ({ error, errorType, messageIncludes }) =>
    !!(
      error?.name === errorType ||
      (error?.name === COGNITO_ERRORS.invalidParameterException &&
        error?.message.includes(messageIncludes))
    )

  const showVerificationCodeError = (error) =>
    checkErrorType({
      error,
      errorType: COGNITO_ERRORS.codeMismatchException,
      messageIncludes: 'confirmationCode',
    })
  const showPasswordError = (error) =>
    checkErrorType({
      error,
      errorType: COGNITO_ERRORS.invalidPasswordException,
      messageIncludes: 'password',
    })

  try {
    return await confirmResetPassword({
      username,
      confirmationCode,
      newPassword,
    })
  } catch (error) {
    let errorMessage = ''

    if (showVerificationCodeError(error)) {
      errorMessage = CUSTOM_ERROR_MESSAGES.verificationCode
    } else if (showPasswordError(error)) {
      errorMessage = CUSTOM_ERROR_MESSAGES.invalidPassword
    }

    throw new Error(errorMessage)
  }
}
