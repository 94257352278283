import { gql } from '@apollo/client'

export const GET_PATIENT_RED_FLAGS = gql`
  query getPatientRedFlags(
    $patientId: ID!
    $startTime: Float!
    $endTime: Float!
  ) {
    patient(id: $patientId) {
      id
      redFlags(endTime: $endTime, startTime: $startTime) {
        weeklySummary {
          startDate
          endDate
          counts {
            classification {
              id
              namespace
              category
              enum
            }
            displayName
            count
          }
        }
      }
    }
  }
`
