export const smallChartHeight = 12
export const mediumChartHeightWithAxis = 85
export const mediumChartHeightWithoutAxis = 55

export const SECTION_TITLES = {
  summary: 'Red Flag Symptoms',
  checkin: 'Daily Checkin',
  dyskinesia: 'Dyskinesia',
  medication: 'Medication',
  notes: 'Notes',
  symptoms: 'Symptoms',
  sleep: 'Sleep',
  tremor: 'Tremor',
  tremorAndDyskinesia: 'Tremor and Dyskinesia',
}

export const DAILY_EVENT_CATEGORIES = {
  symptom: 'symptom',
  sideEffect: 'side-effect',
  medication: 'medication',
  note: 'note',
}

export const ALL_DAILY_EVENT_CATEGORIES = [
  DAILY_EVENT_CATEGORIES.sideEffect,
  DAILY_EVENT_CATEGORIES.symptom,
  DAILY_EVENT_CATEGORIES.medication,
  DAILY_EVENT_CATEGORIES.note,
]

export const DAILY_EMPTY_DAY_TEXT = 'No data available'
