import { bool, func, node, oneOfType, string } from 'prop-types'
import * as MUI from '@mui/material'
import { colors } from 'theme/colors'
import { Link as RouterLink } from 'react-router-dom'

const Link = ({
  href = '',
  children,
  className,
  color,
  onClick,
  external,
  light,
  underline,
  ...props
}) => {
  const commonStyles = {
    textDecoration: underline ? 'underline' : 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  }

  const getLinkColor = () => {
    if (color) {
      return color
    }
    return light ? colors.PRIMARY[1000] : colors.BLUEBERRY[500]
  }

  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }

  const internalExternalProps = external
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {
        component: RouterLink,
        to: href,
        onClick: onClick ? handleClick : () => {},
      }

  return (
    <MUI.Link
      {...{
        'data-cy': 'link',
        sx: commonStyles,
        className,
        href,
        underline: 'none',
        color: getLinkColor(),
        ...internalExternalProps,
        ...props,
      }}
    >
      {children || href}
    </MUI.Link>
  )
}

Link.propTypes = {
  href: string,
  children: oneOfType([string, node]),
  className: string,
  color: string,
  onClick: func,
  external: bool,
  light: bool,
}

export default Link
