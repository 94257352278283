import { dateFloat } from '../utils'
import {
  DEVICE_TYPE_APPLE_WATCH,
  DEVICE_TYPE_STRIVE_STUDY,
  DEVICE_TYPE_EVENTS,
} from '../../../../ui/screens/EditPatient/Devices/consts'
import { TIMEZONE } from 'test-utils/consts'
export const deviceTypes = [
  DEVICE_TYPE_APPLE_WATCH,
  DEVICE_TYPE_STRIVE_STUDY,
  DEVICE_TYPE_EVENTS,
]
export const getDeviceType = (id) => deviceTypes.find((d) => d.id === id)
export const makeDevice = ({
  id = '149e9d5d-0b93-4aa8-963b-e9bfffbcf096',
  deviceShortId = '7d57797b',
  deviceType = DEVICE_TYPE_APPLE_WATCH.id,
  alias = id,
  disabled = false,
  patientId,
  channels = ['0', '1', '2', '3'],
  kind = 'Apple Watch',
}) => {
  const device = {
    id,
    deviceShortId,
    alias,
    disabled,
    deviceType: getDeviceType(deviceType),
    channels,
    kind,
    createdAt: dateFloat(),
  }
  if (patientId) {
    device.patientId = patientId
  }
  return device
}

/**
 * Creates a dummy clientKey for testing.
 *
 * @returns {object} a dummy clientKey
 */
export const makeClientKey = () => {
  const id = '0e0eabdd-b228-48b4-998e-8f7af06202ab'
  const createdAt = dateFloat()
  return {
    id,
    clientKeyId: id,
    createdAt,
  }
}

/**
 * Creates a dummy client for testing.
 *
 * @param {string} id the optionally specified ID of the dummy client
 * @param {string} displayName the optionally specified display name of the dummy client
 * @param {object|func} clientKeys the optionally specified clientKeys of the dummy client. If not specified will call the makeClientKey factory.
 * @returns {object} a formatted mock client
 */
export const makeClient = ({ id, displayName, clientKeys }) => ({
  id: id || '70e9a3e0-dea8-45a0-a22f-43ba82cde048',
  displayName,
  createdAt: dateFloat(),
  clientKeyList: {
    clientKeys: clientKeys || [makeClientKey()],
  },
})

/**
 * Creates a dummy patient for testing.
 *
 * @returns {object} a formatted mock patient
 */
export const makePatient = ({
  id: patientId,
  codeName,
  devices = [],
  identifiableInfo: {
    firstName = null,
    lastName = null,
    realName = null,
    email = null,
    demographicData = {
      dateOfBirth: null,
      pdDiagnosisDate: null,
      clinicalVisit: null,
      nextVisitMonthYear: null,
    },
  },
  clients,
  timezone,
  redFlags,
}) => {
  const patient = {
    id: patientId,
    codeName,
    identifiableInfo: {
      firstName,
      lastName,
      realName,
      demographicData,
    },
    deviceList: {
      pageInfo: {
        endCursor: null,
      },
      devices,
    },
    userInfo: {
      timezone,
    },
    medicationScheduleList: [],
  }

  if (email) {
    patient.identifiableInfo.email = email
  }

  if (redFlags) {
    patient.redFlags = redFlags
  }

  if (clients) {
    patient.clientList = {
      clients,
    }
  }

  return patient
}
export const PATIENT_ZERO = {
  id: 'zero',
  codeName: 'Patient Zero',
  identifiableInfo: {
    realName: 'Patient Zero',
    firstName: 'Patient',
    lastName: 'Zero',
  },
  devices: [
    makeDevice({ id: 'DeviceNPC700398H', patientId: 'zero' }),
    makeDevice({
      id: 'DeviceNPC700404H',
      patientId: 'zero',
      kind: 'Apple Watch',
    }),
    makeDevice({ id: 'Disabled Device', patientId: 'zero', disabled: true }),
  ],
  clients: [
    makeClient({ displayName: 'StriveApp' }),
    makeClient({ displayName: 'Apple Watch' }),
  ],
  timezone: TIMEZONE.losAngeles,
}
export const PATIENT_TWO = {
  id: 'RCS02',
  codeName: 'Human Two',
  identifiableInfo: {
    realName: 'Human Two',
    firstName: 'Human',
    lastName: 'Two',
  },
  devices: [
    makeDevice({ id: 'DeviceNPC700404H', patientId: 'RCS02' }),
    makeDevice({ id: 'DeviceNPC700398H', patientId: 'RCS02' }),
  ],
  clients: [],
  timezone: TIMEZONE.newYork,
}
export const PATIENT_FIVE = {
  id: 'RCS05',
  codeName: 'Human Five',
  identifiableInfo: {
    realName: 'Human Five',
    firstName: 'Human',
    lastName: 'Five',
  },
  devices: [
    makeDevice({ id: 'DeviceNPC700414H', patientId: 'RCS02' }),
    makeDevice({ id: 'DeviceNPC700415H', patientId: 'RCS02' }),
  ],
  clients: [makeClient({ displayName: 'StriveStudy' })],
  timezone: TIMEZONE.newYork,
}

export const patients = [
  makePatient(PATIENT_ZERO),
  makePatient(PATIENT_TWO),
  makePatient(PATIENT_FIVE),
]

export const getPatientAccess = (patients) =>
  patients.map((patient) => ({
    id: '0adcd7e4-5163-41f1-9558-2407f70eb20e',
    patientId: patient.id,
    patient,
  }))
export const patientAccess = getPatientAccess(patients)
