import { useCallback, useContext, useState } from 'react'
import { Card, makeStyles } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import EditClientName from './EditClientName'
import { useMutation } from '@apollo/client'
import Box from '@material-ui/core/Box'
import { Error, NotificationBar } from 'ui/components/Notifications'
import ClientKey from '../ClientKey/ClientKey'
import CreateClientKey from '../CreateClientKey'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import clsx from 'clsx'
import Switch from '@material-ui/core/Switch'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { UPDATE_CLIENT } from '../mutations'
import { useRefetchPatientEditableFields } from 'ui/hooks/usePatientEditableFields'
import { PatientContext } from 'ui/contexts'

export const useStyles = makeStyles((theme) => ({
  client: {
    margin: theme.spacing(2),
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
  },
}))

const Client = ({ client }) => {
  const { id, displayName, disabled } = client
  const { id: patientId } = useContext(PatientContext)
  const classes = useStyles()
  const [error, setError] = useState()
  const handleClose = () => {
    setError(false)
  }
  const [updateClient, { loading }] = useMutation(UPDATE_CLIENT, {
    onError: (error) => {
      console.error(error)
      setError('Unable to update Client')
    },
    refetchQueries: [useRefetchPatientEditableFields(patientId)],
  })
  const toggleDisabled = useCallback(
    async (disabled) => {
      const input = {
        clientId: client.id,
        disabled,
      }
      await updateClient({ variables: { input } })
    },
    [client.id, updateClient],
  )

  return (
    <Card className={clsx(classes.client, { [classes.disabled]: disabled })}>
      <CardContent>
        <ErrorBoundary>
          <Box
            display="flex"
            p={1}
            flexDirection="row"
            justifyContent="space-between"
          >
            <EditClientName
              clientId={id}
              name={displayName}
              disabled={disabled}
            />
            <Box>
              <Switch
                data-cy="disable-client"
                checked={!disabled}
                onChange={(e) => toggleDisabled(!e.target.checked)}
                inputProps={{ 'aria-label': 'Enable/disable client' }}
                disabled={loading}
              />
              {error && (
                <NotificationBar open={!!error} onClose={handleClose}>
                  <Error message={error} onClose={handleClose} />
                </NotificationBar>
              )}
            </Box>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="70%">Client Key ID</TableCell>
                <TableCell align="center">Created</TableCell>
                <TableCell align="center">Disabled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {client.clientKeyList.clientKeys.map((key) => (
                <ClientKey key={key.id} {...key} clientDisabled={disabled} />
              ))}
            </TableBody>
          </Table>
          {!disabled && (
            <Box display="flex" justifyContent="center" marginTop={2}>
              <CreateClientKey client={client} />
            </Box>
          )}
        </ErrorBoundary>
      </CardContent>
    </Card>
  )
}

export default Client
