const typeDefs = `
schema {
  query: Query
  mutation: Mutation
}
input AddDatasetFilterInput {
  datasetId: ID!
  filterId: ID!
  filterArgs: [FilterArgumentInput!]!
}
type Client {
  id: ID!
  clientKeyList(cursor: Cursor, limit: Int): ClientKeyList
  createdAt: Float!
  disabled: Boolean
  disabledAt: Float
  disabledBy: Membership
  disabledByActor: Actor
  displayName: String
  updatedAt: Float!
}
type ClientKey {
  id: ID!
  clientKeyId: String!
  client: Client!
  createdAt: Float!
  deviceLastDataSession: JSONString
  disabled: Boolean
  disabledAt: Float
  disabledBy: Membership
  disabledByActor: Actor
  updatedAt: Float!
}
type ClientKeyList {
  clientKeys: [ClientKey]
  pageInfo: PageInfo
}
type ClientList {
  clients: [Client]
  pageInfo: PageInfo
}
input CreateClientInput {
  displayName: String
  patientId: ID!
  withClientKey: Boolean = false
}
input CreateClientKeyInput {
  clientId: ID!
}
type CreateClientKeyOutput {
  clientKey: ClientKey!
  clientKeySecret: String!
}
type CreateClientOutput {
  client: Client!
  clientKey: ClientKey
  clientKeySecret: String
}
input CreateDatasetInput {
  title: String!
}
input CreateDeviceInput {
  patientId: ID!
  deviceTypeId: ID!
  alias: String
}
type CreateDeviceOutput {
  device: Device!
}
input CreateMembershipInput {
  admin: Boolean!
  displayName: String
  email: String!
  orgId: ID
}
input CreatePatientClientInput {
  displayName: String!
  withClientKey: Boolean = false
}
type CreatePatientClientOutput {
  client: Client!
  clientKey: ClientKey
  clientKeySecret: String
}
input CreatePatientDeviceInput {
  alias: String
  deviceTypeId: ID!
}
input CreatePatientInput {
  codeName: String!
  client: CreatePatientClientInput
  device: CreatePatientDeviceInput
}
type CreatePatientOutput {
  client: CreatePatientClientOutput
  device: Device
  patient: Patient!
}
scalar Cursor
type Dataset {
  id: ID!
  authorId: ID!
  author: Membership!
  created: Float!
  description: String
  fill: FillType
  fillOptions: [FillType!]
  filters: [Filter]
  filterOptions: [FilterType!]
  modified: Float!
  shape: Shape
  stream: StreamType
  streamOptions: [StreamType!]
  title: String
  totalResultStreams: Int
  draft: Boolean
}
type Device {
  id: ID!
  deviceShortId: String!
  alias: String
  channels: [String] @deprecated(reason: "To be deprecated.")
  createdAt: Float
  deviceType: DeviceType!
  disabled: Boolean
  disabledAt: Float
  disabledBy: Membership
  disabledByActor: Actor
  kind: String @deprecated(reason: "To be deprecated.")
  patient: Patient!
  updatedAt: Float!
}
type DeviceList {
  devices: [Device]
  pageInfo: PageInfo
}
type DeviceType {
  id: ID!
  displayName: String!
}
type IdentifiableInfo {
  realName: String
}
type FillType {
  id: ID!
  displayName: String!
}
type Filter {
  id: ID!
  args: [FilterArgument!]
}
type FilterArgument {
  id: ID!
  parameterId: ID!
  valueId: ID!
}
input FilterArgumentInput {
  filterParameterId: ID!
  filterParameterValueId: ID!
}
type FilterParameter {
  id: ID!
  displayName: String!
  valueOptions: [ValueOption!]
  required: Boolean!
}
type FilterType {
  id: ID!
  displayName: String!
  parameters: [FilterParameter!]
}
scalar JSONString
type Membership {
  id: ID!
  admin: Boolean!
  created: Float!
  disabled: Boolean
  hasPatientAccess(orgId: ID, patientId: ID!): Boolean!
  orgId: ID!
  org: Org
  userId: ID!
  user: User
  status: Status
  role: Role
}
type Role {
  admin: Boolean!
  displayName: String!
  canSeePHI: Boolean!
}
type MembershipList {
  pageInfo: PageInfo
  memberships: [Membership]
}
input ResendMembershipInvitationInput {
    membershipID: ID!
}

type ResendMembershipInvitationOutput {
    ok: Boolean
}
input ResendUserInvitationInput {
 userId: ID!
}
type ResendUserInvitationOutput {
 ok: Boolean!
}
type Mutation {
  createDataset(input: CreateDatasetInput): Dataset!
  setDatasetStream(input: SetDatasetStreamInput!): Dataset!
  addDatasetFilter(input: AddDatasetFilterInput!): Dataset!
  removeDatasetFilter(input: RemoveDatasetFilterInput!): Dataset!
  updateDatasetFilter(input: UpdateDatasetFilterInput!): Dataset!
  createClientKey(input: CreateClientKeyInput): CreateClientKeyOutput!
  updateClientKey(input: UpdateClientKeyInput): UpdateClientKeyOutput!
  createPatient(input: CreatePatientInput): CreatePatientOutput!
  updatePatient(input: UpdatePatientInput): UpdatePatientOutput!
  createDevice(input: CreateDeviceInput): CreateDeviceOutput!
  updateDevice(input: UpdateDeviceInput): UpdateDeviceOutput!
  createClient(input: CreateClientInput): CreateClientOutput!
  updateClient(input: UpdateClientInput): UpdateClientOutput!
  createMembership(input: CreateMembershipInput): Membership!
  updateMembership(input: UpdateMembershipInput): Membership!
  resendUserInvitation(input: ResendUserInvitationInput): ResendUserInvitationOutput!
  resendMembershipInvitation(input: ResendMembershipInvitationInput!): ResendMembershipInvitationOutput!
  createTask(input: CreateTaskInput = null): TaskOutput!
  updateTask(input: UpdateTaskInput = null): TaskOutput!
  addPatientsToTask(patientIds: [ID!]!, taskId: ID!): TaskAssignmentList!
  removePatientsFromTask(patientIds: [ID!]!, taskId: ID!): TaskAssignmentList!
  updateTaskAssignment(input: UpdateTaskAssignmentInput = null): TaskAssignment!
}
type Org {
  id: ID!
  created: Float!
  name: String @deprecated(reason: "please use displayName attribute")
  displayName: String
  membershipList(withDisabled: Boolean, cursor: Cursor, limit: Int): MembershipList
  patientAccessList(cursor: Cursor, limit: Int): PatientAccessList
  taskList(
    withDisabled: Boolean = true
    cursor: Cursor = null
    limit: Int = 100
  ): TaskList
}
type PageInfo {
  endCursor: Cursor
}
type Patient {
  id: ID!
  clientList(cursor: Cursor, limit: Int): ClientList
  codeName: String!
  createdAt: Float
  deviceList(cursor: Cursor, limit: Int): DeviceList
  persistentViewList(cursor: Cursor, limit: Int): PersistentViewList
  identifiableInfo(cursor: Cursor, limit: Int): IdentifiableInfo
  taskAssignmentList(
    fromTime: String!
    toTime: String!
    cursor: Cursor = null
    limit: Int = 100
  ): TaskAssignmentList
}
type PatientAccess {
  id: ID!
  created: Float!
  orgId: ID!
  org: Org!
  patientId: ID!
  patient: Patient!
}
type PatientAccessList {
  id: ID!
  pageInfo: PageInfo
  patientAccess: [PatientAccess]
}
type Query {
  org(orgId: ID): Org!
  user: User!
  patient(id: ID!): Patient!
  deviceTypes: [DeviceType]!
  task(taskId: ID = null): Task!
  taskList(
    orgId: ID = null
    withDisabled: Boolean = true
    cursor: Cursor = null
    limit: Int = 100
  ): TaskList
}
input RemoveDatasetFilterInput {
  datasetId: ID!
  filterId: ID!
}
input SetDatasetStreamInput {
  datasetId: ID!
  streamId: ID!
}
type Shape {
  shapeType: ShapeType!
  axes: [ShapeAxis]
}
type ShapeAxis {
  unit: String
  quantity: String
}
type ShapeType {
  id: ID!
  displayName: String!
  dimensions: [String]!
}
type StreamType {
  id: ID!
  displayName: String!
  shape: Shape
}
input UpdateClientInput {
  clientId: ID!
  disabled: Boolean
  displayName: String
}
input UpdateClientKeyInput {
  clientKeyId: ID!
  disabled: Boolean!
}
type UpdateClientKeyOutput {
  clientKey: ClientKey!
}
type UpdateClientOutput {
  client: Client!
}
input UpdateDatasetFilterInput {
  datasetId: ID!
  filterId: ID!
  filterArgs: [FilterArgumentInput!]!
}
input UpdateDeviceInput {
  deviceId: ID!
  deviceTypeId: ID
  disabled: Boolean
  alias: String
}
type UpdateDeviceOutput {
  device: Device!
}
input UpdateMembershipInput {
  admin: Boolean
  disabled: Boolean
  membershipId: ID!
}
input UpdatePatientInput {
  patientId: ID!
  codeName: String!
}
type UpdatePatientOutput {
  patient: Patient!
}
input UpdateUserInput {
    displayName: String!
}
type UpdateUserOutput {
    user: User!
}
type User {
  id: ID!
  created: Float!
  defaultMembershipId: ID
  defaultMembership: Membership
  email: String!
  membership(orgId: ID!): Membership!
  membershipList(cursor: Cursor, limit: Int): MembershipList
  name: String @deprecated(reason: "please use displayName attribute")
  displayName: String
  username: String!
  invitedAt: Float
  status: String
  accessTokenList(cursor: Cursor): AccessTokenList
}
type ValueOption {
  id: ID!
  displayName: String!
  value: String!
}

type ExternalUser {
  displayName: String
}

union Actor = Membership | ExternalUser

type Status {
  displayName: String!
  disabledAt: Float
  disabledBy: DisabledBy
  invitedAt: Float!
  invitedBy: InvitedBy
  joinedAt: Float
}

type DisabledBy {
  user: User
}

type InvitedBy {
  user: User
}

type UploadedBy {
  user: User
}

type AccessTokenList {
  accessTokens: [AccessToken]
  pageInfo: PageInfo
}

type AccessToken {
  id: ID!
  accessTokenId: String!
  createdAt: Float!
  disabled: Boolean!
  disabledAt: Float
  usedAt: Float
}

enum Visibility {
    ALL
    ORG
    MEMBER
}

type PersistentView {
    id: ID!
    createdAt: Float!
    disabled: Boolean
    disabledAt: Float
    disabledByActor: Actor
    latest: PersistentViewVersion!
    patient: Patient!
    visibility: Visibility
}

type PersistentViewList {
    views: [PersistentView]
    pageInfo: PageInfo!
}

type PersistentViewVersion {
    createdAt: Float!
    createdByActor: Actor!
    data: String! # Blob stored by frontend for view state
    dataSchemaVersion: Int! # Schema version so frontend can handle multiple versions
    displayName: String!
    version: Int! # Version number
}

type PersistentViewVersionList {
    versions: [PersistentViewVersion]
    pageInfo: PageInfo!
}

input CreateTaskInput {
  title: String!
  notificationText: String!
  introductionText: String!
  schedules: [ScheduleInput!]!
  startTime: String!
  endTime: String
  patients: [ID!]
}

input ScheduleInput {
  scheduleType: ScheduleTypes!
  daysOfWeek: [Int]
  daysOfMonth: [Int]
  months: [Int]
  dateInterval: Int
  times: [String!]!
}

enum ScheduleTypes {
  CALENDAR_CLOCK_SCHEDULE
  INTERVAL_CLOCK_SCHEDULE
}

type Task {
  id: ID!
  title: String!
  notificationText: String!
  introductionText: String!
  defaultTaskSchedule: TaskSchedule
  assignmentList(limit: Int = 100, cursor: Cursor = null): TaskAssignmentList!
  createdAt: Float!
  updatedAt: Float!
  disabled: Boolean!
}

type TaskAssignment {
  id: ID!
  patient: Patient!
  task: Task!
  schedule: TaskSchedule!
  disabled: Boolean
}

type TaskAssignmentError {
  id: ID!
  patient: Patient!
  task: Task!
  schedule: TaskSchedule!
  disabled: Boolean
  error: RuneBulkMutationError!
}

type TaskAssignmentList {
  assignments: [TaskAssignment]!
  pageInfo: PageInfo
  errors: [TaskAssignmentError]!
}

type TaskList {
  tasks: [Task]
  pageInfo: PageInfo
}

type TaskOutput {
  task: Task!
}

type TaskSchedule {
  schedules: [Schedule]
  startTime: String!
  endTime: String
  triggerTimeList(
    fromTime: String!
    toTime: String!
    timezone: String!
  ): TriggerTimeList
  hasDefaultPeriod: Boolean
  hasDefaultFrequency: Boolean
}

type TriggerTimeList {
  triggerTimes: [String!]!
  fromTime: String!
  toTime: String!
}

input UpdateTaskAssignmentInput {
  patientId: ID!
  taskId: ID!
  schedules: [ScheduleInput]
  startTime: String
  endTime: String
  disabled: Boolean
  setScheduleToDefault: Boolean
}

input UpdateTaskInput {
  id: ID!
  title: String
  notificationText: String
  introductionText: String
  disabled: Boolean
  schedules: [ScheduleInput]
  startTime: String
  endTime: String
}

type RuneBulkMutationError {
  code: String!
  failureId: String!
  message: String!
}

interface Schedule {
  scheduleType: String!
}
`

export default typeDefs
