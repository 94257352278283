import { Alert, Button, useAuthenticator, View } from '@aws-amplify/ui-react'
import { Link, Text } from 'ui/baseComponents'
import StrivePDLogoDisplay from 'ui/screens/SignIn/StrivePDLogoDisplay'
import TermsAndPrivacyNotice from 'ui/screens/SignIn/TermsAndPrivacyNotice'
import DuoLogo from 'assets/img/duo-logo.png'
import 'ui/screens/SignIn/AmplifyCSSOverrides.css'
import { colors } from 'theme/colors'

export const AmplifyComponentCustomizations = ({
  duoSsoEnabled,
  loginWithDuo,
  multipleIdentities,
}) => ({
  ConfirmResetPassword: {
    Header() {
      return (
        <>
          <StrivePDLogoDisplay />
          <Text variant="body16">
            You should receive an email containing a verification code. If you
            don't receive one within a few minutes, please contact
            support@runelabs.io
          </Text>
        </>
      )
    },
    Footer() {
      return <TermsAndPrivacyNotice />
    },
  },
  ForceNewPassword: {
    Header() {
      return <StrivePDLogoDisplay sx={{ marginTop: '2rem' }} />
    },
    Footer() {
      return <TermsAndPrivacyNotice user="newUser" />
    },
  },
  ForgotPassword: {
    Header() {
      return (
        <>
          <StrivePDLogoDisplay />
          <Text variant="body16">
            Enter your email address to reset your password
          </Text>
        </>
      )
    },
    Footer() {
      return <TermsAndPrivacyNotice />
    },
  },
  SignIn: {
    Header() {
      return <StrivePDLogoDisplay sx={{ marginTop: '2rem' }} />
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator((context) => [
        context.route,
        context.user,
      ])

      return (
        <View style={{ textAlign: 'center' }}>
          {duoSsoEnabled && (
            <View>
              <Button
                className="duo-sign-in-button"
                onClick={loginWithDuo}
                data-cy="duo-sign-in-button"
              >
                <Text
                  variant="body14"
                  color={colors.GREY[800]}
                  sx={{ fontWeight: 400 }}
                >
                  Login with
                </Text>
                <img src={DuoLogo} alt="Duo Logo" height="50px" />
              </Button>
              {multipleIdentities && (
                <Alert variation="error" marginTop="16px" textAlign="left">
                  Multiple identities returned by Cognito. Please contact
                  support@runelabs.io.
                </Alert>
              )}
            </View>
          )}
          <View style={{ marginTop: '16px' }}>
            <Link
              onClick={toForgotPassword}
              style={{ display: 'block', marginBottom: '10px' }}
            >
              Forgot Password
            </Link>
          </View>
          <View style={{ marginTop: '20px' }}>
            <TermsAndPrivacyNotice />
          </View>
        </View>
      )
    },
  },
})
