import { memo } from 'react'
import IconButton from '@mui/material/IconButton'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import { STYLES } from 'ui/screens/Patients/PatientList/PatientTable/consts'
import NavLink from 'ui/components/NavLink/index'

/**
 * Renders a table cell with a link to the patient settings page.
 *
 * @param {Object} props - The component props.
 * @param {string} props.patientId - The ID of the patient.
 * @returns {JSX.Element} The rendered component.
 */
const TableCellLinkToPatientSettings = ({ patientId }) => (
  <IconButton size="small" sx={STYLES.iconButton}>
    <NavLink
      to={`/patients/${patientId}/manage/details`}
      title="Edit Patient Settings"
      data-cy="patient-row-edit-patient-link"
      sx={STYLES.settingsIcon}
    >
      <ManageAccountsOutlinedIcon />
    </NavLink>
  </IconButton>
)

export default memo(TableCellLinkToPatientSettings)
