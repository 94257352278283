/**
 *
 * @param {Object} object, the object to search
 * @param {*} path, A dot-separated string representing the nested property path, e.g. 'identifiableInfo.demographicData.firstName'
 * @returns {*} the value at the nested property path, or undefined if not found
 */

export const getNestedObjectValue = ({ object, path }) =>
  path.split('.').reduce((acc, part) => acc && acc[part], object)

/**
 *
 * @param {Object} object, the object to update
 * @param {string} path, A dot-separated string representing the nested property path, e.g. 'identifiableInfo.demographicData.firstName'
 * @param {*} value, the new value to set
 * @returns {Object} the updated object
 */
export const updateNestedObjectValue = ({ object, path, value }) => {
  const keys = path?.split('.')
  const lastKey = keys.pop()

  let newObject = { ...object }
  let pointer = newObject

  for (const key of keys) {
    pointer[key] = pointer[key] ? { ...pointer[key] } : {} // Ensure nested structure
    pointer = pointer[key]
  }

  pointer[lastKey] = value
  return newObject
}
