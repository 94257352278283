import { useRef } from 'react'
import * as MUI from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Button } from 'ui/components/Button'
import useBoundStore from 'domains/zustand/store'
import { SEARCH_FIELDS } from 'ui/screens/Patients/PatientList/PatientTable/consts'
import { Stack } from 'ui/baseComponents'

/**
 * A search input component for searching patients by the specified search fields.
 * @param {Object} props - The props of the component
 * @param {string} props.defaultFocus - The field that should be focused by default.
 * @param {Array} props.searchFields - The fields the user should be able to search by.
 * @param {boolean} props.small - Whether the search inputs should be small.
 * @returns {JSX.Element} The rendered component.
 */
const PatientSearch = ({ defaultFocus, searchFields, small }) => {
  const resetSearchPatients = useBoundStore(
    (state) => state.resetSearchPatients,
  )
  const searchPatientsInputFields = useBoundStore(
    (state) => state.searchPatientsInputFields,
  )
  const setSearchPatientsInputFields = useBoundStore(
    (state) => state.setSearchPatientsInputFields,
  )

  const { codeName, email, firstName, lastName } = SEARCH_FIELDS

  const searchFieldsConfig = {
    [codeName]: {
      dataCy: 'search-patients-code-name',
      placeholder: 'Code name',
      ref: useRef(null),
    },
    [email]: {
      dataCy: 'search-patients-email',
      placeholder: 'Email',
      ref: useRef(null),
    },
    [firstName]: {
      dataCy: 'search-patients-first-name',
      placeholder: 'First name',
      ref: useRef(null),
    },
    [lastName]: {
      dataCy: 'search-patients-last-name',
      placeholder: 'Last name',
      ref: useRef(null),
    },
  }

  const getInputProps = (inputField, ref) => ({
    endAdornment: (
      <MUI.InputAdornment position="end">
        <MUI.IconButton
          onClick={() => {
            setSearchPatientsInputFields('', inputField)
            ref.current.focus()
          }}
          data-cy={`clear-search-patients-${inputField}`}
        >
          <ClearIcon />
        </MUI.IconButton>
      </MUI.InputAdornment>
    ),
    startAdornment: (
      <MUI.InputAdornment position="start">
        <SearchIcon />
      </MUI.InputAdornment>
    ),
  })

  return (
    <>
      {searchFields.map((field) => (
        <MUI.TextField
          key={field}
          inputRef={searchFieldsConfig[field].ref}
          value={searchPatientsInputFields[field]}
          placeholder={searchFieldsConfig[field].placeholder}
          InputProps={getInputProps(field, searchFieldsConfig[field].ref)}
          variant="outlined"
          onChange={(event) =>
            setSearchPatientsInputFields(event.target.value, field)
          }
          data-cy={searchFieldsConfig[field].dataCy}
          size={small ? 'small' : 'medium'}
        />
      ))}
      <Stack direction="row">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            resetSearchPatients()
            searchFieldsConfig[defaultFocus].ref.current.focus()
          }}
          size={small ? 'small' : 'medium'}
          sx={{ height: '100%' }}
        >
          Reset
        </Button>
      </Stack>
    </>
  )
}
export default PatientSearch
