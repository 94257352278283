import { colors } from 'theme/colors'
import { STRIVEPD_FONT_FAMILY } from 'theme/strivePDTheme'

// Common styles for button states
const buttonActiveFocusHoverStyles = {
  backgroundColor: { value: colors.PRIMARY[1200] },
  borderColor: { value: colors.PRIMARY[1200] },
  boxShadow: 'none',
}

// Common styles for link states
const linkActiveFocusHoverStyles = {
  color: { value: colors.PRIMARY[1000] },
  backgroundColor: { value: 'transparent' },
  borderColor: { value: 'transparent' },
  boxShadow: 'none',
}

export const amplifyThemeOverrides = {
  name: 'amplify-theme-overrides',
  tokens: {
    components: {
      button: {
        primary: {
          backgroundColor: { value: colors.PRIMARY[1000] },
          color: { value: colors.RUNE_WHITE },
          _active: buttonActiveFocusHoverStyles,
          _hover: buttonActiveFocusHoverStyles,
          _focus: buttonActiveFocusHoverStyles,
        },
        link: {
          backgroundColor: { value: colors.RUNE_WHITE },
          color: { value: colors.PRIMARY[1000] },
          _active: linkActiveFocusHoverStyles,
          _focus: linkActiveFocusHoverStyles,
          _hover: linkActiveFocusHoverStyles,
        },
        small: {
          fontSize: { value: '16px' },
        },
      },
      fieldcontrol: {
        _focus: {
          boxShadow: { value: 'none' },
        },
      },
      input: {
        color: { value: colors.GREY[900] },
        borderColor: { value: colors.GREY[300] },
        _focus: {
          borderColor: { value: colors.PRIMARY[1000] },
        },
      },
    },
    fonts: {
      default: {
        variable: {
          value: STRIVEPD_FONT_FAMILY,
        },
      },
    },
  },
}
