import { validate } from 'email-validator'

export const FORM_FIELD_WIDTH = {
  full: {
    name: 'full',
    numericValue: 1,
    percentage: '100%',
  },
  half: {
    name: 'half',
    numericValue: 0.5,
    percentage: '50%',
  },
}

export const FORM_FIELDS_METADATA = {
  clinicalVisitDate: {
    key: 'clinicalVisitDate',
    label: 'Clinical Visit',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.clinicalVisit',
    type: 'date',
    isOnlyFieldInRow: true,
  },
  dateOfBirth: {
    key: 'dateOfBirth',
    label: 'Date of Birth',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.dateOfBirth',
    type: 'date',
  },
  ethnicity: {
    key: 'ethnicity',
    label: 'Ethnicity',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.ethnicity',
    type: 'select',
  },
  facilityName: {
    key: 'facilityName',
    label: 'Facility Name',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.facilityName',
    type: 'text',
  },
  gender: {
    key: 'gender',
    label: 'Gender',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.gender',
    type: 'select',
  },
  geneticMarkers: {
    key: 'geneticMarkers',
    label: 'Genetic Markers',
    layout: FORM_FIELD_WIDTH.full.name,
    name: 'identifiableInfo.demographicData.geneticMarkers',
    type: 'multiSelect',
  },
  height: {
    key: 'height',
    label: 'Height',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.height',
    type: 'text',
  },
  initialProvider: {
    name: 'initialProvider',
  },
  otherConditions: {
    key: 'diagnoses',
    label: 'Other Conditions',
    layout: FORM_FIELD_WIDTH.full.name,
    name: 'identifiableInfo.demographicData.otherConditions',
    type: 'multiSelect',
  },
  patientCodeName: {
    key: 'patientCodeName',
    label: 'Code Name',
    layout: FORM_FIELD_WIDTH.full.name,
    name: 'codeName',
    type: 'text',
  },
  patientEmail: {
    key: 'patientEmail',
    label: 'Contact Email',
    layout: FORM_FIELD_WIDTH.full.name,
    name: 'identifiableInfo.email',
    type: 'email',
    validate: (email) =>
      validate(email) || email === '' || email === 'undefined'
        ? null
        : 'Invalid email',
  },
  patientFirstName: {
    key: 'firstName',
    label: 'First Name',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.firstName',
    type: 'text',
  },
  patientLastName: {
    key: 'lastName',
    label: 'Last Name',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.lastName',
    type: 'text',
  },
  pdDiagnosis: {
    key: 'pdDiagnosis',
    label: 'PD Diagnosis',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.pdDiagnosis',
    type: 'select',
  },
  pdDiagnosisDate: {
    key: 'pdDiagnosisDate',
    label: 'PD Diagnosis Date',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.pdDiagnosisDate',
    type: 'date',
  },
  providerAddressLine1: {
    name: 'identifiableInfo.demographicData.providerAddress.addressLine1',
  },
  providerAddressLine2: {
    name: 'identifiableInfo.demographicData.providerAddress.addressLine2',
  },
  providerCity: {
    name: 'identifiableInfo.demographicData.providerAddress.city',
  },
  providerNpi: {
    name: 'identifiableInfo.demographicData.providerNpi',
  },
  providerState: {
    name: 'identifiableInfo.demographicData.providerAddress.state',
  },
  providerZipCode: {
    name: 'identifiableInfo.demographicData.providerAddress.zipCode',
  },
  providerFirstName: {
    key: 'providerFirstName',
    label: 'Provider First Name',
    layout: FORM_FIELD_WIDTH.half.name,
    optionValueIdentifier: 'first_name',
    name: 'identifiableInfo.demographicData.providerInfo.firstName',
    type: 'autoComplete',
  },
  providerLastName: {
    key: 'providerLastName',
    label: 'Provider Last Name',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.providerInfo.lastName',
    optionValueIdentifier: 'last_name',
    type: 'autoComplete',
  },
  race: {
    key: 'race',
    label: 'Race',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.race',
    type: 'select',
  },
  stateForSearch: {
    key: 'stateForSearch',
    label: 'State',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.state',
    type: 'select',
  },
  weight: {
    key: 'weight',
    label: 'Weight',
    layout: FORM_FIELD_WIDTH.half.name,
    name: 'identifiableInfo.demographicData.weight',
    type: 'text',
  },
}

export const FORM_FIELDS = {
  clinicalInfoFields: [FORM_FIELDS_METADATA.clinicalVisitDate],
  codeName: [FORM_FIELDS_METADATA.patientCodeName],
  demographicInfo: [
    FORM_FIELDS_METADATA.dateOfBirth,
    FORM_FIELDS_METADATA.gender,
    FORM_FIELDS_METADATA.height,
    FORM_FIELDS_METADATA.weight,
    FORM_FIELDS_METADATA.race,
    FORM_FIELDS_METADATA.ethnicity,
  ],
  facilityInfo: [FORM_FIELDS_METADATA.facilityName],
  healthInfo: [
    FORM_FIELDS_METADATA.pdDiagnosis,
    FORM_FIELDS_METADATA.pdDiagnosisDate,
    FORM_FIELDS_METADATA.geneticMarkers,
    FORM_FIELDS_METADATA.otherConditions,
  ],
  personalInfo: [
    FORM_FIELDS_METADATA.patientFirstName,
    FORM_FIELDS_METADATA.patientLastName,
    FORM_FIELDS_METADATA.patientEmail,
  ],
  providerName: [
    FORM_FIELDS_METADATA.providerFirstName,
    FORM_FIELDS_METADATA.providerLastName,
  ],
}

export const PROVIDER_SEARCH_FIELDS = {
  providerFirstName: FORM_FIELDS_METADATA.providerFirstName.name,
  providerLastName: FORM_FIELDS_METADATA.providerLastName.name,
}

export const TYPOGRAPHY = {
  instructions: 'body14',
  readOnlyInfoHeading: 'body16B',
  readOnlyInfoLine: 'body16',
  sectionHeading: 'head22',
  sectionSubHeading: 'head18',
}

// This is the mapping used by StrivePD
export const US_STATES_AND_ABBREVIATIONS = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  Palau: 'PW',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  'Virgin Islands': 'VI',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
}
