import { useContext, useMemo } from 'react'
import {
  TableRow,
  TableCell,
  Box,
  TextField,
  InputAdornment,
  LinearProgress,
} from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  PATIENT_TABLE_NO_RESULTS,
  STYLES,
  TABLE_HEADERS,
  PATIENT_TABLE_ROWS_PER_PAGE,
  RED_FLAG_CLASSIFICATIONS,
  SEARCH_FIELDS,
  noPaddingCellStyles,
  hiddenColumnStyles,
  FILTER_PLACEHOLDER_TEXT,
  PATIENT_TABLE_URGENCY,
} from 'ui/screens/Patients/PatientList/PatientTable/consts'
import {
  TableCellLinkToPatientSettings,
  TableCellLinkToPatientReport,
  PatientSearch,
  PatientTableError,
} from 'ui/screens/Patients/PatientList/PatientTable'
import Loading from 'ui/components/Loading'
import { DataTable } from 'ui/components/DataTable'
import { Link, Stack, Table, Text } from 'ui/baseComponents'
import { useFlags } from 'domains/launchdarkly/hooks'
import { usePatientList, usePatientSearch } from 'ui/hooks'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import useBoundStore from 'domains/zustand/store'
import { UserPHIContext } from 'ui/contexts'
import {
  findRedFlagCount,
  formatExpandedRowContent,
  getPatientListStartEndTimes,
} from 'ui/screens/Patients/PatientList/PatientTable/helpers'
import { DATE_FORMAT, DATE_FORMAT_HUMAN_SHORT } from 'ui/consts'
import { formatDateString } from 'utilities/time'
import moment from 'moment'

/**
 * The patient table component for users with access to PHI.
 * @returns {JSX.Element} The rendered component.
 */
const PatientTableWithPHIAccess = ({ orgPatientCount }) => {
  const phiVisibility = useContext(UserPHIContext)
  const { monthlyReportsTabVisibile: monthlyReportsTabVisible } = useFlags()

  const loadingPatientList = useBoundStore((state) => state.loadingPatientList)
  const patientListResults = useBoundStore((state) => state.patients)
  const showAllPatients = useBoundStore((state) => state.showAllPatients)
  const errorPatientList = useBoundStore((state) => state.errorPatientList)

  const searchPatientsLoading = useBoundStore(
    (state) => state.searchPatientsLoading,
  )
  const searchPatientsError = useBoundStore(
    (state) => state.searchPatientsError,
  )
  const searchPatientsResults = useBoundStore(
    (state) => state.searchPatientsResults,
  )

  const error = showAllPatients ? errorPatientList : searchPatientsError
  const loading = showAllPatients ? loadingPatientList : searchPatientsLoading
  const patients = showAllPatients ? patientListResults : searchPatientsResults

  const { startDate, endDate, startTime, endTime } =
    getPatientListStartEndTimes()

  usePatientList({
    fetchAll: true,
    skip: !showAllPatients,
    startTime,
    endTime,
  })

  usePatientSearch({ skip: showAllPatients, startTime, endTime })

  const formattedPatientData = useMemo(
    () =>
      patients.map((patient) => {
        const { id, preComputedName, identifiableInfo, redFlags, codeName } =
          patient
        const { totalSummary, alertClinician, needsEducation } = redFlags || {}
        const urgency = alertClinician
          ? PATIENT_TABLE_URGENCY.alert
          : needsEducation
          ? PATIENT_TABLE_URGENCY.educate
          : ''
        const { dateOfBirth, clinicalVisit, nextVisitMonthYear } =
          identifiableInfo?.demographicData || {}
        const dob = dateOfBirth
          ? formatDateString({
              dateString: dateOfBirth,
              format: DATE_FORMAT_HUMAN_SHORT,
            })
          : null
        const age = dateOfBirth
          ? moment().diff(moment(dateOfBirth), 'years')
          : null
        const nextVisit =
          clinicalVisit || nextVisitMonthYear
            ? moment(clinicalVisit || nextVisitMonthYear).format(DATE_FORMAT)
            : null

        return {
          metaData: {
            dob: `${dob ? `${dob} (Age ${age})` : ''}`,
            id,
            codeName,
            urgency,
            age,
          },
          [TABLE_HEADERS.urgency]: urgency,
          [TABLE_HEADERS.patient]: { id, preComputedName },
          [TABLE_HEADERS.email]: identifiableInfo?.email,
          [TABLE_HEADERS.nextVisit]: nextVisit,
          [TABLE_HEADERS.falling]: findRedFlagCount(
            totalSummary,
            RED_FLAG_CLASSIFICATIONS.falling,
          ),
          [TABLE_HEADERS.lightheadedness]: findRedFlagCount(
            totalSummary,
            RED_FLAG_CLASSIFICATIONS.lightheadedness,
          ),
          [TABLE_HEADERS.offPeriod]: findRedFlagCount(
            totalSummary,
            RED_FLAG_CLASSIFICATIONS.offPeriod,
          ),
          [TABLE_HEADERS.hallucination]: findRedFlagCount(
            totalSummary,
            RED_FLAG_CLASSIFICATIONS.hallucinations,
          ),
          [TABLE_HEADERS.swallowingIssues]: findRedFlagCount(
            totalSummary,
            RED_FLAG_CLASSIFICATIONS.swallowingIssues,
          ),
          [TABLE_HEADERS.settings]: id,
          [TABLE_HEADERS.report]: id,
          // Nested values can't be searched, so we are adding hidden fields for searchability
          hiddenName: preComputedName,
          hiddenCodeName: codeName,
          hiddenDob: dob,
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patients],
  )

  const columns = [
    {
      name: 'metaData',
      options: {
        display: false,
      },
    },
    {
      name: TABLE_HEADERS.urgency,
      options: {
        sort: true,
        ...noPaddingCellStyles,
        setCellProps: () => ({
          'data-cy': 'patient-row-patient-urgency',
        }),
        customBodyRender: (value) => {
          if (value === PATIENT_TABLE_URGENCY.alert) {
            return (
              <ErrorOutlineIcon
                color="error"
                fontSize="small"
                sx={{ verticalAlign: 'middle' }}
              />
            )
          } else if (value === PATIENT_TABLE_URGENCY.educate) {
            return (
              <WarningAmberIcon
                color="warning"
                fontSize="small"
                sx={{ verticalAlign: 'middle' }}
              />
            )
          } else {
            return null
          }
        },
        sortCompare:
          (order) =>
          ({ data: val1 }, { data: val2 }) => {
            const priority = (val) => {
              if (val === PATIENT_TABLE_URGENCY.alert) return 1
              if (val === PATIENT_TABLE_URGENCY.educate) return 2
              if (!val) return 4
              return 3
            }

            const priorityDiff = priority(val1) - priority(val2)
            if (priorityDiff !== 0)
              return priorityDiff * (order === 'asc' ? 1 : -1)

            return (
              (val1 || '').localeCompare(val2 || '') *
              (order === 'asc' ? 1 : -1)
            )
          },
      },
    },
    {
      name: TABLE_HEADERS.patient,
      options: {
        sort: true,
        ...noPaddingCellStyles,
        customBodyRender: (value) => (
          <Box sx={STYLES.patientNameColumn}>
            <Link
              href={`/patients/${value.id}/${ROUTE_METADATA.weeklyView.path}?startTime=${startDate}&endTime=${endDate}`}
              data-cy="patient-row-name-link"
              light
            >
              {value.preComputedName}
            </Link>
          </Box>
        ),
        sortCompare:
          (order) =>
          ({ data: name1 }, { data: name2 }) =>
            name1?.preComputedName.localeCompare(name2?.preComputedName) *
            (order === 'asc' ? 1 : -1),
      },
    },
    {
      name: TABLE_HEADERS.email,
      options: {
        sort: true,
        setCellHeaderProps: () => ({
          style: { paddingRight: '1rem' },
        }),
        setCellProps: () => ({
          'data-cy': 'patient-row-patient-email',
        }),
      },
    },
    {
      name: TABLE_HEADERS.nextVisit,
      options: {
        sort: true,
        setCellHeaderProps: () => ({
          style: { paddingRight: '1rem' },
        }),
        setCellProps: () => ({
          'data-cy': 'patient-row-patient-next-visit',
        }),
      },
    },
    {
      name: TABLE_HEADERS.falling,
      options: {
        sort: true,
        searchable: false,
        setCellHeaderProps: () => ({
          style: { paddingRight: 0, paddingLeft: 0 },
        }),
        setCellProps: () => ({
          className: 'darkened-background',
          'data-cy': 'patient-row-red-flag-falling',
        }),
        customHeadLabelRender: ({ _, label }) => (
          <span style={STYLES.redFlagColumn}>{label}</span>
        ),
      },
    },
    {
      name: TABLE_HEADERS.lightheadedness,
      options: {
        sort: true,
        searchable: false,
        setCellHeaderProps: () => ({
          style: { paddingRight: 0, paddingLeft: 0 },
        }),
        setCellProps: () => ({
          'data-cy': 'patient-row-red-flag-lightheadedness',
        }),
        customHeadLabelRender: ({ _, label }) => (
          <span style={STYLES.redFlagColumn}>{label}</span>
        ),
      },
    },
    {
      name: TABLE_HEADERS.offPeriod,
      options: {
        sort: true,
        searchable: false,
        setCellHeaderProps: () => ({
          style: { paddingRight: 0, paddingLeft: 0 },
        }),
        setCellProps: () => ({
          className: 'darkened-background',
          'data-cy': 'patient-row-red-flag-off-period',
        }),
        customHeadLabelRender: ({ _, label }) => (
          <span style={STYLES.redFlagColumn}>{label}</span>
        ),
      },
    },
    {
      name: TABLE_HEADERS.hallucination,
      options: {
        sort: true,
        searchable: false,
        setCellHeaderProps: () => ({
          style: { paddingRight: 0, paddingLeft: 0 },
        }),
        setCellProps: () => ({
          'data-cy': 'patient-row-red-flag-hallucination',
        }),
        customHeadLabelRender: ({ _, label }) => (
          <span style={STYLES.redFlagColumn}>{label}</span>
        ),
      },
    },
    {
      name: TABLE_HEADERS.swallowingIssues,
      options: {
        sort: true,
        searchable: false,
        setCellHeaderProps: () => ({
          style: { paddingRight: 0, paddingLeft: 0 },
        }),
        setCellProps: () => ({
          className: 'darkened-background',
          'data-cy': 'patient-row-red-flag-swallowing-issues',
        }),
        customHeadLabelRender: ({ _, label }) => (
          <span style={STYLES.redFlagColumn}>{label}</span>
        ),
      },
    },
    {
      name: TABLE_HEADERS.settings,
      label: ' ',
      options: {
        sort: false,
        setCellProps: () => ({
          'data-cy': 'patient-row-settings',
          style: {
            paddingRight: 0,
          },
        }),
        customBodyRender: (value) => (
          <TableCellLinkToPatientSettings patientId={value} />
        ),
      },
    },
    {
      name: 'hiddenName',
      options: {
        ...hiddenColumnStyles,
      },
    },
    {
      name: 'hiddenCodeName',
      options: {
        ...hiddenColumnStyles,
      },
    },
    {
      name: 'hiddenDob',
      options: {
        ...hiddenColumnStyles,
      },
    },
  ]

  if (monthlyReportsTabVisible) {
    columns.push({
      name: TABLE_HEADERS.report,
      label: ' ',
      options: {
        sort: false,
        setCellProps: () => ({
          style: {
            paddingLeft: 0,
          },
        }),
        customBodyRender: (value) => (
          <TableCellLinkToPatientReport patientId={value} />
        ),
      },
    })
  }

  const { codeName, email, firstName, lastName } = SEARCH_FIELDS
  const searchFields = phiVisibility ? [firstName, lastName, email] : [codeName]

  const searchFieldsDefaultFocus = phiVisibility ? firstName : codeName

  const options = {
    customSearchRender: (searchText, handleSearch) => {
      const handleTextChange = (e) => {
        handleSearch(e.target.value)
      }
      return (
        <Stack direction="row" spacing={2} justifyContent="end">
          {showAllPatients ? (
            <TextField
              placeholder={FILTER_PLACEHOLDER_TEXT}
              size="small"
              value={searchText || ''}
              onChange={handleTextChange}
              variant="outlined"
              sx={{ width: '550px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListIcon />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <PatientSearch
              {...{
                defaultFocus: searchFieldsDefaultFocus,
                searchFields,
                small: true,
              }}
            />
          )}
        </Stack>
      )
    },
    enableNestedDataAccess: '.',
    expandableRows: true,
    expandableRowsOnClick: true,
    filter: false,
    renderExpandableRow: (rowData = [], rowMeta) => {
      const colSpan = rowData.length + 1
      const { codeName, id, dob } = rowData[0] || {}
      const rows1 = [
        formatExpandedRowContent({ label: 'DoB', value: dob }),
        formatExpandedRowContent({ label: 'ID', value: id, copyable: true }),
        formatExpandedRowContent({ label: 'Code', value: codeName }),
      ]
      // TODO: Uncomment when the backend is ready
      // const rows2 = [
      //   formatExpandedRowContent({ label: 'Clinical Site', value: clinicalSite),
      //   formatExpandedRowContent({ label: 'Provider', value: provider),
      //   formatExpandedRowContent({ label: 'Provider email', value: providerEmail, copyable: true),
      // ]

      return (
        <TableRow
          style={STYLES.expandedRow}
          data-cy="patient-row-accordion-panel"
        >
          <TableCell style={{ padding: 0 }} />
          <TableCell colSpan={3} style={{ padding: '0.5rem 0' }}>
            <Table rows={rows1} size="small" />
          </TableCell>
          {/* TODO: Uncomment when the backend is ready
          <TableCell colSpan={5} style={{ padding: '0.5rem 0' }}>
            <Table rows={rows2} size="small" />
          </TableCell> */}
          <TableCell colSpan={colSpan - 3} style={{ padding: 0 }} />
        </TableRow>
      )
    },
    rowsPerPage: PATIENT_TABLE_ROWS_PER_PAGE.default,
    rowsPerPageOptions: [
      PATIENT_TABLE_ROWS_PER_PAGE.default,
      PATIENT_TABLE_ROWS_PER_PAGE.min,
      PATIENT_TABLE_ROWS_PER_PAGE.max,
    ],
    setRowProps: (row) => {
      const [{ id, urgency = '' }] = row
      return {
        className: `urgency-${urgency || 'default'}`,
        'data-cy': `patient-row-${id}`,
      }
    },
    search: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    textLabels: {
      body: {
        noMatch: PATIENT_TABLE_NO_RESULTS,
        columnHeaderTooltip: ({ label }) => `Sort by ${label}`,
      },
    },
    viewColumns: false,
  }
  // get percentage of progress by comparing patients.length to orgPatientCount
  const progress = (patients.length / orgPatientCount) * 100

  return (
    <Box
      data-cy="patient-table-with-phi-access"
      data-testid="patient-table-with-phi-access"
      sx={loading ? STYLES.loading : STYLES.loaded}
    >
      {error ? (
        <PatientTableError error={errorPatientList} />
      ) : (
        <>
          <DataTable
            columns={columns}
            data={formattedPatientData}
            options={options}
          />
          {loading &&
            (showAllPatients ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                height="100px"
                data-cy="loading-progress-bar"
              >
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: 500,
                    height: 10,
                    borderRadius: 10,
                    margin: '1rem',
                  }}
                />
                <Text variant="head18">
                  {Math.floor(progress)}% ({patients.length}/{orgPatientCount})
                </Text>
              </Stack>
            ) : (
              <Stack justifyContent="center" height="50vh">
                <Loading />
              </Stack>
            ))}
        </>
      )}
    </Box>
  )
}
export default PatientTableWithPHIAccess
