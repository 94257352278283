export const MOBILITY_METRIC_TYPES = {
  walkingSpeed: 'Walking Speed',
  doubleSupport: 'Double Support',
  stepLength: 'Step Length',
}

export const WEEKLY_VIEW_TABLE_HEADINGS = {
  date: 'Date',
  falling: 'Falling',
  hallucinations: 'Hallucinations',
  lightheadedness: 'Lightheadedness',
  offPeriod: 'Off Period',
  swallowingIssues: 'Swallowing Issues',
  notes: 'Notes',
  medication: 'Medication',
  stepLength: MOBILITY_METRIC_TYPES.stepLength,
  walkingSpeed: MOBILITY_METRIC_TYPES.walkingSpeed,
  doubleSupport: MOBILITY_METRIC_TYPES.doubleSupport,
}

export const STYLES = {
  darkenedHeaders: {
    backgroundColor: '#F9FBFF',
  },
  redFlagHeaders: {
    maxWidth: 60,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '16px 8px !important',
  },
}
