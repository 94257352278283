import { useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemLink from 'ui/components/ListItemLink'
import { PatientContext } from 'ui/contexts/PatientContext'
import styled from 'styled-components'
import { Z_INDEX_DRAWER } from 'theme/zIndexRegistry'
import { HEADER_HEIGHT } from 'ui/components/MainNav'

const drawerWidth = 240
const useStyles = makeStyles(() => ({
  active: {
    fontWeight: 'bold',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: Z_INDEX_DRAWER,
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  drawerPaper: {
    top: 'auto',
    width: drawerWidth,
    position: 'static',
  },
}))

const MenuList = styled(List)`
  a.active {
    .menu-item {
      font-weight: bold !important;
    }
  }
`

const EditPatientSidebar = () => {
  const classes = useStyles()
  const patient = useContext(PatientContext)

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <MenuList>
        <ListItemLink
          button
          to={`/patients/${patient.id}/manage/details`}
          activeClassName="active"
          data-cy="edit-patient-sidebar-link-details"
        >
          <ListItemText
            classes={{ primary: 'menu-item' }}
            primary="Patient Details"
          />
        </ListItemLink>
        <ListItemLink
          button
          to={`/patients/${patient.id}/manage/clients`}
          activeClassName="active"
          data-cy="edit-patient-sidebar-link-clients"
        >
          <ListItemText classes={{ primary: 'menu-item' }} primary="Clients" />
        </ListItemLink>
        <ListItemLink
          button
          to={`/patients/${patient.id}/manage/devices`}
          activeClassName="active"
          data-cy="edit-patient-sidebar-link-devices"
        >
          <ListItemText classes={{ primary: 'menu-item' }} primary="Devices" />
        </ListItemLink>
      </MenuList>
    </Drawer>
  )
}

export default EditPatientSidebar
