import { Link, Stack } from 'ui/baseComponents'
import { TERMS_AND_PRIVACY_NOTICE_AGREEMENT } from 'ui/screens/SignIn/consts'
import { PrivacyNoticeLink } from 'ui/components/PrivacyNoticeLink'
import { colors } from 'theme/colors'
import { STRIVEPD_FONT_FAMILY } from 'theme/strivePDTheme'

const STYLES = {
  termsContainer: {
    color: colors.GREY[600],
    fontFamily: STRIVEPD_FONT_FAMILY,
  },
}

const TermsAndPrivacyNotice = ({ user = 'existingUser' }) => (
  <Stack
    data-cy="terms-container"
    alignItems="center"
    sx={STYLES.termsContainer}
  >
    {TERMS_AND_PRIVACY_NOTICE_AGREEMENT[user]}
    <Stack direction="row">
      <Link
        href="https://www.runelabs.io/terms-of-use"
        external
        underline
        color="inherit"
      >
        Terms of Use
      </Link>
      &nbsp;and
      <PrivacyNoticeLink color="inherit" underline="hover" />
    </Stack>
  </Stack>
)

export default TermsAndPrivacyNotice
