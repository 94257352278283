import { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'
import MUIFormControl from '@material-ui/core/FormControl'
import { Box, InputLabel, makeStyles, TextField } from '@material-ui/core'
import { PatientContext } from 'ui/contexts'
import SubmitButton from 'ui/components/SubmitButton/SubmitButton'
import { useMutation } from '@apollo/client'
import { Warning } from 'ui/components/Notifications'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { UPDATE_PATIENT } from '../mutations'
import { useRefetchPatients } from 'ui/screens/Patients/queries'
import CopyTextIcon from 'ui/components/CopyText/CopyTextIcon'
import { SECOND_IN_MILLISECONDS } from 'utilities/time'
import { getPatientListStartEndTimes } from 'ui/screens/Patients/PatientList/PatientTable/helpers'

const Form = styled('form')`
  padding: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
`

const FormControl = styled(MUIFormControl)`
  max-width: 300px;
  margin: ${({ theme }) => theme.spacing(2)}px !important;
`
const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: 120,
  },
  warning: {
    margin: theme.spacing(2),
    maxWidth: 400,
  },
}))

const PatientDetailsWithoutPHI = () => {
  const classes = useStyles()
  const patient = useContext(PatientContext)

  const { id, codeName: codeNameFromPatientContext } = patient
  const successLabel = id ? 'Saved' : 'Created'
  const submitLabel = id ? 'Save' : 'Create'
  const [codeName, setCodeName] = useState(codeNameFromPatientContext)
  const [error, setError] = useState()
  const { startTime, endTime } = getPatientListStartEndTimes()
  const [updatePatient, { loading }] = useMutation(UPDATE_PATIENT, {
    onError: (error) => {
      console.error(error)
      setError('Unable to update patient')
    },
    refetchQueries: [useRefetchPatients({ startTime, endTime })],
  })

  const [success, setSuccess] = useState(false)
  const [timeoutId, setTimeoutId] = useState(false)

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      clearTimeout(timeoutId)
      try {
        const input = {
          patientId: id,
          codeName,
        }
        await updatePatient({
          variables: {
            input,
          },
        })
        const TIMEOUT_IN_SECONDS = 2
        setSuccess(true)
        setTimeoutId(
          setTimeout(
            () => setSuccess(false),
            TIMEOUT_IN_SECONDS * SECOND_IN_MILLISECONDS,
          ),
        )
      } catch (error) {
        console.error(error)
        const HTTP_REPSONSE_CODE_CONFLICT = 409
        const errorMessage = error.message.includes(HTTP_REPSONSE_CODE_CONFLICT)
          ? `A patient with the codeName ${codeName} already exists.`
          : 'Unable to update Patient'
        setError(errorMessage)
      }
    },
    [codeName, id, timeoutId, updatePatient],
  )
  return (
    <ErrorBoundary>
      <Form onSubmit={onSubmit} data-cy="PatientDetails">
        <FormControl>
          <TextField
            id="codeName"
            data-cy="codeName"
            error={!!error}
            helperText={error}
            disabled={loading}
            label="Code Name"
            value={codeName}
            onChange={(e) => setCodeName(e.target.value)}
          />
        </FormControl>
        <Warning
          className={classes.warning}
          action={null}
          message="DO NOT use patient’s real name or other identifiable information"
        />
        <Box mt={2} mb={1} ml={2}>
          <InputLabel shrink={true}>Patient ID</InputLabel>
          <CopyTextIcon value={id} />
        </Box>
        <FormControl>
          <SubmitButton
            classes={classes}
            icon={null}
            success={success}
            loading={loading}
            successLabel={successLabel}
            submitLabel={submitLabel}
          />
        </FormControl>
      </Form>
    </ErrorBoundary>
  )
}

export default PatientDetailsWithoutPHI
