import { memo, useEffect } from 'react'
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from 'recharts'
import { Box } from '@mui/material'

import { colors } from 'theme/colors'
import {
  ACTIVITY_CATEGORIES_ENUMS,
  ACTIVITY_CATEGORY_ICONS_MAP,
  ACTIVITY_COLORS,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/consts'
import { getActivityCategoryChartData } from 'ui/clinicianScreens/Patient/Report/sections/Activity/helpers'
import { DEBOUNCE_DELAY_DEFAULT } from 'ui/baseComponents/Charts/consts'
import { DATA_ATTRIBUTES } from 'ui/clinicianScreens/Patient/Report/consts'

const ReportChartActivityCategories = ({
  activityDurationsByCategory = [],
  updateAttributesForPlaywrightScript,
}) => {
  const STYLES = {
    chartContainer: {
      width: '100%',
      '& .recharts-legend-item-text': {
        color: `${colors.GREY[700]} !important`, // overriding js
        verticalAlign: 'middle',
      },
      '& .recharts-legend-wrapper': {
        '&::before': {
          content: `"Time in category"`,
          color: ACTIVITY_COLORS.time,
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
    },
  }

  const data = getActivityCategoryChartData(activityDurationsByCategory) || []

  const barColors = {
    recent: colors.PRIMARY[1000],
    previous: colors.PRIMARY[400],
  }

  const renderCustomAxisTick = ({ x, y, payload }) => {
    const activityEnum = ACTIVITY_CATEGORIES_ENUMS[payload.value]

    return (
      <g>
        <image
          href={ACTIVITY_CATEGORY_ICONS_MAP.get(activityEnum)}
          width="20"
          height="20"
          x={x + 8}
          y={y}
        />
        <text x={x + 32} y={y + 14} fontSize={12}>
          {payload.value}
        </text>
      </g>
    )
  }

  const hasActivityDuration = data?.length > 0

  const { activity: activityDataAttributes } = DATA_ATTRIBUTES

  const { categoryChartEmptyAttribute, categoryChartAttribute } =
    activityDataAttributes

  useEffect(() => {
    if (!window.Cypress) {
      if (hasActivityDuration) {
        updateAttributesForPlaywrightScript([
          categoryChartAttribute.playwrightScriptSelector,
        ])
      } else {
        updateAttributesForPlaywrightScript([
          categoryChartEmptyAttribute.playwrightScriptSelector,
        ])
      }
    }
  }, [hasActivityDuration]) // eslint-disable-line react-hooks/exhaustive-deps

  return hasActivityDuration ? (
    <Box
      sx={STYLES.chartContainer}
      data-chart-id="activity-category-chart"
      data-cy="activity-category-chart"
    >
      <ResponsiveContainer
        debounce={DEBOUNCE_DELAY_DEFAULT}
        width="100%"
        height={200}
      >
        <RechartsBarChart
          {...{
            data,
            width: 600,
            height: 200,
            margin: {
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            },
          }}
        >
          <CartesianGrid strokeDasharray="0" stroke={colors.TREMOR[100]} />
          <XAxis
            dataKey="name"
            type="category"
            scale="band"
            tick={renderCustomAxisTick}
            tickLine={{ stroke: colors.TREMOR[100] }}
            axisLine={{ stroke: colors.TREMOR[100] }}
          />
          <YAxis
            unit="m"
            type="number"
            tickLine={{ stroke: colors.TREMOR[100] }}
            axisLine={{ stroke: colors.TREMOR[100] }}
            fontSize={12}
          />
          <Legend
            verticalAlign="top"
            align="left"
            iconType="square"
            iconSize={10}
            wrapperStyle={{
              top: -8,
              left: 60,
              fontSize: 12,
              textTransform: 'capitalize',
            }}
            payload={Object.keys(barColors).map((key) => ({
              value: key,
              type: 'square',
              color: barColors[key],
            }))}
          />
          <Bar
            {...{
              dataKey: 'recent',
              fill: barColors.recent,
              radius: [5, 5, 0, 0],
              isAnimationActive: false,
            }}
          />
          <Bar
            {...{
              dataKey: 'previous',
              fill: barColors.previous,
              radius: [5, 5, 0, 0],
              isAnimationActive: false,
            }}
          />
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>
  ) : (
    // height: 1 is needed in order to be visible to Playwright
    <div data-category-chart-empty style={{ margin: 0, height: 1 }} />
  )
}

export default memo(ReportChartActivityCategories)
