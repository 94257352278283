import { gql } from '@apollo/client'

export const GET_PATIENT_EDITABLE_FIELDS_DROPDOWN_OPTIONS = gql`
  query getPatientEditableFieldsDropdownOptions {
    diagnoses {
      diagnoses {
        id
        name
      }
    }
    gender: __type(name: "Gender") {
      enumValues {
        name
      }
    }
    geneticMarkers {
      geneticMarkers {
        id
        name
      }
    }
    ethnicity: __type(name: "Ethnicity") {
      enumValues {
        name
      }
    }
    pdDiagnosis: __type(name: "PdDiagnosis") {
      enumValues {
        name
      }
    }
    race: __type(name: "Race") {
      enumValues {
        name
      }
    }
  }
`
