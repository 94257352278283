import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { colors } from 'theme/colors'
import { Text } from 'ui/baseComponents'
import moment from 'moment'
import { DATE_FORMAT } from 'ui/consts'
import {
  getTimestampEndOfDay,
  getTimestampStartOfDay,
  WEEK_IN_DAYS,
} from 'utilities/time'

export const calculateShowingConditions = ({
  loading,
  error,
  patients,
  searchPatientsExecuted,
}) => ({
  allPatientsLoaded: !loading && !error && patients?.length > 0,
  always: true,
  noPatients: !loading && !error && !patients?.length,
  searchPatients: !searchPatientsExecuted,
})

export const formatExpandedRowContent = ({ label, value, copyable }) => [
  {
    key: `cell-${label}-label`,
    value: (
      <Text variant="body14" color={colors.COOL[700]}>
        {label}
      </Text>
    ),
    sx: { verticalAlign: 'middle', border: 0 },
  },
  {
    key: `cell-${label}-value`,
    value: copyable ? (
      <>
        {value}{' '}
        {value && (
          <IconButton color="primary" size="small">
            <ContentCopyIcon
              onClick={() => navigator.clipboard.writeText(value)}
              fontSize="small"
            />
          </IconButton>
        )}
      </>
    ) : (
      value
    ),
    sx: { verticalAlign: 'middle', border: 0 },
  },
]

export const findRedFlagCount = (totalCounts, classification) =>
  totalCounts?.counts?.find(
    (count) => count.classification.enum === classification,
  )?.count || null

export const getPatientListStartEndTimes = (numOfWeeks = 8) => {
  const endDate = moment().format(DATE_FORMAT)
  const startDate = moment(endDate)
    .subtract(numOfWeeks * WEEK_IN_DAYS - 1, 'd')
    .format(DATE_FORMAT)
  const endTime = getTimestampEndOfDay(endDate)
  const startTime = getTimestampStartOfDay(startDate)
  return { startDate, endDate, startTime, endTime }
}
