import EditPatient from 'ui/screens/EditPatient'
import { Daily } from '../Daily'
import { Log } from '../Log'
import { Overview } from '../Overview'
import { Report } from '../Report'
import { Summary } from '../Summary'
import { StriveGPT } from '../StriveGPT'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import { WeeklyView } from 'ui/clinicianScreens/Patient/WeeklyView'

const {
  daily,
  editPatient,
  log,
  overview,
  questionAnswer: questionAnswerRouteMetadata,
  reportClinician,
  reportPatient,
  summary,
  summaryComparison,
  weeklyView,
} = ROUTE_METADATA

/**
 * Formatting the tabs and their respective necessary props/urls/links
 *
 * @returns {object[]} List of tabs to display
 */
export const getTabs = ({
  admin,
  clinicianReportVisible,
  comparisonModeEnabled,
  monthlyReportsTabVisible,
  phiVisibility,
  striveGptVisible,
}) => {
  const tabArray = [
    {
      id: overview.path,
      component: <Overview />,
      label: overview.title,
      to: `${overview.path}`,
    },
    {
      id: summary.path,
      component: <Summary />,
      label: summary.title,
      to: comparisonModeEnabled
        ? `${summaryComparison.path}`
        : `${summary.path}`,
    },
    {
      id: weeklyView.path,
      component: <WeeklyView />,
      label: weeklyView.title,
      to: `${weeklyView.path}`,
    },
    {
      id: daily.path,
      component: <Daily />,
      label: daily.title,
      to: `${daily.path}`,
    },
    {
      id: log.path,
      component: <Log />,
      label: log.title,
      to: `${log.path}`,
    },
    ...(striveGptVisible
      ? [
          {
            id: questionAnswerRouteMetadata.path,
            component: <StriveGPT />,
            label: questionAnswerRouteMetadata.title,
            to: `${questionAnswerRouteMetadata.path}`,
          },
        ]
      : []),
    ...(monthlyReportsTabVisible
      ? [
          {
            id: reportPatient.path,
            component: <Report />,
            label: reportPatient.title,
            to: `${reportPatient.path}`,
          },
        ]
      : []),
    ...(clinicianReportVisible
      ? [
          {
            id: reportClinician.path,
            component: <Report isClinicianReport />,
            label: reportClinician.title,
            to: `${reportClinician.path}`,
          },
        ]
      : []),
    ...(admin || phiVisibility
      ? [
          {
            id: editPatient.path,
            component: <EditPatient />,
            label: editPatient.title,
            to: `${editPatient.path}`,
          },
        ]
      : []),
  ]

  return tabArray
}
