import moment from 'moment'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { PD_MEDICATIONS } from 'ui/clinicianScreens/Patient/MedicationTables/consts'
import { CLINICIAN_RED_FLAGS } from 'ui/clinicianScreens/Patient/Report/sections/SymptomLog/consts'
import { DATE_FORMAT } from 'ui/consts'
import { AGGREGATE_WINDOW_METRIC_TYPES } from 'ui/hooks/consts'
import {
  convertUnixTimestampToSpecifiedTimezone,
  SECOND_IN_MILLISECONDS,
  WEEK_IN_DAYS,
} from 'utilities/time'

export const getMedicationsByWeek = (weekStartDates, eventList) => {
  const medicationEvents =
    eventList?.filter(
      (event) => event.classification.category === 'medication',
    ) || []

  const pdMedications = medicationEvents?.filter((event) =>
    PD_MEDICATIONS.includes(event.classification.enum),
  )

  const medicationsGroupedByWeek = weekStartDates.map((weekStartDate) =>
    pdMedications.filter(
      (medication) =>
        moment(
          medication.duration.startTime * SECOND_IN_MILLISECONDS,
        ).isSameOrAfter(weekStartDate, 'day') &&
        moment(medication.duration.startTime * SECOND_IN_MILLISECONDS).isBefore(
          weekStartDate.clone().add(7, 'days'),
        ),
    ),
  )

  const medicationCountsByWeek = medicationsGroupedByWeek.map((medications) =>
    medications.reduce((acc, medication) => {
      const name = getEventName(medication)
      const { dosage, dosage_strength } = JSON.parse(medication.payload)
      const { quantity, unit } = dosage_strength || {}

      acc[name + quantity] = {
        name,
        dosage: (acc[name + quantity]?.dosage || 0) + dosage,
        quantity,
        unit,
      }
      return acc
    }, {}),
  )

  return medicationCountsByWeek
}

export const getSymptomsByWeek = (weekStartDates, eventList) => {
  const symptomEvents =
    eventList?.filter((event) =>
      ['symptom', 'side-effect'].includes(event.classification.category),
    ) || []
  const redFlagSymptoms = symptomEvents?.filter((event) =>
    CLINICIAN_RED_FLAGS.includes(event.classification.enum),
  )

  const symptomsGroupedByWeek = weekStartDates.map((weekStartDate) =>
    redFlagSymptoms.filter(
      (symptom) =>
        moment(
          symptom.duration.startTime * SECOND_IN_MILLISECONDS,
        ).isSameOrAfter(weekStartDate, 'day') &&
        moment(symptom.duration.startTime * SECOND_IN_MILLISECONDS).isBefore(
          weekStartDate.clone().add(7, 'days'),
        ),
    ),
  )

  const symptomCountsByWeek = symptomsGroupedByWeek.map((symptoms) =>
    symptoms.reduce((acc, symptom) => {
      const name = getEventName(symptom)
      acc[name] = acc[name] ? acc[name] + 1 : 1
      return acc
    }, {}),
  )

  return symptomCountsByWeek
}

export const getNotesByWeek = (weekStartDates, eventList, selectedTimezone) => {
  const notesEvents =
    eventList
      ?.filter((event) => event.classification.category === 'note')
      .reverse() || []

  const notes = notesEvents?.map((event, key) => {
    const noteDate = convertUnixTimestampToSpecifiedTimezone({
      timestamp: event.duration.startTime * SECOND_IN_MILLISECONDS,
      timezoneName: selectedTimezone,
      format: 'MM/DD/YYYY',
    })

    // Filter out not usable notes log items and format the wording
    const CONTENT_INDEX = 1
    const noteContent = Object.entries(JSON.parse(event.payload)).map(
      (item) => item[CONTENT_INDEX],
    )
    return { noteDate, noteContent }
  })

  const notesGroupedByWeek = weekStartDates.map((weekStartDate) =>
    notes.filter(
      (note) =>
        moment(note.noteDate).isSameOrAfter(weekStartDate, 'day') &&
        moment(note.noteDate).isBefore(weekStartDate.clone().add(7, 'days')),
    ),
  )

  return notesGroupedByWeek
}

export const getMobilityMetricsByWeek = (data) => {
  const findMetricWeeklyValues = (metricType) =>
    data?.find((metric) => metric?.description?.id === metricType)?.metric?.data
      ?.aggregate_values

  const walkingSpeed = findMetricWeeklyValues(
    AGGREGATE_WINDOW_METRIC_TYPES.walkingSpeed,
  )
  const stepLength = findMetricWeeklyValues(
    AGGREGATE_WINDOW_METRIC_TYPES.stepLength,
  )
  const doubleSupport = findMetricWeeklyValues(
    AGGREGATE_WINDOW_METRIC_TYPES.doubleSupport,
  )

  return { walkingSpeed, stepLength, doubleSupport }
}

const getNextSunday = (date) => {
  const givenDate = moment(date)
  if (givenDate.day() === 0) {
    return givenDate.format(DATE_FORMAT) // Already a Sunday
  }
  return givenDate.day(7).format(DATE_FORMAT) // Move to next Sunday
}

export const getWeeklyDatesByCalendarWeeks = (endDate, numOfWeeks = 8) => {
  const adjustedEndDate = getNextSunday(endDate)
  const adjustedStartDate = moment(adjustedEndDate)
    .subtract(numOfWeeks * WEEK_IN_DAYS - 1, 'd')
    .format(DATE_FORMAT)

  return { adjustedStartDate, adjustedEndDate }
}
