import { Autocomplete, Stack, Text } from 'ui/baseComponents'
import {
  FORM_FIELDS,
  FORM_FIELDS_METADATA,
  TYPOGRAPHY,
} from 'ui/screens/EditPatient/PatientDetails/consts'
import FormField from 'ui/screens/EditPatient/PatientDetails/FormField'
import { getNestedObjectValue } from 'utilities/objects'
import { colors } from 'theme/colors'

const STYLES = {
  autoCompleteDropdown: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  readOnlyInfoLine: {
    marginTop: '0 !important',
  },
  readOnlyProviderInfo: {
    backgroundColor: colors.GREY[100],
    border: 'none',
    borderRadius: '8px',
    padding: 2,
  },
}

const getFieldValue = ({ field, formData, patient }) => {
  const formDataValue = getNestedObjectValue({
    object: formData,
    path: field?.name,
  })
  const patientValue = getNestedObjectValue({
    object: patient,
    path: field?.name,
  })

  return formDataValue !== undefined ? formDataValue : patientValue || '' // eslint-disable-line no-undefined
}

const ProviderSearchField = ({
  errors,
  formData,
  handleChange,
  inputValues,
  isEditing,
  loadingOptions,
  options,
  patient,
  providerLocation,
  providerOptions,
  updateSearchProviderInputFields,
  updateSelectedProvider,
  value,
}) => {
  const disabled = !providerLocation || (!isEditing && !value)
  const readOnly = !isEditing && !!value

  const PROVIDER_ADDRESS = {
    addressLine1: getFieldValue({
      field: FORM_FIELDS_METADATA.providerAddressLine1,
      formData,
      patient,
    }),
    addressLine2: getFieldValue({
      field: FORM_FIELDS_METADATA.providerAddressLine2,
      formData,
      patient,
    }),
    city: getFieldValue({
      field: FORM_FIELDS_METADATA.providerCity,
      formData,
      patient,
    }),
    state: getFieldValue({
      field: FORM_FIELDS_METADATA.providerState,
      formData,
      patient,
    }),
    zipCode: getFieldValue({
      field: FORM_FIELDS_METADATA.providerZipCode,
      formData,
      patient,
    }),
  }

  return (
    <>
      <FormField
        {...{
          errors,
          field: FORM_FIELDS_METADATA.stateForSearch,
          formData,
          handleChange,
          isEditing,
          key: FORM_FIELDS_METADATA.stateForSearch.name,
          loadingOptions,
          options,
          patient,
        }}
      />
      <Text variant={TYPOGRAPHY.instructions}>
        Select a state above before searching for provider.
      </Text>

      <Stack direction="row" spacing={2}>
        {FORM_FIELDS.providerName.map((field) => (
          <Autocomplete
            key={field.key}
            label={field.label}
            name={field.name}
            getOptionLabel={(option) =>
              option?.value?.[field.optionValueIdentifier] || ''
            }
            menuItemChildren={(option) => (
              <Stack spacing={0}>
                <Stack>
                  {option?.value?.address?.address_line_1}
                  {option?.value?.address?.address_line_2 &&
                    `, ${option?.value?.address?.address_line_2}`}
                </Stack>
                <Stack sx={{ marginTop: 0 }}>
                  {option?.value?.address?.city},{' '}
                  {option?.value?.address?.state}
                  {''}
                  {option?.value?.address?.zip_code}
                </Stack>
              </Stack>
            )}
            options={providerOptions || []}
            isOptionEqualToValue={(option, value) =>
              JSON.stringify(option.value) === JSON.stringify(value)
            }
            onChange={(event) => updateSelectedProvider(event)}
            onInputChange={(event) => updateSearchProviderInputFields(event)}
            readOnly={readOnly}
            disabled={disabled}
            sxSelect={STYLES.autoCompleteDropdown}
            inputValue={inputValues[field.name]}
            value={value}
          />
        ))}
      </Stack>
      <Stack direction="column" spacing={2} sx={STYLES.readOnlyProviderInfo}>
        <Text variant={TYPOGRAPHY.readOnlyInfoHeading}>Provider Address</Text>
        <Stack direction="row" sx={STYLES.readOnlyInfoLine}>
          <Text variant={TYPOGRAPHY.readOnlyInfoLine} component="p">
            {PROVIDER_ADDRESS.addressLine1}
          </Text>
          {PROVIDER_ADDRESS.addressLine2 && ','}
          <Text variant={TYPOGRAPHY.readOnlyInfoLine} component="p">
            {PROVIDER_ADDRESS.addressLine2}
          </Text>
        </Stack>
        <Stack direction="row" sx={STYLES.readOnlyInfoLine}>
          <Text variant={TYPOGRAPHY.readOnlyInfoLine}>
            {PROVIDER_ADDRESS.city}
          </Text>
          {PROVIDER_ADDRESS.city && ','}
          <Text variant={TYPOGRAPHY.readOnlyInfoLine}>
            {PROVIDER_ADDRESS.state}
          </Text>
          <Text variant={TYPOGRAPHY.readOnlyInfoLine}>
            {PROVIDER_ADDRESS.zipCode}
          </Text>
        </Stack>
        <Stack direction="column" spacing={2}>
          <Text variant={TYPOGRAPHY.readOnlyInfoHeading}>NPI</Text>
          <Text
            variant={TYPOGRAPHY.readOnlyInfoLine}
            sx={STYLES.readOnlyInfoLine}
          >
            {getFieldValue({
              field: FORM_FIELDS_METADATA.providerNpi,
              formData,
              patient,
            })}
          </Text>
        </Stack>
      </Stack>
    </>
  )
}

export default ProviderSearchField
