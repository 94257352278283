import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import {
  Autocomplete as MUIAutocomplete,
  CircularProgress as MUICircularProgress,
  ListItem as MUIListItem,
  Paper as MUIPaper,
  TextField as MUITextField,
} from '@mui/material'
import { SHARED_STYLES } from './consts'

const STYLES = {
  disabledInput: {
    '& .MuiOutlinedInput-root.Mui-disabled': SHARED_STYLES.disabledInputBox,
    ...SHARED_STYLES.disabledInput,
  },
  option: {
    '&.MuiAutocomplete-option': {
      display: 'block',
    },
  },
  readOnlyInput: SHARED_STYLES.readOnlyInput,
  root: {
    '&.MuiAutocomplete-root': {
      width: '100%',
    },
  },
}

const Autocomplete = ({
  disabled,
  error,
  freeSolo = false,
  getOptionLabel,
  inputValue,
  isOptionEqualToValue,
  label,
  loading,
  menuItemChildren,
  name,
  onBlur,
  onChange,
  onInputChange,
  options,
  readOnly,
  value,
}) => {
  const handleChange = (_, newValue) => {
    onChange?.({ target: { name, value: newValue?.value || newValue || null } })
  }

  const handleInputChange = (_, newInputValue, reason) => {
    if (reason === 'input') {
      onInputChange?.({ target: { name, value: newInputValue || '' } })
    }
  }

  const CustomPaper = (props) => <MUIPaper elevation={8} {...props} />

  return (
    <MUIAutocomplete
      {...{
        disabled,
        freeSolo,
        getOptionLabel,
        loading,
        inputValue,
        isOptionEqualToValue,
        noOptionsText: 'No results found',
        onBlur,
        onChange: handleChange,
        onInputChange: handleInputChange,
        openOnFocus: true,
        options,
        PaperComponent: CustomPaper,
        readOnly,
        selectOnFocus: true,
        value,
        sx: {
          ...STYLES.root,
          ...(readOnly && STYLES.readOnlyInput),
          ...(disabled && !readOnly && STYLES.disabledInput),
        },
      }}
      renderInput={(params) => (
        <MUITextField
          {...{
            ...params,
            label,
            name,
            error: !!error,
            helperText: error,
            sx: {
              ...(readOnly && STYLES.readOnlyInput),
              ...(disabled && !readOnly && STYLES.disabledInput),
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <MUICircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <MUIListItem {...props} key={option?.key || option} sx={STYLES.option}>
          {option?.name || option}
          {menuItemChildren && menuItemChildren(option)}
        </MUIListItem>
      )}
    />
  )
}

export default Autocomplete

Autocomplete.propTypes = {
  disabled: bool,
  error: string,
  freeSolo: bool,
  getOptionLabel: func.isRequired,
  inputValue: string,
  isOptionEqualToValue: func,
  label: string.isRequired,
  loading: bool,
  menuItemChildren: func,
  name: string,
  onChange: func,
  onInputChange: func,
  options: arrayOf(
    oneOfType([
      shape({
        key: string,
        name: string.isRequired,
        value: oneOfType([number, string]).isRequired,
      }),
      string,
    ]),
  ),
  readOnly: bool,
  value:
    shape({
      key: string,
      name: string.isRequired,
      value: oneOfType([number, string]).isRequired,
    }) || null,
}
