import { Fragment, useCallback, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFlags } from 'domains/launchdarkly/hooks'
import moment from 'moment'
import {
  AppBar,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import LogoIcon from 'ui/components/LogoIcon/LogoIcon'
import MainNavSkeleton from 'ui/components/MainNav/MainNav.skeleton'
import MembershipContext from 'ui/contexts/MembershipContext'
import { AdapterLink } from 'ui/components/NavLink/NavLink'
import NavLink from 'ui/components/NavLink'
import { NotificationBar, Warning } from 'ui/components/Notifications'
import UserContext from 'ui/contexts/UserContext'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import { fmtMomentLocal, SECOND_IN_MILLISECONDS } from 'utilities/time'
import OrgListWrapper from '../OrgList/OrgListWrapper'
import { Text } from 'ui/baseComponents/Text'
import useBoundStore from 'domains/zustand/store'

export const HEADER_HEIGHT = 64

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: `${theme.palette.primary.main}`,
    boxShadow: `${theme.header.boxShadow}`,
    borderBottom: `${theme.header.borderBottom}`,
    '@media print': {
      display: 'none',
    },
  },
  logoLink: {
    display: 'flex',
  },
  toolbar: {
    height: `${theme.header.height}`,
  },
  dropdownMenuItem: {
    minWidth: '200px',
    padding: theme.spacing(1, 2),
    color: `${theme.palette.primary.secondary}`,
  },
  email: {
    cursor: 'default',
    pointerEvents: 'none',
    color: `${theme.palette.primary.main}`,
  },
  settingsText: {
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  homeLogo: {
    marginRight: theme.spacing(2),
  },
  disabled: {
    display: 'none',
  },
  tabs: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
    '& .MuiTab-textColorInherit': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiTab-root': {
      fontSize: '1.125rem',
      fontWeight: 400,
      opacity: 1,
      paddingTop: '1.25rem',
      textTransform: 'none',
    },
    '& .MuiTab-root:hover': {
      fontWeight: 600,
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      height: theme.spacing(0.5),
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      borderTopLeftRadius: '100px 50px',
      borderTopRightRadius: '100px 50px',
      maxWidth: '80%',
      width: '80%',
      backgroundColor: theme.header.tabs.indicator,
    },
    '& .Mui-selected': {
      fontWeight: 600,
    },
  },
  accountButton: {
    height: '100%',
    paddingTop: '1.25rem',
  },
  accountDropIcon: {
    height: '1rem',
  },
}))

/**
 * @return {JSX.Element} Tabs component that allows user to navigate to /users or /patients by clicking on Patients or Team.
 */
const MainMenuTabs = () => {
  const membership = useContext(MembershipContext)
  const classes = useStyles()
  const { pathname } = useLocation()
  let currentTab = false

  const isUserRoute = pathname.includes(ROUTE_METADATA.users.path)
  const isPatientRoute = pathname.includes(ROUTE_METADATA.patients.path)

  if (isUserRoute) {
    currentTab = ROUTE_METADATA.users.path
  } else if (isPatientRoute) {
    currentTab = ROUTE_METADATA.patients.path
  }

  return (
    <Tabs
      aria-label="App Navigation Tabs"
      className={classes.tabs}
      value={currentTab}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    >
      <Tab
        component={AdapterLink}
        color="inherit"
        to={ROUTE_METADATA.patients.path}
        value={ROUTE_METADATA.patients.path}
        label="All Patients"
        data-cy="main-nav-patients"
      />
      {membership.admin ? (
        <Tab
          component={AdapterLink}
          color="inherit"
          to={ROUTE_METADATA.users.path}
          value={ROUTE_METADATA.users.path}
          label="Team"
          data-cy="main-nav-team"
        />
      ) : null}
    </Tabs>
  )
}

/**
 * @return {JSX.Element}  Element containing main navbar items: Patients, Team, and user dropdown. Displays a maintenance warning when there is upcoming maintenance.
 */
const MenuContent = () => {
  const { email } = useContext(UserContext)
  const { dhpMaintenanceUpcoming } = useFlags()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(true)
  const signOut = useBoundStore((state) => state.signOut)
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  if (!email) {
    return <MainNavSkeleton />
  }

  const handleMessageClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      {dhpMaintenanceUpcoming?.start_time && (
        <NotificationBar
          open={open}
          onClick={handleMessageClose}
          autoHideDuration={null}
        >
          <Warning
            className={'maintenanceWarning'}
            message={`The website will be under maintenance between ${fmtMomentLocal(
              'MM-DD-Y h:mma',
              moment(
                dhpMaintenanceUpcoming.start_time * SECOND_IN_MILLISECONDS,
              ),
            )} and ${fmtMomentLocal(
              'MM-DD-Y h:mma',
              moment(dhpMaintenanceUpcoming.end_time * SECOND_IN_MILLISECONDS),
            )}. It will not be accessible during this period of time.`}
          />
        </NotificationBar>
      )}
      <IconButton
        edge="start"
        className={classes.homeLogo}
        color="inherit"
        aria-label="menu"
        data-cy="home-logo"
      >
        <NavLink className={classes.logoLink} to="/patients">
          <LogoIcon />
        </NavLink>
      </IconButton>
      <MainMenuTabs />
      <div>
        <IconButton
          className={classes.accountButton}
          data-cy="user-menu-button"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClick}
        >
          <>
            <Text variant={'body16B'}>My Account</Text>
            {!!anchorEl ? (
              <ArrowDropUp className={classes.accountDropIcon} />
            ) : (
              <ArrowDropDown className={classes.accountDropIcon} />
            )}
          </>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem
            classes={{ root: classes.dropdownMenuItem }}
            className={classes.email}
          >
            <Typography variant="subtitle2">{email}</Typography>
          </MenuItem>
          <Divider variant="middle" />
          <MenuItem classes={{ root: classes.dropdownMenuItem }}>
            <NavLink
              to={`${ROUTE_METADATA.settings.path}/profile`}
              className={classes.settingsText}
              title="Edit your user settings"
              data-cy="userSettingsNavLink"
            >
              User Settings
            </NavLink>
          </MenuItem>
          <OrgListWrapper />
          <Divider variant="middle" />
          <MenuItem
            classes={{ root: classes.dropdownMenuItem }}
            data-cy="logout"
            onClick={() => signOut()}
          >
            Sign Out
          </MenuItem>
        </Menu>
      </div>
    </Fragment>
  )
}

/**
 * @return {JSX.Element} Main navigation bar present on all pages. Utilizes MenuContent as child to display links to Patients, Team, and user dropdown.
 */
const MainNav = () => {
  const classes = useStyles()
  const { pathname } = useLocation()

  const hideMainNav = pathname.includes(
    ROUTE_METADATA.reportPatientPDFTest.path,
  )

  if (hideMainNav) {
    return null
  }

  return (
    <AppBar classes={{ root: classes.header }} position="sticky">
      <Toolbar className={classes.toolbar}>
        <MenuContent />
      </Toolbar>
    </AppBar>
  )
}

export default MainNav
