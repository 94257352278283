import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import CreateClient from './CreateClient'
import Client from './Client/Client'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { useClients } from 'ui/contexts/PatientContext'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
}))
const Clients = () => {
  const classes = useStyles()
  const clients = useClients()

  return (
    <ErrorBoundary>
      <Box className={classes.root}>
        <CreateClient />
        {clients.map((client) => (
          <ErrorBoundary key={client.id}>
            <Client client={client} />
          </ErrorBoundary>
        ))}
      </Box>
    </ErrorBoundary>
  )
}

export default Clients
