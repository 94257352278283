import axios from 'axios'

import { NEXT_PAGE_TOKEN } from './consts'
import { getAuthHeaders } from 'ui/screens/SignIn/helpers'

/**
 * Call the Stream API, with authentication handling
 * @param url
 * @param params
 * @param axiosOptions
 * @param streamApiClient
 * @param method
 * @return {Promise<*>}
 */
export const callApi = async ({
  url,
  params,
  streamApiClient,
  axiosOptions,
  method,
}) => {
  const headers = await getAuthHeaders()

  if (method === 'POST') {
    try {
      delete params.timezone_name
      const arrParams = Object.values(params)
      headers['Content-Type'] = 'application/json'
      const response = await streamApiClient.post(url, arrParams, {
        headers: headers,
        ...axiosOptions,
      })
      return response ? response.data : response
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  } else {
    try {
      const response = await streamApiClient.get(url, {
        headers,
        params,
        ...axiosOptions,
      })
      const nextPageTokenResponse =
        response?.headers?.[NEXT_PAGE_TOKEN.responseHeader]

      return {
        ...(response?.data || response),
        ...(nextPageTokenResponse && {
          [NEXT_PAGE_TOKEN.key]: nextPageTokenResponse,
        }),
      }
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  }
}
