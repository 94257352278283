import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { RUNE_WHITE, colors } from './colors'

export const STRIVEPD_FONT_FAMILY = '"Work Sans", sans-serif'

let strivePDTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      contrastText: RUNE_WHITE,
      light: colors.PRIMARY[300],
      main: colors.PRIMARY[1000],
      dark: colors.PRIMARY[1300],
    },
    secondary: {
      light: colors.BLUEBERRY[100],
      main: colors.BLUEBERRY[500],
      dark: colors.BLUEBERRY[700],
    },
    error: {
      light: colors.RED[200],
      main: colors.RED[500],
      dark: colors.RED[700],
    },
    warning: {
      light: colors.YELLOW[200],
      main: colors.YELLOW[500],
      dark: colors.YELLOW[700],
    },
    success: {
      light: colors.GREEN[200],
      main: colors.GREEN[500],
      dark: colors.GREEN[800],
    },
    tremorPrimary: colors.TREMOR[500],
    dyskinesiaPrimary: colors.DYSKINESIA[500],
    offTimePrimary: colors.RED[300],
    default: {
      main: RUNE_WHITE,
    },
  },
  typography: {
    fontFamily: STRIVEPD_FONT_FAMILY,
    fontSize: 14,
    fontWeight: 400,
    color: colors.BLACK,
    head72: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '4.5rem',
      fontWeight: 300,
      letterSpacing: '-0.135rem',
    },
    head72B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '4.5rem',
      fontWeight: 600,
      letterSpacing: '-0.135rem',
    },
    head72L: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '4.5rem',
      fontWeight: 200,
      letterSpacing: '-0.135rem',
    },
    head56: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '3.5rem',
      fontWeight: 400,
      letterSpacing: '-0.105rem',
    },
    head56B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '3.5rem',
      fontWeight: 600,
      letterSpacing: '-0.105rem',
    },
    head56L: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '3.5rem',
      fontWeight: 300,
      letterSpacing: '-0.105rem',
    },
    head42: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '2.625rem',
      fontWeight: 400,
      letterSpacing: '-0.07875rem',
    },
    head42B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '2.625rem',
      fontWeight: 600,
      letterSpacing: '-0.07875rem',
    },
    head42L: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '2.625rem',
      fontWeight: 300,
      letterSpacing: '-0.07875rem',
    },
    head32: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '2rem',
      fontWeight: 400,
      letterSpacing: '-0.04rem',
    },
    head32B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '2rem',
      fontWeight: 600,
      letterSpacing: '-0.04rem',
    },
    head32L: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '2rem',
      fontWeight: 300,
      letterSpacing: '-0.04rem',
    },
    head24: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.5rem',
      fontWeight: 400,
      letterSpacing: '-0.015rem',
    },
    head24B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.5rem',
      fontWeight: 600,
      letterSpacing: '-0.015rem',
    },
    head24L: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.5rem',
      fontWeight: 300,
      letterSpacing: '-0.015rem',
    },
    head22: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.375rem',
      fontWeight: 400,
      letterSpacing: '-0.01375rem',
    },
    head22B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.375rem',
      fontWeight: 600,
      letterSpacing: '-0.01375rem',
    },
    head22L: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.375rem',
      fontWeight: 300,
      letterSpacing: '-0.01375rem',
    },
    head20: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.25rem',
      fontWeight: 400,
      letterSpacing: '-0.0125rem',
    },
    head20B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.25rem',
      fontWeight: 600,
      letterSpacing: '-0.0125rem',
    },
    head20L: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.25rem',
      fontWeight: 300,
      letterSpacing: '-0.0125rem',
    },
    head18: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    head18B: {
      fontFamily: STRIVEPD_FONT_FAMILY,
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    body16: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '-0.03em',
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    body16B: {
      fontSize: '1rem',
      fontWeight: 600,
      letterSpacing: '-0.03em',
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    body14: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '-0.02625rem',
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    body14B: {
      fontSize: '0.875rem',
      fontWeight: 600,
      letterSpacing: '-0.02625rem',
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    body12: {
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: '-0.015rem',
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    body12B: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '-0.015rem',
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    body10: {
      fontSize: '0.625rem',
      fontWeight: 500,
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    body10B: {
      fontSize: '0.625rem',
      fontWeight: 600,
      fontFamily: STRIVEPD_FONT_FAMILY,
    },
    caps16: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '0.08rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps16B: {
      fontSize: '1rem',
      fontWeight: 600,
      letterSpacing: '0.08rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps14: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '0.04375rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps14B: {
      fontSize: '0.875rem',
      fontWeight: 600,
      letterSpacing: '0.04375rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps12: {
      fontSize: '0.75rem',
      fontWeight: 500,
      letterSpacing: '0.075rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps12B: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.075rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps10: {
      fontSize: '0.625rem',
      fontWeight: 500,
      letterSpacing: '0.05rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps10B: {
      fontSize: '0.625rem',
      fontWeight: 600,
      letterSpacing: '0.05rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: 600,
      letterSpacing: 2,
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
})

strivePDTheme = responsiveFontSizes(strivePDTheme)

export default strivePDTheme
