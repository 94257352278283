import { useCallback, useContext, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { useMutation } from '@apollo/client'
import { FormHelperText } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import { useDispatch } from 'react-redux'
import { push } from 'redux-first-history'
import DeviceTypePicker from 'ui/components/DeviceTypePicker'
import styled from 'styled-components'
import MUIFormControl from '@material-ui/core/FormControl'
import CreateEntityDialog from 'ui/components/CreateEntity/CreateEntityDialog'
import { CREATE_DEVICE } from './mutations'
import { useRefetchPatientEditableFields } from 'ui/hooks/usePatientEditableFields'
import { PatientContext } from 'ui/contexts'
import { Alert } from 'ui/baseComponents/Alert'

const FormControl = styled(MUIFormControl)`
  margin-top: ${({ theme }) => theme.spacing(1)}px !important;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px !important;
`

const CreateDeviceDialog = ({ handleClose }) => {
  const { id: patientId, deviceList } = useContext(PatientContext)
  const dispatch = useDispatch()
  const [createDevice, { loading }] = useMutation(CREATE_DEVICE, {
    refetchQueries: [useRefetchPatientEditableFields(patientId)],
    awaitRefetchQueries: true,
  })
  const [error, setError] = useState()
  const [alias, setAlias] = useState('')
  const [deviceType, setDeviceType] = useState()

  useEffect(() => {
    if (deviceType?.displayName && deviceList?.devices) {
      setAlias(
        deviceType.displayName +
          ' ' +
          (deviceList.devices.filter(
            (device) => device.deviceType.id === deviceType.id,
          ).length +
            1),
      )
    }
  }, [deviceList, deviceType])

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (!deviceType) {
        setError('Select a device type')
        return
      }
      setError(false)
      const input = {
        alias,
        deviceTypeId: deviceType.id,
        patientId,
      }
      const {
        data: {
          createDevice: {
            device: { id },
          },
        },
      } = await createDevice({ variables: { input } })
      handleClose()

      const url = `/patients/${patientId}/manage/devices/${id}`

      dispatch(push(url))
    },
    [alias, createDevice, deviceType, dispatch, handleClose, patientId],
  )
  return (
    <CreateEntityDialog
      title="Register new Device"
      data-cy="CreateDevice"
      loading={loading}
      disabled={!alias.length || !deviceType}
      handleClose={handleClose}
      onSubmit={onSubmit}
    >
      <FormControl fullWidth>
        <InputLabel htmlFor="deviceType">Device Type</InputLabel>
        <DeviceTypePicker
          error={error}
          loading={loading}
          deviceType={deviceType}
          setDeviceType={setDeviceType}
          autoFocus
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="alias"
          data-cy="alias"
          error={!!error}
          helperText={error}
          disabled={loading}
          label="Alias"
          value={alias}
          onChange={(e) => setAlias(e.target.value)}
        />
      </FormControl>
      <Alert severity="warning">
        DO NOT use patient’s real name or other identifiable information
      </Alert>
    </CreateEntityDialog>
  )
}

export default CreateDeviceDialog
