import axios from 'axios'
import { getAuthHeaders } from 'ui/screens/SignIn/helpers'

const baseURL = window.Rune?.Carrot?.config?.lasagna?.host

/**
 * Send a question to the lasagna api.
 * @param {string} value A question to send to the llm.
 * @param {string} model The llm to use.
 * @returns {Promise<Response>} resp A fetch call to the lasagna API.
 */
export async function postLasagna(value, model) {
  const authHeader = await getAuthHeaders()
  let proto = 'https'
  if (window.Rune?.Carrot?.config?.lasagna?.secure === false) {
    proto = 'http'
  }
  const resp = await axios({
    method: 'post',
    url: `${proto}://${baseURL}/llm`,
    headers: { 'Content-Type': 'application/json', ...authHeader },
    data: { question: value, llm: model },
  })
  return resp
}

/**
 * Send a request to the lasagna api to generate a sample LLM response.
 * @param {string} task_type The type of sample response lasagna should generate (ex. symptom_summary or activity_summary).
 * @returns {Promise<Response>} resp A fetch call to the lasagna API.
 */
export async function postLasagnaInternalReviewSample(task_type) {
  const authHeader = await getAuthHeaders()
  let proto = 'https'
  if (window.Rune?.Carrot?.config?.lasagna?.secure === false) {
    proto = 'http'
  }
  const resp = await axios({
    method: 'post',
    url: `${proto}://${baseURL}/llm/v3/evaluate`,
    headers: { 'Content-Type': 'application/json', ...authHeader },
    data: { task_type: task_type },
  })
  return resp
}

/**
 * Send a request to the lasagna api to get the current llm evaluation classification options.
 * @returns {Promise<Response>} resp A fetch call to the lasagna API.
 */
export async function postLasagnaEvaluationClassification() {
  const authHeader = await getAuthHeaders()
  let proto = 'https'
  if (window.Rune?.Carrot?.config?.lasagna?.secure === false) {
    proto = 'http'
  }
  const resp = await axios({
    method: 'get',
    url: `${proto}://${baseURL}/llm/v3/evaluate/classification`,
    headers: { 'Content-Type': 'application/json', ...authHeader },
  })
  return resp
}

/**
 * Send a request to the lasagna api to log the evaluation results.
 * @param {string} executionId The execution id to be logged
 * @param {string} taskType The review task type
 * @param {bool} approved Boolean indicating whether the response was approved or not
 * @param {array} classification an array containing classifications for an non-approved response
 * @param {string} comments Any free text comments about the response.
 * @returns {Promise<Response>} resp A fetch call to the lasagna API.
 */
export async function patchLasagnaEvaluation(
  executionId,
  taskType,
  approved,
  classification,
  comments,
) {
  const authHeader = await getAuthHeaders()
  let proto = 'https'
  if (window.Rune?.Carrot?.config?.lasagna?.secure === false) {
    proto = 'http'
  }
  const data = {
    execution_id: executionId,
    task_type: taskType,
    approved: approved,
  }
  if (classification.length) {
    data.classification = classification
  }
  if (comments.length !== 0) {
    data.comments = comments
  }
  const resp = await axios({
    method: 'patch',
    url: `${proto}://${baseURL}/llm/v3/evaluate`,
    headers: { 'Content-Type': 'application/json', ...authHeader },
    data: data,
  })
  return resp
}
