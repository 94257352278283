import { memo } from 'react'
import {
  DatePicker,
  MultiSelectChip,
  Select,
  Stack,
  TextField,
} from 'ui/baseComponents'
import { getNestedObjectValue } from 'utilities/objects'
import { FORM_FIELD_WIDTH } from 'ui/screens/EditPatient/PatientDetails/consts'

const FormField = ({
  errors,
  field,
  formData,
  handleChange,
  handleDateChange,
  handleMultiSelectChange,
  isEditing,
  loadingOptions,
  options,
  patient,
}) => {
  const { datePickerProps, isOnlyFieldInRow, key, label, layout, name, type } =
    field

  const STYLES = {
    datePicker: {
      width: '100%',
    },
  }

  const commonProps = {
    label,
    name,
    value:
      getNestedObjectValue({ object: formData, path: name }) ||
      getNestedObjectValue({ object: patient, path: name }) ||
      '',
    onChange: (event) => handleChange({ event, key }),
    error: !!errors[name],
    helperText: errors[name],
  }

  const readOnly = !isEditing && !!commonProps.value

  const fieldComponent = (() => {
    switch (type) {
      case 'text':
      case 'email':
        return (
          <TextField
            {...commonProps}
            type={type}
            disabled={!isEditing}
            inputProps={{
              readOnly,
            }}
          />
        )
      case 'select':
        return (
          <Select
            {...commonProps}
            options={options[key] || []}
            disabled={!isEditing || loadingOptions}
            readOnly={readOnly}
          />
        )
      case 'date':
        return (
          <DatePicker
            {...commonProps}
            onChange={handleDateChange}
            disabled={!isEditing}
            readOnly={readOnly}
            sx={{
              ...STYLES.datePicker,
            }}
            {...datePickerProps}
          />
        )
      case 'multiSelect':
        return (
          <MultiSelectChip
            {...commonProps}
            selectedValues={
              getNestedObjectValue({ object: formData, path: name }) ||
              getNestedObjectValue({ object: patient, path: name }) ||
              []
            }
            options={options[key] || []}
            onChange={handleMultiSelectChange}
            disabled={!isEditing}
            readOnly={readOnly}
          />
        )
      default:
        return null
    }
  })()

  return (
    <Stack
      sx={{
        width:
          layout === FORM_FIELD_WIDTH.full.name
            ? FORM_FIELD_WIDTH.full.percentage
            : FORM_FIELD_WIDTH.half.percentage,
      }}
      direction="row"
    >
      {fieldComponent}
      {isOnlyFieldInRow && <div />}
    </Stack>
  )
}

export default memo(FormField)
