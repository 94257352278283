import { Button, Stack } from 'ui/baseComponents'

const ActionButtons = ({
  isEditing,
  onClickCancel,
  onClickEdit,
  onClickSave,
}) =>
  !isEditing ? (
    <Button onClick={onClickEdit} sx={{ alignSelf: 'flex-end' }}>
      Edit
    </Button>
  ) : (
    <Stack direction="row" sx={{ alignSelf: 'flex-end' }}>
      <Button type="primary" onClick={onClickSave}>
        Save
      </Button>
      <Button type="secondary" onClick={onClickCancel}>
        Cancel
      </Button>
    </Stack>
  )

export default ActionButtons
