import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { get } from 'nested-property'
import { GET_PATIENT_LIST } from 'domains/carrotGraph/queries'
import { patientName } from 'utilities/string'
import useBoundStore from 'domains/zustand/store'

export const PATIENT_LIST_LIMIT = {
  default: 200,
  recent: 50,
}

const sortPatientList = ({ patients }) =>
  patients.sort((patientA, patientB) =>
    patientA.preComputedName.localeCompare(patientB.preComputedName),
  )

// /**
//  * Custom hook to fetch a list of patients with pagination support.
//  *
//  * This hook uses Apollo's useQuery to execute the GET_PATIENT_LIST GraphQL query.
//  * It supports incremental loading of data through a cursor-based pagination mechanism.
//  * The hook provides functionality to fetch more data based on the current cursor position
//  * and the limit of items to be fetched.
//  *
//  * @param {Object} options - Configuration options for the hook.
//  * @param {string|null} options.cursor - The cursor for pagination. Null indicates the start of the dataset.
//  * @param {number} options.limit - The maximum number of patients to fetch in one call.
//  * @param {boolean} options.fetchAll - Flag indicating whether to fetch all patients or not.
//  */

const usePatientList = ({
  limit = PATIENT_LIST_LIMIT.default,
  fetchAll = false,
  skip = false,
  startTime,
  endTime,
} = {}) => {
  const [isFetching, setIsFetching] = useState(true)
  const searchInput = useBoundStore((state) => state.searchInput)

  const { data, fetchMore, error, loading } = useQuery(GET_PATIENT_LIST, {
    fetchPolicy: 'cache-first',
    variables: {
      limit,
      startTime,
      endTime,
    },
    skip,
  })

  const patientAccess = get(data, 'org.patientAccessList.patientAccess')
  const responseCursor = get(data, 'org.patientAccessList.pageInfo.endCursor')

  const patientList = useMemo(
    () => (patientAccess ? patientAccess.map((p) => p.patient) : []),
    [patientAccess],
  )

  // Pre-compute patients. Doing this here prevents us from running withLastFirst every time we sort.
  const precomputedPatients = useMemo(
    () =>
      patientList.map((patient) => {
        const preComputedName = patientName(patient).withLastFirst()
        return { ...patient, preComputedName }
      }),
    [patientList],
  )

  // Filter and sort patients based on search input and sort state
  const filteredAndSortedPatients = useMemo(() => {
    let filteredPatients = precomputedPatients.filter((patient) =>
      patient.preComputedName.toLowerCase().includes(searchInput.toLowerCase()),
    )

    filteredPatients = sortPatientList({
      patients: filteredPatients,
    })

    return filteredPatients
  }, [precomputedPatients, searchInput])

  const fetchMoreData = (fetchMore, cursor) => {
    setIsFetching(true)
    fetchMore({
      variables: { cursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // isolates the list to which we are appending
        const prevPatients =
          get(previousResult, 'org.patientAccessList.patientAccess') || []
        const newPatients =
          get(fetchMoreResult, 'org.patientAccessList.patientAccess') || []
        // spread operator used repeatedly to append data to only the correct list.
        // A little bit verbose at this depth but necessary for our db structure.

        return {
          ...fetchMoreResult,
          org: {
            ...(fetchMoreResult.org || {}),
            patientAccessList: {
              ...(fetchMoreResult.org.patientAccessList || {}),
              patientAccess: [...prevPatients, ...newPatients],
            },
          },
        }
      },
    })
  }

  useEffect(() => {
    if (responseCursor && fetchAll) {
      fetchMoreData(fetchMore, responseCursor)
    } else if (data || error) {
      setIsFetching(false)
    }
  }, [data, error, fetchMore, fetchAll, responseCursor])

  const isLoading = loading || isFetching

  useEffect(() => {
    useBoundStore.setState(() => ({
      loadingPatientList: isLoading,
      errorPatientList: error,
      patients: filteredAndSortedPatients,
    }))
  }, [isLoading, error, filteredAndSortedPatients])
}

export default usePatientList
