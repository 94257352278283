export const formFields = {
  forceNewPassword: {
    password: {
      label: 'Password',
      placeholder: 'Enter your new password',
    },
    confirm_password: {
      label: 'Confirm Password',
      placeholder: 'Please confirm your password',
    },
  },
  forgotPassword: {
    username: {
      label: 'Email',
      placeholder: 'Enter your email',
    },
  },
  signIn: {
    username: {
      label: 'Email',
      placeholder: 'Enter your email',
      isRequired: true,
    },
    password: {
      placeholder: 'Enter your password',
    },
  },
}
