import { useEffect, useState } from 'react'
import { callTangerineApi } from './api'

export const useTangerineApi = ({
  url,
  params = {},
  endpointSuffix = '',
  axiosOptions = {},
  skip = false,
  method = 'GET',
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState([])

  const signature = JSON.stringify({ url: url + endpointSuffix, params })

  useEffect(
    () => {
      if (skip) {
        setLoading(false)
        return {}
      }
      async function fetch() {
        try {
          if (method !== 'PATCH') setLoading(true)
          const data = await callTangerineApi({
            url: url + endpointSuffix,
            params,
            axiosOptions,
            method,
          })
          setData(data)
        } catch (error) {
          setErrors(error)
        } finally {
          setLoading(false)
        }
      }
      fetch() // the exit function that allows us to conditionally not run a hook
    },
    // eslint-disable-next-line
    [url, signature], // eslint escape needed to prevent useEffect from calling again if certain params update
  )

  return {
    data,
    loading,
    errors,
  }
}
