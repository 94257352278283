import { combineReducers } from 'redux'
import { reducer as config } from '../config'

export const reducers = (routerReducer) =>
  combineReducers({
    config,
    router: routerReducer,
  })

export default reducers
