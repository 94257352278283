import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { SHARED_STYLES } from 'ui/baseComponents/FormFields/consts'

const STYLES = {
  disabled: {
    '& .MuiInputBase-input.Mui-disabled': SHARED_STYLES.disabledInputBox,
    ...SHARED_STYLES.disabledInput,
  },
  disabledInputLabel: SHARED_STYLES.disabledFont,
  option: {
    '&.MuiMenuItem-root': {
      display: 'block',
      paddingLeft: '1rem',
    },
  },
  readOnly: SHARED_STYLES.readOnlyInput,
}

const MultiSelectChip = ({
  disabled,
  label,
  name,
  onChange,
  options,
  readOnly,
  selectedValues,
  sxChip,
  sxSelect,
}) => {
  const labelId = `${label}-label`

  return (
    <FormControl fullWidth>
      <InputLabel
        id={labelId}
        sx={{ ...(disabled && STYLES.disabledInputLabel) }}
      >
        {label}
      </InputLabel>
      <Select
        {...{
          disabled,
          labelId,
          name,
          onChange,
          sx: {
            ...sxSelect,
            ...(readOnly && STYLES.readOnly),
            ...(disabled && !readOnly && STYLES.disabled),
          },
          'data-cy': `multi-select-${name}`,
        }}
        input={<OutlinedInput label={label} />}
        multiple
        value={selectedValues.map(({ id }) => id)}
        renderValue={() => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedValues.map(({ id, name }) => (
              <Chip key={id} label={name} sx={sxChip} />
            ))}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={STYLES.option}
            style={{
              fontWeight: selectedValues.some((value) => value.id === option.id)
                ? 500
                : 400,
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiSelectChip
