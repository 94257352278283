import { Fragment, useCallback, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { sleep } from 'utilities'
import MUIFormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import SubmitButton from 'ui/components/SubmitButton/SubmitButton'
import styled from 'styled-components'
import CopyTextIcon from 'ui/components/CopyText/CopyTextIcon'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { Error, NotificationBar } from 'ui/components/Notifications'
import Box from '@material-ui/core/Box'
import DeviceTypePicker from 'ui/components/DeviceTypePicker'
import { useRefetchPatientEditableFields } from 'ui/hooks/usePatientEditableFields'
import { UPDATE_DEVICE } from './mutations'
import { PatientContext } from 'ui/contexts'
import { SECOND_IN_MILLISECONDS } from 'utilities/time'

const FormControl = styled(MUIFormControl)`
  width: 400px;
  margin: ${({ theme }) => theme.spacing(1)}px !important;
`
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    width: 120,
  },
  deviceIdHelper: {
    marginBottom: theme.spacing(1),
  },
  enabledSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchLabel: {
    flexGrow: 1,
  },
}))

export const EditDeviceForm = ({
  id: deviceId,
  deviceShortId,
  deviceType: deviceTypeProp,
  alias: aliasProp,
  disabled: disabledProp,
}) => {
  const { id: patientId } = useContext(PatientContext)
  const classes = useStyles()
  const [updateDevice, { loading }] = useMutation(UPDATE_DEVICE, {
    refetchQueries: [useRefetchPatientEditableFields(patientId)],
    // Give refetch full patient a chance to complete before we redirect to the device page,
    // or the device might not yet be present
    awaitRefetchQueries: true,
  })

  // Error is for generic errors, not attribute-specific validation errors
  const [error, setError] = useState()
  const [alias, setAlias] = useState(aliasProp)
  const [deviceType, setDeviceType] = useState(deviceTypeProp)
  const [disabled, setDisabled] = useState(disabledProp)
  const [success, setSuccess] = useState()
  const handleClose = useCallback(() => setError(false), [])

  const SLEEP_DURATION = 2

  const onSubmit = useCallback(
    async (e) => {
      e && e.preventDefault()
      if (!disabled && !deviceType) {
        setError('Select a device type')
        return
      }
      setError(false)
      const input = {
        deviceId,
        alias,
        deviceTypeId: deviceType.id,
        disabled,
      }
      await updateDevice({ variables: { input } })
      setSuccess(true)
      await sleep(SLEEP_DURATION * SECOND_IN_MILLISECONDS)
      setSuccess(false)
    },
    [alias, deviceType, disabled, deviceId, updateDevice],
  )

  const toggleDisabled = useCallback(
    async (disabled) => {
      setDisabled(disabled)
      const input = {
        deviceId,
        disabled,
      }
      await updateDevice({ variables: { input } })
      setError(false)
    },
    [deviceId, updateDevice],
  )
  return (
    <form className={classes.root} onSubmit={onSubmit}>
      <FormControl>
        <Typography variant="h5">{alias}</Typography>
      </FormControl>
      <FormControl>
        <Box my={1}>
          <InputLabel shrink={true}>Device ID</InputLabel>
        </Box>
        <Box mb={2}>
          <CopyTextIcon value={deviceShortId} size="small" />
        </Box>
      </FormControl>
      {error && (
        <NotificationBar open={!!error} onClose={handleClose}>
          <Error message="Unable to update Device" onClose={handleClose} />
        </NotificationBar>
      )}
      <FormControl>
        <Box
          display="flex"
          flexDirection="row"
          className={classes.enabledSwitchContainer}
        >
          <Typography variant="body1" className={classes.switchLabel}>
            Enabled
          </Typography>
          <Switch
            data-cy="disabled-switch"
            checked={!disabled}
            onChange={(e) => toggleDisabled(!e.target.checked)}
            inputProps={{ 'aria-label': 'Enable/disable user' }}
            disabled={loading}
          />
        </Box>
      </FormControl>
      {!disabled && (
        <Fragment>
          <FormControl>
            <TextField
              id="alias"
              data-cy="alias"
              error={!!error}
              helperText={error}
              disabled={loading}
              label="Alias"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="deviceType">Device Type</InputLabel>
            <DeviceTypePicker
              error={error}
              loading={loading}
              deviceType={deviceType}
              setDeviceType={setDeviceType}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
          <FormControl>
            <SubmitButton
              variant="contained"
              className={classes.submitButton}
              loading={loading}
              success={success}
              icon={null}
              disabled={!alias.length || !deviceType}
              submitLabel="Save"
              successLabel="Saved"
            />
          </FormControl>
        </Fragment>
      )}
    </form>
  )
}

export default EditDeviceForm
