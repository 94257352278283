import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import { SHARED_STYLES } from 'ui/baseComponents/FormFields/consts'
import { DATE_FORMAT } from 'ui/consts'

const STYLES = {
  disabled: {
    '& .MuiOutlinedInput-root.Mui-disabled': SHARED_STYLES.disabledInputBox,
    ...SHARED_STYLES.disabledInput,
  },
  readOnly: SHARED_STYLES.readOnlyInput,
}

// This component needs LocalizationProvider and AdapterMoment which are imported in App.js
const DatePicker = ({
  dateFormat = DATE_FORMAT,
  disabled,
  label = 'Select Date',
  name,
  readOnly,
  slotProps,
  sx,
  value,
  onChange,
  views = ['year', 'month', 'day'],
}) => (
  <MUIDatePicker
    {...{
      disabled,
      label,
      name,
      value: value ? moment(value, dateFormat) : null,
      onChange: (newDate) =>
        onChange({
          target: {
            name,
            value: newDate ? moment(newDate) : null,
          },
        }),
      format: dateFormat,
      slotProps,
      sx: {
        ...sx,
        ...(readOnly && STYLES.readOnly),
        ...(disabled && !readOnly && STYLES.disabled),
      },
      views,
    }}
  />
)

export default DatePicker
