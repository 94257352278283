import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { Authenticator } from '@aws-amplify/ui-react'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { Provider as RollbarProvider } from '@rollbar/react'
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import CarrotGraphProvider from 'domains/carrotGraph/provider'
import { StreamApiProvider } from 'domains/streamApi/context'
import { initStore } from 'domains/store'
import { mockGraphClient } from 'domains/carrotGraph/mocks/graphClient.mock'
import { createGraphClient } from 'domains/carrotGraph/client'
import { getAuthHeaders } from 'ui/screens/SignIn/helpers'
import ConfigContext from 'ui/contexts/ConfigContext'
import './App.scss'
import { MINUTE_IN_SECONDS } from 'utilities/time'
import AppRoutes from './AppRoutes/AppRoutes'
import { RecentDataAvailabilityContext } from 'ui/contexts'
import { useRecentDataAvailabilityContext } from 'ui/contexts/RecentDataAvailabilityContext'

/*
 * @todo: move this into Carrot Graph provider component
 */
const makeGraphClient = (carrotGraph) => {
  if (window.Rune.mockCarrotGraph) {
    return mockGraphClient()
  }

  return createGraphClient({
    ...carrotGraph,
    getHeaders: () => getAuthHeaders(),
  })
}

const initRollbar = (config) => {
  const { environment, accessToken, enabled } = config.vendors.rollbar || {
    enabled: false,
  }
  return {
    accessToken,
    environment,
    enabled,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: process.env.REACT_APP_GIT_SHA,
        },
      },
    },
  }
}

/*
 * @todo: phase out Redux from codebase
 */
const makeReduxStore = ({ config, historyObject }) => {
  const { store, history } = initStore({
    initialState: {
      config: {
        ...config,
        timezoneOffset: new Date().getTimezoneOffset() * -MINUTE_IN_SECONDS,
        timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    historyObject,
  })

  return { store, history }
}

/**
 * The main Carrot application.
 *
 * Wraps the rest of the application with global contexts used throughout the
 * app.
 * @returns {JSX.Element} Application component tree
 */
const App = () => {
  const { config } = window.Rune.Carrot
  const historyObject = createBrowserHistory()
  const graphClient = makeGraphClient(config.carrotGraph)
  const rollbar = initRollbar(config)
  const { store, history } = makeReduxStore({
    config,
    historyObject,
  })
  const recentDataAvailabilityContextValue = useRecentDataAvailabilityContext()
  return (
    <Authenticator.Provider>
      <RollbarProvider config={rollbar}>
        <ConfigContext.Provider value={config}>
          <Provider store={store}>
            <CarrotGraphProvider client={graphClient}>
              <Router history={history}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <CssBaseline />
                  <StreamApiProvider config={config.carrotStream}>
                    <RecentDataAvailabilityContext.Provider
                      value={recentDataAvailabilityContextValue}
                    >
                      <AppRoutes />
                    </RecentDataAvailabilityContext.Provider>
                  </StreamApiProvider>
                </LocalizationProvider>
              </Router>
            </CarrotGraphProvider>
          </Provider>
        </ConfigContext.Provider>
      </RollbarProvider>
    </Authenticator.Provider>
  )
}

export default App
