import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { callApplesauceApi } from 'domains/applesauce/api'

const useProviderSearch = ({
  onProviderSelect,
  providerState,
  searchFields,
}) => {
  const [providerOptions, setProviderOptions] = useState([])
  const [providerLocation, setProviderLocation] = useState(providerState)
  const SEARCH_FIELDS = {
    providerFirstName: searchFields.providerFirstName || 'providerFirstName',
    providerLastName: searchFields.providerLastName || 'providerLastName',
  }
  const [searchProviderInputFields, setSearchProviderInputFields] = useState({
    [SEARCH_FIELDS.providerFirstName]: '',
    [SEARCH_FIELDS.providerLastName]: '',
  })
  const [debouncedSearchInput, setDebouncedSearchInput] = useState({
    [SEARCH_FIELDS.providerFirstName]: '',
    [SEARCH_FIELDS.providerLastName]: '',
  })
  const [selectedProvider, setSelectedProvider] = useState(null)
  const { config } = window.Rune.Carrot

  const MINIMUM_SEARCH_LENGTH = 2

  const getFieldName = (name) =>
    name === SEARCH_FIELDS.providerLastName
      ? SEARCH_FIELDS.providerLastName
      : SEARCH_FIELDS.providerFirstName

  const updateSearchProviderInputFields = (event) => {
    const { name, value } = event.target

    const fieldName = getFieldName(name)

    // eslint-disable-next-line no-undefined
    if (value !== undefined) {
      setSearchProviderInputFields((prev) => ({
        ...prev,
        [fieldName]: value || '',
      }))
    }
  }

  const updateSelectedProvider = (event) => {
    const { name, value } = event.target
    const fieldName = getFieldName(name)

    if (!value) {
      // Only if both fields are empty, set the selected provider to null.
      // Otherwise, we run into a bug where we can't use the x to clear the remaining input value
      if (
        !searchProviderInputFields[SEARCH_FIELDS.providerFirstName] &&
        !searchProviderInputFields[SEARCH_FIELDS.providerLastName]
      ) {
        setSelectedProvider(value)
      }

      setSearchProviderInputFields((prev) => ({
        ...prev,
        [fieldName]: value || '',
      }))
    } else {
      setSelectedProvider(value)
      setSearchProviderInputFields({
        [SEARCH_FIELDS.providerFirstName]: value.first_name,
        [SEARCH_FIELDS.providerLastName]: value.last_name,
      })
    }

    if (name !== 'initialProvider') {
      onProviderSelect(value)
    }
  }

  const updateProviderLocation = (value) => {
    setProviderLocation(value)
  }

  useEffect(() => {
    const handleSearch = debounce((input) => {
      setDebouncedSearchInput(input)
    }, 300)

    handleSearch(searchProviderInputFields)

    return () => {
      handleSearch.cancel()
    }
  }, [searchProviderInputFields])

  useEffect(() => {
    if (
      debouncedSearchInput[SEARCH_FIELDS.providerFirstName]?.length >=
        MINIMUM_SEARCH_LENGTH ||
      debouncedSearchInput[SEARCH_FIELDS.providerLastName]?.length >=
        MINIMUM_SEARCH_LENGTH
    ) {
      if (!providerLocation) return
      const getData = async () =>
        await callApplesauceApi({
          url: `https://${config.providers.host}/api/v1/providers`,
          params: {
            first_name: debouncedSearchInput[SEARCH_FIELDS.providerFirstName],
            last_name: debouncedSearchInput[SEARCH_FIELDS.providerLastName],
            state: providerLocation,
            limit: 10,
          },
          method: 'GET',
          axiosOptions: { responseType: 'json' },
        })

      getData()
        .then(({ data }) => {
          if (data?.length > 0) {
            const providerOptions = data.map((provider) => ({
              key: `${provider.first_name}-${
                provider.address.address_line_1
              }-${Math.random().toString(36).slice(2, 9)}}`, // The api returns duplicate objects hence the Math.random() to make the key unique
              name: `${provider.first_name} ${provider.last_name}`,
              value: provider,
            }))
            setProviderOptions(providerOptions)
            return
          }
          setProviderOptions([])
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setProviderOptions([])
    }
  }, [debouncedSearchInput, providerLocation]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    providerLocation,
    providerOptions,
    searchProviderInputFields,
    selectedProvider,
    updateProviderLocation,
    updateSearchProviderInputFields,
    updateSelectedProvider,
  }
}

export default useProviderSearch
