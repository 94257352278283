import { admin, deviceTypes, org, patients, user } from './data'

const Query = ({ isAdmin }) => ({
  user: () => (isAdmin ? admin : user),
  patient: (o, { id }) => patients.find((p) => p.id === id),
  org: () => org,
  deviceTypes: () => deviceTypes,
})

export default Query
