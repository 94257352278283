import { useRollbar } from '@rollbar/react'

const useLogger = () => {
  const rollbar = useRollbar()

  const mockRollbar = {
    critical: (...args) => console.error('MockRollbar critical:', ...args),
    debug: (...args) => console.log('MockRollbar debug:', ...args),
    error: (...args) => console.error('MockRollbar error:', ...args),
    info: (...args) => console.info('MockRollbar info:', ...args),
    warning: (...args) => console.warning('MockRollbar warning:', ...args),
  }

  const rollbarInstance =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? mockRollbar
      : {
          critical: (...args) => rollbar.critical(...args),
          debug: (...args) => rollbar.debug(...args),
          error: (...args) => rollbar.error(...args),
          info: (...args) => rollbar.info(...args),
          warning: (...args) => rollbar.warning(...args),
        }

  return rollbarInstance
}

export default useLogger
