import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTangerineApi } from 'domains/tangerine/hooks'
import { PatientContext } from 'ui/contexts'

const ReportPatientPDFTest = () => {
  const patient = useContext(PatientContext)
  const { id: patientId } = patient || {}

  const { config } = window.Rune.Carrot

  const [searchParams] = useSearchParams()
  const startTimePeriod2 = searchParams.get('startTimePeriod2')
  const endTimePeriod2 = searchParams.get('endTimePeriod2')

  const {
    data = [],
    errors,
    loading,
  } = useTangerineApi({
    url: `https://${config.strivepd.host}/api/v3/patients/${patientId}/strivepd_report`,
    method: 'POST',
    params: {
      start_date: startTimePeriod2,
      end_date: endTimePeriod2,
    },
    axiosOptions: { responseType: 'arraybuffer' },
  })

  const file = new Blob([data], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(file)

  if (loading) {
    return <div>Loading...</div>
  }

  if (errors?.length) {
    return <div>Error: {errors}</div>
  }

  return (
    <>
      <object
        data={`${fileURL}`}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Here's a <a href={fileURL}>link to the PDF!</a>
        </p>
      </object>
    </>
  )
}

export default ReportPatientPDFTest
